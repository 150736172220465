import { useContext } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EUSFormContext } from './EUSFormContext';

type EUSCancelConfirmDialogProps = Omit<DialogProps, 'onClose' | 'open'> & {
  onSaveAndExit: () => void;
  validationError?: boolean; // to show an Alert in case of a validation error
};

export function EUSCancelConfirmDialog(props: EUSCancelConfirmDialogProps): JSX.Element {
  const { TransitionProps, onSaveAndExit, validationError, ...dialogProps } = props;
  const {
    cancelConfirmDialog: { open, setOpen },
    saveStatus: { isLoading, error, reset },
    handleCancel,
  } = useContext(EUSFormContext);

  return (
    <Dialog
      open={open}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>Vuoi davvero uscire?</DialogTitle>
      <DialogContent>
        Sono state apportate modifiche alla pratica.
        {error && <ErrorAlert error={error} />}
        {validationError && <Alert severity="warning"> Impossibile salvare la pratica a causa di un errore </Alert>}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setOpen(false)}>
          Rivedi modifiche
        </Button>
        <Button variant="contained" color="error" onClick={handleCancel}>
          Annulla modifiche ed esci
        </Button>
        <LoadingButton variant="contained" type="submit" color="primary" onClick={onSaveAndExit} loading={isLoading}>
          Salva ed esci
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
