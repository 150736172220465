import { ThirdPartyType, ThirdPartyTypeSchema } from '../entities/ThirdPartyType';
import { api } from './baseApi';

const url = 'v1/third-party-types';

const thirdPartyTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readThirdPartyTypeList: builder.query<ThirdPartyType[], void>({
      query: () => url,
      transformResponse: (data) => ThirdPartyTypeSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadThirdPartyTypeListQuery } = thirdPartyTypeApi;
