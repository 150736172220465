import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useReadAppConfigQuery, useUpdateAppConfigMutation } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { SelectECCNListDialog } from '../../../components/Dialog/SelectECCNListDialog';
import { SelectItemListDialogProps } from '../../../components/Dialog/SelectItemListDialogProps';
import { SelectPNListDialog } from '../../../components/Dialog/SelectPNListDialog';
import { SelectPurchaseGroupListDialog } from '../../../components/Dialog/SelectPurchaseGroupListDialog';
import { SelectVendorListDialog } from '../../../components/Dialog/SelectVendorListDialog';
import { AddIcon, SearchIcon } from '../../../components/Icons';
import { AppConfig } from '../../../entities/AppConfig';
import {
  BottomUpSearchFields,
  BottomUpSearchFilters,
  BottomUpSearchParams,
  BottomUpSearchParamsSchema,
  BottomUpSearchResult,
} from '../../../entities/BottomUpSearch';
import { useLazyBottomUpSearchQuery } from '../../../services/searchApi';
import { parseMultilineInputValue } from '../../../utils/parseMultilineInputValue';

const fields = new Map<keyof BottomUpSearchFields, string>([
  ['enditem', 'End-item'],
  ['program', 'Programma'],
  ['customer', 'Customer'],
  ['enduser', 'End User'],
  ['foreignClassification', 'Classificazione estera'],
  ['italianClassification', 'Classificazione italiana'],
  ['techDataClassification', 'Classificazione tech data'],
  ['serni', 'Categoria SERNI'],
]);

const filters = new Map<keyof BottomUpSearchFilters, string>([
  ['pn', 'P/N'],
  ['vendor', 'Vendor'],
  ['pgr', 'Purchase Group'],
  ['eccn', 'ECCN'],
]);

type AddFilterDialog = (props: SelectItemListDialogProps<string>) => JSX.Element;

const addFilterDialog = new Map<keyof BottomUpSearchFilters, AddFilterDialog>([
  ['pn', SelectPNListDialog],
  ['vendor', SelectVendorListDialog],
  ['pgr', SelectPurchaseGroupListDialog],
  ['eccn', SelectECCNListDialog],
]);

type BottomUpSearchFormProps = PaperProps & {
  searchParams: BottomUpSearchParams;
  onResultLoaded: (results: BottomUpSearchResult[], params: BottomUpSearchParams) => void;
};

export function BottomUpSearchForm(props: BottomUpSearchFormProps): JSX.Element {
  const { searchParams, onResultLoaded, sx, ...paperProps } = props;
  const appConfig = useReadAppConfigQuery();
  const [updateConfig] = useUpdateAppConfigMutation();
  const [search, status] = useLazyBottomUpSearchQuery();
  const [addFilterDialogKey, setAddFilterDialogKey] = useState<keyof BottomUpSearchFilters | null>(null);
  const AddFilterDialog = useMemo(
    () => (addFilterDialogKey ? addFilterDialog.get(addFilterDialogKey) : undefined),
    [addFilterDialogKey],
  );

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BottomUpSearchParams>({
    defaultValues: searchParams,
    resolver: zodResolver(BottomUpSearchParamsSchema),
  });

  const onSubmit = useCallback(
    async (params: BottomUpSearchParams) => {
      const config = appConfig.data as AppConfig | undefined;
      updateConfig({
        ...config,
        bottomUpSearchPage: {
          ...config?.bottomUpSearchPage,
          selectedFields: params.fields,
        },
      });
      const result = await search(params).unwrap();
      if (result.length > 0) {
        onResultLoaded(result, params);
      }
    },
    [appConfig.data, onResultLoaded, search, updateConfig],
  );

  useEffect(() => {
    const config = (appConfig.data as AppConfig)?.bottomUpSearchPage?.selectedFields;
    if (config && appConfig.data) {
      [...fields.keys()].forEach((field) => setValue(`fields.${field}`, config[field]));
    }
  }, [appConfig.data, setValue]);

  return (
    <Paper sx={{ padding: 2, margin: 1, ...sx }} {...paperProps}>
      <Grid container spacing={2}>
        {[...filters].map(([key, label]) => (
          <Grid item md sm={4} xs={6} key={`filters.${key}`}>
            <Controller
              control={control}
              name={`filters.${key}`}
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    multiline
                    label={`Filtro ${label}`}
                    minRows={15}
                    maxRows={25}
                    error={Boolean(error || (errors.filters && 'atLeastOne' in errors.filters))}
                    helperText={
                      error?.message || (errors.filters as { atLeastOne?: { message?: string } })?.atLeastOne?.message
                    }
                    value={value?.join('\n') || ''}
                    {...field}
                    onChange={(event) => onChange(parseMultilineInputValue(event.target.value))}
                    disabled={status.isFetching}
                    fullWidth
                  />
                  <Tooltip title={`Aggiungi ${label}`}>
                    <IconButton
                      onClick={() => setAddFilterDialogKey(key)}
                      color="secondary"
                      sx={{ position: 'absolute', top: -4, right: -4 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>
        ))}
        <Grid item md sm={4} xs={6}>
          <FormControl
            component="fieldset"
            variant="outlined"
            error={Boolean(errors.fields && 'atLeastOne' in errors.fields)}
          >
            <FormGroup>
              {[...fields].map(([key, label]) => (
                <Controller
                  control={control}
                  name={`fields.${key}`}
                  key={`fields.${key}`}
                  render={({ field: { value, ...field } }) => (
                    <FormControlLabel
                      disabled={status.isFetching}
                      control={<Checkbox checked={value || false} value={value || false} {...field} />}
                      label={label}
                    />
                  )}
                />
              ))}
            </FormGroup>
            <FormHelperText>
              {(errors.fields as { atLeastOne?: { message?: string } })?.atLeastOne?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginY: 0 }}>
        <Grid item sm xs={12}>
          {status.error ? (
            <ErrorAlert error={status.error} />
          ) : status.data?.length === 0 ? (
            <Alert severity="info">La ricerca non ha prodotto risultati</Alert>
          ) : null}
        </Grid>
        <Grid item md={12 / 5} xs={12}>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SearchIcon />}
            loading={status.isFetching}
            fullWidth
          >
            Cerca
          </LoadingButton>
        </Grid>
      </Grid>
      {AddFilterDialog && addFilterDialogKey && (
        <AddFilterDialog
          open={true}
          onClose={(value) => {
            if (addFilterDialogKey && value) {
              const previousValue = getValues(`filters.${addFilterDialogKey}`);
              const set = new Set<string>([...previousValue, ...value]);
              setValue(`filters.${addFilterDialogKey}`, [...set]);
            }
            setAddFilterDialogKey(null);
          }}
        />
      )}
    </Paper>
  );
}
