import { z } from 'zod';
import { PurchaseGroupSchema } from './PurchaseGroup';
import { VendorSchema } from './Vendor';

export enum SupplierSource {
  Manual = 'MANUAL',
  PO = 'ZSC_PO',
  POArchive = 'ZSC_PO_ARCHIVE',
  ReqList = 'ZSC_REQ_LIST',
}

export const SupplierSchema = z.object({
  pn: z.string(),
  vendor: VendorSchema,
  vendorSource: z.nativeEnum(SupplierSource).optional(),
  pgr: PurchaseGroupSchema,
  pgrSource: z.nativeEnum(SupplierSource).optional(),
});

export type Supplier = z.infer<typeof SupplierSchema>;
