import { z } from 'zod';

export const ExportLicenceSchema = z.object({
  id: z.number(),
  authorization: z.string(),
  releaseDate: z.string().optional(),
  expiryDate: z.string().optional(),
  variation: z.string().optional(),
  requiredBy: z.string().optional(),
  authorizationType: z.string(),
  movementType: z.string().optional(),
  destinationCountry: z.string().optional(),
  destinationName: z.string().optional(),
  destinationAddress: z.string().optional(),
  orderNumber: z.string().optional(),
  program: z.string().optional(),
  equipment: z.string().optional(),
  description: z.string().optional(),
  pn: z.string().optional(),
  quantityValue: z.string().optional(),
  enduser: z.string().optional(),
});

export type ExportLicence = z.infer<typeof ExportLicenceSchema>;
