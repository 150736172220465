import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { PurchaseOrder, PurchaseOrderSchema } from '../entities/PurchaseOrder';
import { api } from './baseApi';

const url = 'v1/purchase-orders';

const purchaseOrderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPurchaseOrderList: builder.query<PagedResponse<PurchaseOrder>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(PurchaseOrderSchema).parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadPurchaseOrderListQuery } = purchaseOrderApi;
