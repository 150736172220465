import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Home, NotFoundPage, AdminRouter, Query, UnauthorizedPage } from './pages';
import { EUSRouter } from './pages/EUS/EUSRouter';
import { ExportLicenceListPage } from './pages/ExportLicence/ExportLicenceListPage';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="query/*" element={<Query />} />
      <Route path="admin/*" element={<AdminRouter />} />
      <Route path="eus/*" element={<EUSRouter />} />
      <Route path="export-licence" element={<ExportLicenceListPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
