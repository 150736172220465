import { z } from 'zod';

export const CustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string().min(2).max(3),
  address: z.string(),
});

export type Customer = z.infer<typeof CustomerSchema>;
