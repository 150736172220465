import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PartNumberAutocomplete } from '../Form/PartNumberAutocomplete';
import { SelectItemListDialogProps } from './SelectItemListDialogProps';

const SelectPNListDialogFormSchema = z.object({
  list: z.string().array().min(1, 'Seleziona almeno un elemento'),
});

type SelectPNListDialogForm = z.infer<typeof SelectPNListDialogFormSchema>;
const defaultValues: SelectPNListDialogForm = {
  list: [],
};

export function SelectPNListDialog(props: SelectItemListDialogProps<string>): JSX.Element {
  const { onClose, TransitionProps, ...dialogProps } = props;

  const { handleSubmit, control, reset } = useForm<SelectPNListDialogForm>({
    defaultValues,
    resolver: zodResolver(SelectPNListDialogFormSchema),
    reValidateMode: 'onBlur',
  });

  const onSubmit = useCallback(
    (values: SelectPNListDialogForm) => {
      onClose(values.list);
    },
    [onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          reset();
          TransitionProps?.onExit?.(node);
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Seleziona P/N</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="list"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <PartNumberAutocomplete
                label="Ricerca P/N"
                multiple
                fullWidth
                onChange={(_, value) => (Array.isArray(value) ? onChange(value) : onChange([]))}
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                sx={{ marginTop: 1 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <Button color="primary" type="submit">
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
