import { z } from 'zod';

export const UnitOfMeasureSchema = z.object({
  alias: z.string(),
  id: z.number(),
  name: z.string(),
});

export type UnitOfMeasure = z.infer<typeof UnitOfMeasureSchema>;

export const TurnbackTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type TurnbackType = z.infer<typeof TurnbackTypeSchema>;

export const EUSStatusSchema = z.object({
  id: z.number(),
  name: z.string(),
  draft: z.boolean(),
  toBeProcessed: z.boolean(),
  release: z.boolean(),
});

export type EUSStatus = z.infer<typeof EUSStatusSchema>;

export const SupplierLicenseTypeSchema = z.object({
  id: z.number(),
  validity: z.number(),
  name: z.string(),
});

export type SupplierLicenseType = z.infer<typeof SupplierLicenseTypeSchema>;

export const SupplyTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type SupplyType = z.infer<typeof SupplyTypeSchema>;
