import { useMemo, useState } from 'react';
import { DataGrid } from '@top-solution/microtecnica-mui';
import { stringSimilarity } from 'string-similarity-js';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SearchIcon } from '../../components/Icons';
import { useReadVendorListQuery } from '../../services/vendorApi';
import { useVendorColumns } from '../Admin/Vendor';

const ignoredWords = [
  'actuation',
  'aerospace',
  'aircraft',
  'aviation',
  'co',
  'company',
  'components',
  'corp',
  'corporation',
  'division',
  'electronic',
  'engineering',
  'euro',
  'france',
  'gmbh',
  'group',
  'gruppo',
  'inc',
  'incorporated',
  'industrial',
  'industriali',
  'industrie',
  'industries',
  'international',
  'italia',
  'italy',
  'limited',
  'llc',
  'ltd',
  'manufacturing',
  'precision',
  'precisione',
  'products',
  'sarl',
  'sas',
  'service',
  'services',
  'snc',
  'spa',
  'srl',
  'system',
  'systems',
  'technologies',
  'uk',
  'usa',
];
// Regexp to extract words from a string ignoring all punctuation and special characters, but not numbers
// '&' is considered part of a word because it is used in some company names
const words = /\b(?!\d+\b)[\w&]+\b/g;

function cleanupName(name: string): string {
  const cleaned = (name.toLowerCase().match(words) ?? [])
    .filter((token) => token.length > 1 && !ignoredWords.includes(token))
    .slice(0, 4)
    .join(' ');
  return cleaned || name;
}

function VendorListDialog(props: { vendorName: string } & DialogProps): JSX.Element {
  const { onClose, vendorName, ...dialogProps } = props;

  const columns = useVendorColumns();

  const readVendorList = useReadVendorListQuery();

  const rows = useMemo(() => {
    if (readVendorList.data?.length) {
      const cleanedVendorName = cleanupName(vendorName);
      const rows = readVendorList.data
        .map((vendor) => ({
          ...vendor,
          similarity: stringSimilarity(cleanedVendorName, cleanupName(vendor.name)),
        }))
        .filter((row) => row.similarity >= 0.5);
      rows.sort((a, b) => b.similarity - a.similarity);
      return rows;
    }
    return [];
  }, [readVendorList.data, vendorName]);

  return (
    <Dialog maxWidth="lg" fullWidth {...dialogProps} onClose={onClose}>
      <DialogTitle>Vendor Simili a {vendorName}</DialogTitle>
      <DialogContent sx={{ height: '50vh', padding: 0 }}>
        <DataGrid rows={rows} columns={columns} hideFooter />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose ? (e) => onClose(e, 'escapeKeyDown') : undefined}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShowButtonVendorListDialog(props: { vendorName: string } & IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { vendorName, ...buttonProps } = props;

  return (
    <>
      <Tooltip title="Ricerca vendor con nome simile">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...buttonProps} size="small">
          <SearchIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {dialogOpen && (
        <VendorListDialog open={dialogOpen} onClose={() => setDialogOpen(false)} vendorName={vendorName} />
      )}
    </>
  );
}
