import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MUIPremiumKey } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, setAuthApiUrl, ZodLocalizationProvider } from '@top-solution/microtecnica-utils';
import { it } from 'date-fns/locale/it';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400-italic.css';
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import { App } from './App';
import { store } from './store';
import { theme } from './theme';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;
const authApi = `${import.meta.env.VITE_AUTH_API}`;

// eslint-disable-next-line
console.log(`🚀 ${appId} (v. ${appVersion}) 🚀`);

setAuthApiUrl(`${authApi}/api`);

LicenseInfo.setLicenseKey(MUIPremiumKey);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <CommonDataApiProvider value={{ appId, authApi }}>
        <ZodLocalizationProvider />
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </CommonDataApiProvider>
    </LocalizationProvider>
  </StrictMode>,
);
