import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../../components/Icons';
import { ECCN } from '../../../entities/ECCN';
import { ECCNEditDialog } from './ECCNEditDialog';

const initialValues: ECCN = {
  id: '',
  isMilitary: false,
  country: null,
  legislation: '',
  availableToManufacturers: false,
} as unknown as ECCN;

export function ECCNAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Aggiungi ECCN">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <ECCNEditDialog open={dialogOpen} initialValues={initialValues} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}
