import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { ThirdParty, ThirdPartyAddForm, ThirdPartySchema } from '../entities/ThirdParty';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/third-parties';

const thirdPartyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readThirdPartyList: builder.query<PagedResponse<ThirdParty>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ThirdPartySchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.THIRD_PARTY, id: 'LIST' }],
    }),
    createThirdParty: builder.mutation<void, ThirdPartyAddForm>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.THIRD_PARTY, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
    updateThirdParty: builder.mutation<void, ThirdPartyAddForm>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.THIRD_PARTY, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
    removeThirdParty: builder.mutation<void, ThirdParty>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'DELETE' }),
      invalidatesTags: () => [
        { type: TAG_TYPES.THIRD_PARTY, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadThirdPartyListQuery,
  useCreateThirdPartyMutation,
  useUpdateThirdPartyMutation,
  useRemoveThirdPartyMutation,
} = thirdPartyApi;
