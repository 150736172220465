import { ReactNode, createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { EUS, EUSEditModel } from '../../../entities/EUS';
import { eusSection } from '../../../pages/sections';

interface EUSSaveStatus {
  isLoading: boolean;
  error?: ApiError | ZodError;
  reset: () => void;
}

interface EUSFormContextType {
  data: EUSEditModel;
  initialData: EUSEditModel;
  defaultValues: EUSEditModel;
  step: number;
  handleNext: (data: EUSEditModel) => void;
  handleBack: () => void;
  handleSave: (data: EUSEditModel) => void;
  handleCancel: () => void;
  saveStatus: EUSSaveStatus;
  cancelConfirmDialog: {
    open: boolean;
    setOpen: (open: boolean) => void;
  };
}

function noop() {
  // do nothing
}

export const EUSFormContext = createContext<EUSFormContextType>({
  data: null as unknown as EUSEditModel,
  initialData: null as unknown as EUSEditModel,
  defaultValues: null as unknown as EUSEditModel,
  step: 0,
  cancelConfirmDialog: { open: false, setOpen: noop },
  handleNext: noop,
  handleBack: noop,
  handleSave: noop,
  handleCancel: noop,
  saveStatus: { isLoading: false, reset: noop },
});

type EUSFormContextProviderProps = Pick<EUSFormContextType, 'initialData'> & {
  children: ReactNode | ReactNode[];
  save: (data: EUSEditModel) => Promise<EUS>;
  saveStatus: EUSSaveStatus;
};

export function EUSFormContextProvider(props: EUSFormContextProviderProps): JSX.Element {
  const { children, initialData, save, saveStatus } = props;
  const [step, setStep] = useState(0);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
  const [data, setData] = useState(initialData);
  const [defaultValues, setDefaultValues] = useState(initialData);
  const [saveSuccessSnackbarOpen, setSaveSuccessSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleNext = useCallback((data: EUSEditModel) => {
    setData(data);
    setStep((step) => step + 1);
  }, []);

  const handleBack = useCallback(() => setStep((step) => step - 1), []);

  const handleClose = useCallback(
    (eus?: EUS) => {
      const id = eus?.id ?? initialData.id;
      navigate(id ? `${eusSection.url}/${id}` : eusSection.url);
    },
    [initialData, navigate],
  );

  const handleSave = useCallback(
    async (data: EUSEditModel) => {
      const response = await save(data);
      if (!confirmCancelDialogOpen && initialData.id !== undefined) {
        setData(data);
        setDefaultValues(data);
        setSaveSuccessSnackbarOpen(true);
      } else {
        handleClose(response);
      }
    },
    [confirmCancelDialogOpen, handleClose, initialData.id, save],
  );

  return (
    <EUSFormContext.Provider
      value={{
        step,
        initialData,
        data,
        defaultValues,
        cancelConfirmDialog: {
          open: confirmCancelDialogOpen,
          setOpen: setConfirmCancelDialogOpen,
        },
        handleBack,
        handleNext,
        handleSave,
        handleCancel: handleClose,
        saveStatus,
      }}
    >
      {children}
      <Snackbar
        open={saveSuccessSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSaveSuccessSnackbarOpen(false)}
      >
        <Alert severity="success">Salvataggio avvenuto con successo</Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(saveStatus.error)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => saveStatus.reset()}
      >
        <Alert severity="error">Errore durante il salvataggio</Alert>
      </Snackbar>
    </EUSFormContext.Provider>
  );
}
