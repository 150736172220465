import { useCallback, useMemo, useState, forwardRef } from 'react';
import { FilterOperator, PagedRequestParams } from '@top-solution/microtecnica-utils';
import debounce from 'lodash.debounce';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useLazySearchItemQuery } from '../../services/itemApi';

export type PartNumberAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput' | 'onInputChange'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'required' | 'placeholder' | 'sx'> & {
    endItemsOnly?: boolean;
  };

function PartNumberAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: PartNumberAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { label, error, value, helperText, endItemsOnly, placeholder, required, sx, ...autocompleteProps } = props;
  const [needle, setNeedle] = useState('');
  const [searchPartNumber, { data: response, error: requestError, isLoading }] = useLazySearchItemQuery();

  const search = useMemo(
    () =>
      debounce(
        (needle: string) => {
          const params: PagedRequestParams & { isEnditem?: boolean } = {
            offset: 0,
            limit: 10,
            sort: ['pn'],
            filters: [{ field: 'pn', operator: FilterOperator.like, value: `${needle}%` }],
          };
          if (endItemsOnly !== undefined) {
            params.isEnditem = endItemsOnly;
          }
          searchPartNumber(params);
        },
        300,
        { leading: false, trailing: true },
      ),
    [endItemsOnly, searchPartNumber],
  );

  const options = useMemo<string[]>(() => {
    let options = new Array<string>();
    if (typeof value === 'string') {
      options = [value as string];
    } else if (Array.isArray(value)) {
      options = [...value];
    }
    if (needle.length >= 3 && response?.data) {
      options = [...options, ...response.data.map(({ pn }) => pn)];
    }
    return options;
  }, [needle, response, value]);

  const handleInputChange = useCallback(
    (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
      setNeedle(value);
      if (reason === 'input' && value.length >= 2) {
        search(value);
      }
    },
    [search],
  );

  return (
    <Autocomplete
      autoComplete
      value={value}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          inputRef={ref}
          error={Boolean(requestError) || error}
          helperText={(requestError && 'message' in requestError && requestError.message) || helperText || undefined}
          required={required}
          sx={sx}
        />
      )}
      noOptionsText={needle.length < 2 ? 'Digita almeno 2 caratteri' : undefined}
      loading={isLoading}
      clearOnEscape
      {...autocompleteProps}
    />
  );
}

export const PartNumberAutocomplete = forwardRef(
  PartNumberAutocompleteComponent,
) as typeof PartNumberAutocompleteComponent;
