import {
  EUSStatus,
  EUSStatusSchema,
  SupplierLicenseType,
  SupplierLicenseTypeSchema,
  SupplyType,
  SupplyTypeSchema,
  TurnbackType,
  TurnbackTypeSchema,
  UnitOfMeasure,
  UnitOfMeasureSchema,
} from '../entities/EUSAttributes';
import { api } from './baseApi';

const url = 'v1/eus-attributes';

const eusAttributesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readUnitOfMeasureList: builder.query<UnitOfMeasure[], void>({
      query: () => `${url}/units-of-measure`,
      transformResponse: (data) => UnitOfMeasureSchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
    readTurnbackTypeList: builder.query<TurnbackType[], void>({
      query: () => `${url}/turnback-types`,
      transformResponse: (data) => TurnbackTypeSchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
    readStatusList: builder.query<EUSStatus[], void>({
      query: () => `${url}/statuses`,
      transformResponse: (data) => EUSStatusSchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
    readSupplierLicenseTypeList: builder.query<SupplierLicenseType[], void>({
      query: () => `${url}/supplier-license-types`,
      transformResponse: (data) => SupplierLicenseTypeSchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
    readSupplyTypeList: builder.query<SupplyType[], void>({
      query: () => `${url}/supply-types`,
      transformResponse: (data) => SupplyTypeSchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadUnitOfMeasureListQuery,
  useReadTurnbackTypeListQuery,
  useReadStatusListQuery,
  useReadSupplierLicenseTypeListQuery,
  useReadSupplyTypeListQuery,
} = eusAttributesApi;
