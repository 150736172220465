import { AuthGuard } from '@top-solution/microtecnica-utils';
import { EUSEditForm } from '../../components/EUS/EUSEditForm';
import { EUSFormContextProvider } from '../../components/EUS/EUSEditForm/EUSFormContext';
import { Layout } from '../../components/Layout';
import { EUSEditModel } from '../../entities/EUS';
import { UserRole } from '../../entities/User';
import { useCreateEUSMutation } from '../../services/eusApi';

import UnauthorizedPage from '../Error/UnauthorizedPage';
import { eusSection } from '../sections';

const breadcrumbs = [{ title: eusSection.title, url: eusSection.url }, { title: 'Nuova pratica' }];

const initialData = {
  eusReleaseDate: null,
  eusLinkedLicense: null,
  plantId: null,
  pgr: null,
  vendor: null,
  vendorRequestDate: null,
  pnList: [],
  enduserList: [],
  thirdPartyList: [],
  additionalEUSRestrictions: null,
  attachmentList: [],
  originalSentToSupplier: null,
  statusId: null,
  supplierLicenseTypeId: null,
  supplierLicenseTypeDuration: null,
  turnbackTypeId: null,
  turnbackNotes: null,
} as unknown as EUSEditModel;

export function EUSAddPage(): JSX.Element {
  const [create, createStatus] = useCreateEUSMutation();

  return (
    <AuthGuard
      unauthorizedFallback={<UnauthorizedPage />}
      authorizeRole={(role) => role === UserRole.ADMIN || role === UserRole.FOREIGN_CLASSIFICATION_MANAGER}
    >
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ padding: 1 }}>
        <EUSFormContextProvider
          initialData={initialData}
          save={(data) => create(data).unwrap()}
          saveStatus={createStatus}
        >
          <EUSEditForm />
        </EUSFormContextProvider>
      </Layout>
    </AuthGuard>
  );
}
