import { useMemo } from 'react';
import Button from '@mui/material/Button';
import { red, green, blue } from '@mui/material/colors';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { UserName } from '../../../components/User/UserName';
import { HistoryAction, HistoryActionLabel, HistoryRecord } from '../../../entities/History';

function parseUnknownValue(value: unknown): string {
  if (typeof value === 'string') {
    return value;
  }
  if (value === null || value === undefined) {
    return '–';
  }
  if (typeof value === 'object') {
    return JSON.stringify(value, null, 2);
  }
  return String(value);
}

type HistoryDiffDialogProps = Omit<DialogProps, 'onClose'> & {
  data: HistoryRecord;
  onClose: () => void;
};

export function HistoryDiffDialog(props: HistoryDiffDialogProps): JSX.Element {
  const { data, onClose, ...dialogProps } = props;
  const timestamp = new Date(data.timestamp);

  const newValue = useMemo(() => data.newValue && JSON.parse(data.newValue), [data.newValue]);
  const oldValue = useMemo(() => data.oldValue && JSON.parse(data.oldValue), [data.oldValue]);

  const rows = useMemo<JSX.Element[]>(() => {
    const keys = new Set<string>();
    if (newValue) {
      Object.keys(newValue).forEach((key) => keys.add(key));
    }
    if (oldValue) {
      Object.keys(oldValue).forEach((key) => keys.add(key));
    }
    return [...keys].map((key) => {
      const newProperty = parseUnknownValue(newValue?.[key]);
      const oldPropery = parseUnknownValue(oldValue?.[key]);
      return (
        <TableRow key={key} className={newProperty !== oldPropery ? 'value-changed' : undefined}>
          <TableCell>{key}</TableCell>
          {data.action !== HistoryAction.Delete && <TableCell>{newProperty}</TableCell>}
          {data.action !== HistoryAction.Create && <TableCell>{oldPropery}</TableCell>}
        </TableRow>
      );
    });
  }, [data.action, newValue, oldValue]);

  return (
    <Dialog maxWidth="md" fullWidth {...dialogProps}>
      <DialogTitle>{`${HistoryActionLabel[data.action]} ${data.entity.name} ID ${data.objectId}`}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Effettuata da <UserName username={data.user} /> in data {timestamp.toLocaleDateString()} alle{' '}
          {timestamp.toLocaleTimeString()}
        </Typography>
        <Table
          size="small"
          sx={{
            '.value-changed': {
              bgcolor:
                data.action === HistoryAction.Create
                  ? green[50]
                  : data.action === HistoryAction.Delete
                    ? red[50]
                    : blue[50],
            },
            '.MuiTableCell-root': {
              whiteSpace: 'pre-wrap',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Proprietà</TableCell>
              {data.action !== HistoryAction.Delete && <TableCell>Valore corrente</TableCell>}
              {data.action !== HistoryAction.Create && <TableCell>Valore precedente</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
