import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '..';
import { BottomUpSearchPage } from './BottomUpSearch/BottomUpSearchPage';
import { TopDownSearchPage } from './TopDownSearch/TopDownSearchPage';

export default function QueryRouter(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Navigate to="/" />} />
      <Route path="bottom-up" element={<BottomUpSearchPage />} />
      <Route path="top-down" element={<TopDownSearchPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
