import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { UserLabel } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { EUSDeleteConfirm } from '../../components/EUS';
import { EUSListDetails } from '../../components/EUS/EUSListDetails';
import { DeleteIcon, EditIcon, FileIcon } from '../../components/Icons/Icons';
import { Layout } from '../../components/Layout';
import { EUS, EUSFieldDescriptionlMap } from '../../entities/EUS';
import { UserRole } from '../../entities/User';
import { useReadEUSByIdQuery } from '../../services/eusApi';
import { useReadStatusListQuery } from '../../services/eusAttributesApi';
import { formatStringDate } from '../../utils/utils';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { eusSection } from '../sections';
import { EUSCustomerDetailsDataGrid } from './EUSCustomerDetailsDataGrid';
import { EUSDownloadAttachmentA } from './EUSDownloadAttachmentA';
import { EUSEccnDetailsDataGrid } from './EUSEccnDetailsDataGrid';
import { EUSEndUserDetailsDataGrid } from './EUSEndUserDetailsDataGrid';
import { EUSPODetailsDataGrid } from './EUSPODetailsDataGrid';
import { EUSRealease } from './EUSRelease';
import { EUSThirdPartiesDetailsDataGrid } from './EUSThirdPartiesDetailsDataGrid';

const breadcrumbs = [{ title: eusSection.title, url: eusSection.url }];

interface EUSDetailItemProps {
  label: string;
  value?: string;
}

export function EUSDetailItem(props: EUSDetailItemProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {props.label}:
      </Typography>
      <Typography variant="body1" ml={1} whiteSpace="nowrap">
        {props.value}
      </Typography>
    </Box>
  );
}

export function EUSDetailPage(): JSX.Element {
  const params = useParams<'eusId'>();
  const eusId = useMemo(() => {
    const eusId = parseInt(params.eusId ?? '');
    if (!isNaN(eusId)) {
      return eusId;
    }
    return null;
  }, [params.eusId]);

  const { isAdmin, hasRole } = useAuth();
  const canEditEUS = useMemo(() => isAdmin || hasRole(UserRole.FOREIGN_CLASSIFICATION_MANAGER), [hasRole, isAdmin]);
  const navigate = useNavigate();

  const { data: eus, isLoading, error } = useReadEUSByIdQuery(eusId ?? skipToken);
  const readEUSStatusList = useReadStatusListQuery();

  const [eusToRemove, setEusToRemove] = useState<EUS | null>(null);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        disableGutters
        sx={{ padding: 1 }}
        breadcrumbs={[...breadcrumbs, { title: eus?.protocolNum ?? eusId }]}
        inProgress={isLoading}
        error={error}
      >
        <Card>
          {eus && (
            <CardContent>
              <Stack direction="column" gap={2}>
                <Stack
                  direction={{ xs: 'column-reverse', md: 'row' }}
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <Typography component="h1" variant="h4">
                    {eus.protocolNum}
                  </Typography>

                  <Stack direction="row" gap={1}>
                    {eus.attachmentList.length && <EUSDownloadAttachmentA eus={eus} />}
                    {canEditEUS && (
                      <>
                        <Tooltip title="Modifica Documento EUS">
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            startIcon={<EditIcon />}
                            onClick={() => navigate(`/eus/${eusId}/edit`)}
                          >
                            Modifica
                          </Button>
                        </Tooltip>
                        {readEUSStatusList.data &&
                          !readEUSStatusList.data?.find((status) => status.id === eus.statusId)?.release && (
                            <EUSRealease eus={eus} />
                          )}
                        <Tooltip title="Elimina Documento EUS">
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => setEusToRemove(eus)}
                          >
                            Elimina
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  </Stack>
                </Stack>
                <div>
                  <Typography variant="body2">
                    Pratica inserita da <UserLabel username={eus.createdBy} />
                    &nbsp;in data {formatStringDate(eus.createdDate)}.
                  </Typography>
                  {eus.updatedBy && eus.updatedDate && (
                    <Typography variant="body2">
                      Ultimo aggiornamento effettuato da <UserLabel username={eus.updatedBy} />
                      &nbsp;in data {formatStringDate(eus.updatedDate)}.
                    </Typography>
                  )}
                </div>
                {eusToRemove && (
                  <EUSDeleteConfirm
                    open={Boolean(eusToRemove)}
                    data={eusToRemove}
                    onClose={() => {
                      setEusToRemove(null);
                    }}
                    onConfirm={() => {
                      navigate(eusSection.url);
                    }}
                  />
                )}
                <EUSListDetails eus={eus} />
                <div>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {EUSFieldDescriptionlMap.attachmentList}:
                  </Typography>
                  {eus.attachmentList.length && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
                      {eus.attachmentList.map((a) => (
                        <Chip
                          key={a.id}
                          label={a.name}
                          sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                          icon={<FileIcon />}
                          onClick={() => window.open(a.path, '_blank')?.focus()}
                        />
                      ))}
                    </Box>
                  )}
                </div>

                <EUSEccnDetailsDataGrid eus={eus} />
                <EUSCustomerDetailsDataGrid eus={eus} />
                <EUSEndUserDetailsDataGrid eus={eus} />
                <EUSThirdPartiesDetailsDataGrid eus={eus} />
                <EUSPODetailsDataGrid eus={eus} />
              </Stack>
            </CardContent>
          )}
        </Card>
      </Layout>
    </AuthGuard>
  );
}
