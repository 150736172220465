import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import { darken } from '@mui/system';
import { AppSection } from '../../entities/AppSection';

type AppSectionListRowProps = StackProps & {
  list: AppSection[];
  color: string;
};

export function AppSectionListRow(props: AppSectionListRowProps) {
  const { list, color, sx, ...stackProps } = props;
  return (
    <Stack
      sx={{
        gap: 2,
        flexWrap: 'wrap',
        '.MuiButton-root': {
          paddingX: 1,
          paddingTop: 3,
          width: 150,
          height: 150,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          textAlign: 'center',
          lineHeight: 1.2,
          background: color,

          '&:hover': {
            background: darken(color, 0.1),
          },
        },
        '.MuiButton-startIcon': {
          marginX: 0,
          marginBottom: 2,

          '.MuiSvgIcon-root': {
            fontSize: 50,
          },
        },
        ...sx,
      }}
      {...stackProps}
    >
      {list.map((section) => (
        <Button key={section.url} component={Link} to={section.url} startIcon={section.icon} variant="contained">
          {section.title}
        </Button>
      ))}
    </Stack>
  );
}
