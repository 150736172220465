import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../../components/Icons';
import { SerniCategory } from '../../../entities/SerniCategory';
import { SerniCategoryEditDialog } from './SerniCategoryEditDialog';

const defaultValues: SerniCategory = {
  pn: null,
  programId: null,
  code: '',
  description: '',
} as unknown as SerniCategory;

export function SerniCategoryAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Aggiungi categoria SERNI">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <SerniCategoryEditDialog open={dialogOpen} initialValues={defaultValues} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}
