import { z } from 'zod';

export enum FileImportStatus {
  SUCCESS = 'Successo',
  NEVER_PARSED = 'Mai analizzato',
  ERROR = 'Errore',
}

export const ImportStatusSchema = z.object({
  description: z.string(),
  path: z.string(),
  error: z.string(),
  lastRun: z.string().nullable(),
  lastSuccess: z.string().nullable(),
});

export type ImportStatus = z.infer<typeof ImportStatusSchema>;
