import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SaveIcon } from '../../../components/Icons';
import {
  ForeignClassification,
  ForeignClassificationAddForm,
  ForeignClassificationAddFormSchema,
} from '../../../entities/ForeignClassification';
import { useUpdateForeignClassificationMutation } from '../../../services/foreignClassificationApi';
import { ForeignClassificationEditForm } from './ForeignClassificationEditForm';

export type ForeignClassificationEditDialogProps = Omit<DialogProps, 'onClose'> & {
  value: ForeignClassification;
  onClose: (value?: ForeignClassification) => void;
};

export function ForeignClassificationEditDialog(props: ForeignClassificationEditDialogProps): JSX.Element {
  const { value, onClose, ...dialogProps } = props;
  const [update, { isLoading, error }] = useUpdateForeignClassificationMutation();
  const form = useForm<ForeignClassificationAddForm>({
    defaultValues: value,
    resolver: zodResolver(ForeignClassificationAddFormSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = useCallback(
    async (values: ForeignClassificationAddForm) => {
      const foreignClassification: ForeignClassification = { ...values, id: value.id, lastUpdate: value.lastUpdate };
      await update(foreignClassification).unwrap();
      onClose(foreignClassification);
    },
    [value.id, value.lastUpdate, update, onClose],
  );

  return (
    <Dialog maxWidth="md" fullWidth {...dialogProps}>
      <DialogTitle>Modifica classificazione estera</DialogTitle>
      <DialogContent sx={{ '.FormRow-root': { mt: 1, '& > *': { flex: 1 } } }}>
        <ForeignClassificationEditForm form={form} editMode />
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
