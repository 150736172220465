import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { PurchaseGroupDataGrid } from '../PurchaseGroup';
import { SelectItemListDialogProps } from './SelectItemListDialogProps';

export function SelectPurchaseGroupListDialog(props: SelectItemListDialogProps<string>): JSX.Element {
  const { onClose, ...dialogProps } = props;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [showError, setShowError] = useState(false);

  const onSubmit = useCallback(() => {
    if (Array.isArray(selectionModel) && selectionModel.length > 0) {
      onClose(selectionModel.map((value) => value.toString()));
    } else {
      setShowError(true);
    }
  }, [onClose, selectionModel]);

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <DialogTitle>Seleziona Purchase Group</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <PurchaseGroupDataGrid
          checkboxSelection
          onRowSelectionModelChange={setSelectionModel}
          rowSelectionModel={selectionModel}
          autoHeight
        />
      </DialogContent>
      <DialogActions>
        {showError && (selectionModel as string[]).length === 0 && (
          <Typography variant="caption" color="error" sx={{ flexGrow: 1 }}>
            Seleziona almeno un elemento
          </Typography>
        )}
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
