import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { IssueData, z, ZodIssueCode } from 'zod';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGridPremiumProps, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium/DataGridPremium';
import { BottomUpSearchParams, BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { EUSEditCustomerItem, EUSEditModel, EUSEditSchema } from '../../../entities/EUS';
import { useBottomUpSearchQuery } from '../../../services/searchApi';
import { createId, isAutoGeneratedRow, rowEmptyId, rowIdSeparator, selectionModelsEquals } from '../../DataGrid';
import { useCreateGroupingRowId, useExtractGroupingRowComponents } from './columns';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

export const EUSRecipientSelectionFormSchema = EUSEditSchema.pick({ pnList: true });

export type EUSRecipientSelectionForm = z.infer<typeof EUSRecipientSelectionFormSchema>;

type RowWithId = BottomUpSearchResult & { id: string };
type Tree = Map<string, Tree | RowWithId>;

type GetRowId = (row: BottomUpSearchResult) => string;
type GetRecipientId = (pn: string, row: EUSEditCustomerItem) => string;

type EUSRecipientPropertySelectionStepProps = Omit<
  DataGridPremiumProps<RowWithId>,
  | 'columns'
  | 'rows'
  | 'error'
  | 'loading'
  | 'density'
  | 'hideFooter'
  | 'selectionModel'
  | 'onSelectionModelChange'
  | 'checkboxSelection'
  | 'disableColumnPinning'
  | 'defaultGroupingExpansionDepth'
  | 'groupingColDef'
  | 'initialState'
  | 'disableColumnMenu'
> & {
  bottomUpSearchParams: BottomUpSearchParams;
  getRowId: GetRowId;
  getRecipientId: GetRecipientId;
  getPreviousStepRowId?: GetRowId;
  getPreviousStepRecipientId?: GetRecipientId;
  getGroupId: (recipient: EUSEditCustomerItem) => string;
  columns: GridColDef[];
  initialState: DataGridPremiumProps['initialState'];
  createRecipient: (groupId: string, propertyId?: string) => EUSEditCustomerItem;
  getRecipientProperty: (recipient: EUSEditCustomerItem) => string | null;
  getErrorMessage?: (pn: string, recipient?: EUSEditCustomerItem) => string;
  groupColWidth?: number;
};

function getComponentsFromId(id: string): { pn: string; groupId: string; propertyId: string; raw: string[] } {
  const raw = id.split(rowIdSeparator);
  return {
    pn: raw[0],
    groupId: raw.slice(1, -1).join(rowIdSeparator) || rowEmptyId,
    propertyId: raw[raw.length - 1],
    raw,
  };
}

function getTreeLeavesFromPath(tree: Tree, path: string[]): GridRowId[] {
  const [id, ...childrenPath] = path;
  const currentNode = tree.get(id);
  if (currentNode) {
    if ('id' in currentNode) {
      return [currentNode.id];
    } else if (childrenPath.length > 0) {
      return getTreeLeavesFromPath(currentNode, childrenPath);
    } else {
      const children = new Array<GridRowId>();
      currentNode.forEach((child, childId) => {
        const nodes = getTreeLeavesFromPath(currentNode, [childId]);
        children.push(...nodes);
      });
      return children;
    }
  }
  return [];
}

function getTreeSelection(
  tree: Tree,
  selectionModel: Set<GridRowId>,
  selection: GridRowId[],
  path: string[],
  createGroupRowId: (components: string[]) => GridRowId | undefined,
): boolean {
  let allChildrenSelected = tree.size > 0;
  tree.forEach((node, id) => {
    const nodePath = [...path, id];
    if ('id' in node) {
      const currentRowId = createId(nodePath);
      // If the current node is a leaf (e.g.: a datagrid row)
      if (selectionModel.has(currentRowId)) {
        // If the leaf is empty (error case) it must not be selected
        if (id !== rowEmptyId) {
          selection.push(currentRowId);
        }
      } else {
        allChildrenSelected = false;
      }
    } else {
      const nodeId = createGroupRowId(nodePath);
      // Otherwise this is a tree
      if (nodeId) {
        const allCurrentNodeChildrenSelected = getTreeSelection(
          node,
          selectionModel,
          selection,
          nodePath,
          createGroupRowId,
        );

        allChildrenSelected = allChildrenSelected && allCurrentNodeChildrenSelected;

        if (allCurrentNodeChildrenSelected) {
          selection.push(nodeId);
        }
      }
    }
  });
  return allChildrenSelected;
}

/**
 * Removes invalid leaves from a tree data structure based on a given validation function.
 * The invalid leaves are removed only if they don't have any valid siblings.
 * @param {Tree} tree - The tree to shake
 * @param isValidLeaf - Function to check if a leaf is "valid" or not
 * @returns a Set containing the IDs of the leaves that were removed from the tree because they did not pass the
 * isValidLeaf test
 */
function removeInvalidLeavesFromTree(
  tree: Tree,
  isValidLeaf: (leave: RowWithId) => boolean,
  removedLeaves = new Set<RowWithId['id']>(),
): Set<RowWithId['id']> {
  const invalidChildLeaves = new Set<RowWithId['id']>();
  tree.forEach((node) => {
    if ('id' in node && !isValidLeaf(node)) {
      invalidChildLeaves.add(node.id);
    }
  });

  if (invalidChildLeaves.size === tree.size) {
    tree.forEach((node) => {
      if ('id' in node) {
        if (invalidChildLeaves.has(node.id) && tree.size > 1) {
          tree.delete(node.id);
          removedLeaves.add(node.id);
        }
      }
    });
  } else {
    tree.forEach((node) => {
      if ('id' in node) {
        if (invalidChildLeaves.has(node.id)) {
          tree.delete(node.id);
          removedLeaves.add(node.id);
        }
      } else {
        removeInvalidLeavesFromTree(node, isValidLeaf, removedLeaves);
      }
    });
  }

  return removedLeaves;
}

export function EUSRecipientPropertySelectionStep(props: EUSRecipientPropertySelectionStepProps): JSX.Element {
  const { data, handleBack, handleNext, handleSave } = useContext(EUSFormContext);
  const {
    bottomUpSearchParams,
    getRowId,
    getRecipientId,
    getPreviousStepRowId,
    getPreviousStepRecipientId,
    getGroupId,
    createRecipient,
    getRecipientProperty,
    columns,
    initialState,
    getErrorMessage,
    groupColWidth,
    isRowSelectable,
    ...dataGridProps
  } = props;

  const bottomUpSearch = useBottomUpSearchQuery(bottomUpSearchParams);

  const { handleSubmit, formState, setValue, reset } = useForm<EUSRecipientSelectionForm>({
    defaultValues: data,
    resolver:
      getErrorMessage &&
      zodResolver(
        EUSRecipientSelectionFormSchema.superRefine((data, ctx) => {
          data.pnList?.forEach((item) => {
            if (item.customerList?.length) {
              item.customerList?.forEach((recipient) => {
                if (getRecipientProperty(recipient) === null && getPreviousStepRecipientId) {
                  ctx.addIssue({
                    code: ZodIssueCode.custom,
                    path: ['pnList', getPreviousStepRecipientId(item.pn, recipient)],
                    message: getErrorMessage(item.pn, recipient),
                  });
                }
              });
            } else {
              ctx.addIssue({
                code: ZodIssueCode.custom,
                path: ['pnList', item.pn],
                message: getErrorMessage(item.pn),
              });
            }
          });
        }),
      ),
  });

  const previousStepSelectedIds = useMemo(
    () =>
      getPreviousStepRecipientId &&
      data.pnList?.reduce((set, item) => {
        item.customerList?.forEach((recipient) => set.add(getPreviousStepRecipientId(item.pn, recipient)));
        return set;
      }, new Set<string>()),
    [data.pnList, getPreviousStepRecipientId],
  );

  const { rows, tree } = useMemo(() => {
    const alreayDefinedIds = new Set<string>();
    const rows = new Array<RowWithId>();
    const root: Tree = new Map<string, Tree>();

    bottomUpSearch.data?.forEach((row) => {
      const id = getRowId(row);
      if (
        // avoid "collisions" on multiple lines with same id when coming back from the next step
        !alreayDefinedIds.has(id) &&
        // skip rows that should be excluded because of previous selection (when present)
        (!getPreviousStepRowId || previousStepSelectedIds?.has(getPreviousStepRowId(row)))
      ) {
        alreayDefinedIds.add(id);
        const data = { ...row, id };
        rows.push(data);
        const { raw: idComponents } = getComponentsFromId(id);
        let node = root;
        idComponents.forEach((component, index) => {
          if (index === idComponents.length - 1) {
            node.set(component, data);
          } else {
            if (!node.has(component)) {
              node.set(component, new Map<string, Tree>());
            }
            node = node.get(component) as Tree;
          }
        });
      }
    });

    const removedLeaves = removeInvalidLeavesFromTree(root, (row) =>
      Boolean(getRecipientProperty(row as unknown as EUSEditCustomerItem)),
    );

    return { rows: removedLeaves.size > 0 ? rows.filter(({ id }) => !removedLeaves.has(id)) : rows, tree: root };
  }, [bottomUpSearch.data, getPreviousStepRowId, getRecipientProperty, getRowId, previousStepSelectedIds]);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const initialSelectionModel = useRef<GridRowSelectionModel>([]);

  const onSubmit = (callback: (data: EUSEditModel) => void, event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    /* Creating an empty hierarchy to group all rows by pn and a generated groupId */
    const selectionByPnGroup = new Map<string, Map<string, Set<string>>>();
    rows?.forEach(({ id }) => {
      const { pn, groupId } = getComponentsFromId(id);
      const groupMap = selectionByPnGroup.get(pn) ?? new Map<string, Set<string>>();
      const propertySet = groupMap.get(groupId) ?? new Set<string>();
      groupMap.set(groupId, propertySet);
      selectionByPnGroup.set(pn, groupMap);
    });
    /* Add selected properties to the hierarchy */
    selectionModel.forEach((id) => {
      if (typeof id === 'string') {
        const { pn, groupId, propertyId } = getComponentsFromId(id);
        selectionByPnGroup.get(pn)?.get(groupId)?.add(propertyId);
      }
    });

    /* Creating a hierarchy to group recipients by pn and a generated groupId */
    const recipientsByPnGroup = data.pnList?.reduce((pnMap, item) => {
      item.customerList?.forEach((recipient) => {
        const groupMap = pnMap.get(item.pn) ?? new Map<string, EUSEditCustomerItem[]>();
        const groupId = getGroupId(recipient);
        const shipToList = groupMap.get(groupId) ?? ([] as EUSEditCustomerItem[]);
        shipToList.push(recipient);
        groupMap.set(groupId, shipToList);
        pnMap.set(item.pn, groupMap);
      });
      return pnMap;
    }, new Map<string, Map<string, EUSEditCustomerItem[]>>());

    /* Regenerate the recipient list on each pn according to selected rows */
    const pnList = data.pnList?.map((item) => {
      const itemCustomerList = new Array<EUSEditCustomerItem>();
      const recipientCountForGroup = new Map<string, number>();
      /* Iterate over the "previous" recipients */
      recipientsByPnGroup?.get(item.pn)?.forEach((customerList, groupId) => {
        const propertySet = selectionByPnGroup.get(item.pn)?.get(groupId);
        let count = recipientCountForGroup.get(groupId) ?? 0;

        customerList.forEach((recipient) => {
          const propertyValue = getRecipientProperty(recipient);
          /* Pick previous recipients if they're still selected */
          if (propertyValue !== null && propertySet?.has(propertyValue)) {
            itemCustomerList.push(recipient);
            propertySet.delete(propertyValue);
            count = count + 1;
          }
        });
        recipientCountForGroup.set(groupId, count);
      });
      /* Iterate over the "current" selection */
      selectionByPnGroup.get(item.pn)?.forEach((propertySet, groupId) => {
        let count = recipientCountForGroup.get(groupId) ?? 0;
        /* Add newly selected recipients */
        propertySet?.forEach((propertyId) => {
          const recipient = createRecipient(groupId, propertyId);

          itemCustomerList.push(recipient);
          count = count + 1;
        });
        /* Add a phantom recipient when there is a previous step but the recipient count for this group is 0 */
        if (getPreviousStepRowId && count === 0) {
          const recipient = createRecipient(groupId);

          itemCustomerList.push(recipient);
        }
      });
      return { ...item, customerList: itemCustomerList };
    });

    setValue('pnList', pnList ?? []);

    return handleSubmit((formData) => callback({ ...data, ...formData }))(event);
  };

  const extractGroupingRowComponents = useExtractGroupingRowComponents();
  const createGroupRowId = useCreateGroupingRowId();

  const handleSelectionModelChange = useCallback(
    (currentValue: GridRowSelectionModel) => {
      setSelectionModel((previousValue: GridRowSelectionModel) => {
        const previousSelectedIds = new Set<GridRowId>(previousValue);
        const currentSelectedIds = new Set<GridRowId>();

        const addedNodes = new Set<GridRowId>();
        const removedNodes = new Set<GridRowId>();

        currentValue.forEach((id) => {
          currentSelectedIds.add(id);
          if (!previousSelectedIds.has(id) && isAutoGeneratedRow(id)) {
            addedNodes.add(id);
          }
        });

        previousSelectedIds.forEach((id) => {
          if (!currentSelectedIds.has(id) && isAutoGeneratedRow(id)) {
            removedNodes.add(id);
          }
        });

        addedNodes.forEach((id) => {
          const path = extractGroupingRowComponents(id);
          if (path?.length) {
            getTreeLeavesFromPath(tree, path).forEach((id) => currentSelectedIds.add(id));
          }
        });

        removedNodes.forEach((id) => {
          const path = extractGroupingRowComponents(id);
          if (path?.length) {
            getTreeLeavesFromPath(tree, path).forEach((id) => currentSelectedIds.delete(id));
          }
        });

        const selection = new Array<GridRowId>();
        getTreeSelection(tree, currentSelectedIds, selection, [], createGroupRowId);
        return selection;
      });
    },
    [tree, createGroupRowId, extractGroupingRowComponents],
  );

  useEffect(() => {
    if (tree.size > 0) {
      const selectedIdsSet = new Set<string>();
      data.pnList?.forEach((item) => {
        item.customerList?.forEach((recipient) => {
          if (getRecipientProperty(recipient) !== null) {
            selectedIdsSet.add(getRecipientId(item.pn, recipient));
          }
        });
      });
      const selection = new Array<GridRowId>();
      getTreeSelection(tree, selectedIdsSet, selection, [], createGroupRowId);
      setSelectionModel(selection);
      initialSelectionModel.current = selection;
    }
  }, [createGroupRowId, data.pnList, getRecipientId, getRecipientProperty, tree]);

  const selectionHasChanged = useMemo(
    () => !selectionModelsEquals(initialSelectionModel.current, selectionModel),
    [selectionModel],
  );

  return (
    <form onSubmit={(event) => onSubmit(handleNext, event)}>
      <EUSFormFieldsWrapper>
        {bottomUpSearch.error ? (
          <ErrorAlert error={bottomUpSearch.error} />
        ) : (
          <DataGridPremium
            columns={columns}
            rows={rows}
            loading={bottomUpSearch.isFetching}
            density="compact"
            hideFooter
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(rowSelectionModel) => {
              if (!bottomUpSearch.isFetching) {
                handleSelectionModelChange(rowSelectionModel);
              }
            }}
            isRowSelectable={(params) => !isRowSelectable || isRowSelectable?.(params)}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnPinning
            defaultGroupingExpansionDepth={-1}
            groupingColDef={{
              headerName: '',
              width: groupColWidth ?? 200,
              hideDescendantCount: true,
            }}
            initialState={initialState}
            disableColumnMenu
            {...dataGridProps}
          />
        )}
      </EUSFormFieldsWrapper>
      {formState.errors?.pnList && (
        <Typography variant="caption" color="error" sx={{ display: 'block', marginBottom: 1 }}>
          Nessun elemento selezionato per:
          <Typography component="ul" variant="caption" marginTop={0}>
            {Object.entries(formState.errors.pnList as unknown as IssueData[]).map(([key, error]) => (
              <li key={key}>{error.message}</li>
            ))}
          </Typography>
        </Typography>
      )}
      <EUSFormButtonsWrapper
        onSave={() => {
          onSubmit(handleSave);
          reset(data);
        }}
        isDirty={selectionHasChanged}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
        <Button type="submit" variant="outlined" disabled={bottomUpSearch.isFetching} color="primary">
          Avanti
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog
        onSaveAndExit={() => onSubmit(handleSave)}
        validationError={formState.isSubmitted && Boolean(formState.errors)}
      />
    </form>
  );
}
