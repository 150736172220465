import { z } from 'zod';

export const TopDownSearchParamsSchema = z
  .object({
    pn: z.string().array(),
    customer: z.string().array(),
    enduser: z.string().array(),
    program: z.string().array(),
  })
  .refine((data) => data.pn.length || data.customer.length || data.enduser.length || data.program.length, {
    message: 'Popolare almeno un filtro',
    path: ['atLeastOne'],
  });

export type TopDownSearchParams = z.infer<typeof TopDownSearchParamsSchema>;

export const TopDownHierarchyRecordSchema = z.object({
  item: z.string(),
  level: z.number(),
});

export type TopDownHierarchyRecord = z.infer<typeof TopDownHierarchyRecordSchema>;

export const TopDownDataRecordSchema = TopDownHierarchyRecordSchema.extend({
  itemDescription: z.string().optional(),
  isMake: z.boolean().optional(),
  enditem: z.string(),
  vendorCode: z.string().optional(),
  vendorName: z.string().optional(),
  vendorSource: z.string().optional(),
  vendorCountry: z.string().optional(),
  vendorDa: z.string().optional(),
  vendorLead: z.string().optional(),
  pgrCode: z.string().optional(),
  pgrBuyer: z.string().optional(),
  foreignClassificationSupplierPN: z.string().optional(),
  foreignClassificationManufacturer: z.string().optional(),
  foreignClassificationManufacturerCountry: z.string().optional(),
  foreignClassificationECCN: z.string().optional(),
  foreignClassificationECCNIsMilitary: z.boolean().optional(),
  foreignClassificationECCNLegislation: z.string().optional(),
  foreignClassificationECCNCountry: z.string().optional(),
  foreignClassificationHTSN: z.string().optional(),
});

export type TopDownDataRecord = z.infer<typeof TopDownDataRecordSchema>;

export const TopDownSearchResultSchema = z.object({
  data: TopDownDataRecordSchema.array(),
  hierarchy: TopDownHierarchyRecordSchema.array(),
});

export type TopDownSearchResult = z.infer<typeof TopDownSearchResultSchema>;
