import { useMemo } from 'react';
import { DataGrid, DataGridWrapper, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { CountryFlag } from '@top-solution/microtecnica-utils';
import { GridColDef, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { isMilitaryColumn, makeBuyColumn, SearchToolbar } from '../../../components/DataGrid';
import {
  TopDownDataRecord,
  TopDownHierarchyRecord,
  TopDownSearchParams,
  TopDownSearchResult,
} from '../../../entities/TopDownSearch';

const hierarchySeparator = '>';
const hierarchyDataSepartor = ':';
const dataComponentSepartor = '~';

const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
  headerName: 'P/N',
  valueGetter: (value, row) => row.item,
  width: 300,
};

interface TopDownSearchDataGridProps {
  searchResult: TopDownSearchResult;
  searchParams: TopDownSearchParams;
  onBack: () => void;
}

export function TopDownSearchDataGrid(props: TopDownSearchDataGridProps): JSX.Element {
  const { searchResult, onBack } = props;
  const countryColumn = useCountryGridColDef({ format: 'png' });

  const gridDataModel = useMemo(() => {
    const pnList = new Set<string>();
    const vendorCodeList = new Set<string>();
    const vendorNameList = new Set<string>();
    const vendorCountryList = new Set<string>();
    const vendorSourceList = new Set<string>();
    const pgrList = new Set<string>();
    const pgrBuyerList = new Set<string>();
    const foreignClassificationManufacturerCountryList = new Set<string>();
    const foreignClassificationECCNLegislationList = new Set<string>();
    const foreignClassificationECNNList = new Set<string>();
    const foreignClassificationHTSNList = new Set<string>();

    const itemDataByPn = searchResult.data.reduce((map, record) => {
      const recordList = map.get(record.item) ?? [];
      recordList.push(record);
      map.set(record.item, recordList);
      return map;
    }, new Map<string, TopDownDataRecord[]>());

    const rows = new Array<(TopDownDataRecord | TopDownHierarchyRecord) & { id: string }>();

    let previousHierarchyNode: TopDownHierarchyRecord & { id: string };
    searchResult.hierarchy.forEach((result) => {
      pnList.add(result.item);
      let id = result.item;
      if (previousHierarchyNode) {
        const path = previousHierarchyNode.id.split(hierarchySeparator);
        if (previousHierarchyNode.level > result.level) {
          const delta = previousHierarchyNode.level - result.level + 1;
          path.splice(path.length - delta, delta);
        } else if (previousHierarchyNode.level === result.level) {
          path.splice(path.length - 1, 1);
        }
        path.push(id);
        id = path.join(hierarchySeparator);
      }
      const dataRecordList = itemDataByPn.get(result.item);
      if (dataRecordList) {
        dataRecordList.forEach((record) => {
          if (record.vendorCode) {
            vendorCodeList.add(record.vendorCode);
          }
          if (record.vendorName) {
            vendorNameList.add(record.vendorName);
          }
          if (record.vendorCountry) {
            vendorCountryList.add(record.vendorCountry);
          }
          if (record.vendorSource) {
            vendorSourceList.add(record.vendorSource);
          }
          if (record.pgrCode) {
            pgrList.add(record.pgrCode);
          }
          if (record.pgrBuyer) {
            pgrBuyerList.add(record.pgrBuyer);
          }
          if (record.foreignClassificationManufacturerCountry) {
            foreignClassificationManufacturerCountryList.add(record.foreignClassificationManufacturerCountry);
          }
          if (record.foreignClassificationECCNLegislation) {
            foreignClassificationECCNLegislationList.add(record.foreignClassificationECCNLegislation);
          }
          if (record.foreignClassificationECCN) {
            foreignClassificationECNNList.add(record.foreignClassificationECCN);
          }
          if (record.foreignClassificationHTSN) {
            foreignClassificationHTSNList.add(record.foreignClassificationHTSN);
          }
          const idComponents = [record.vendorCode, record.pgrCode, record.foreignClassificationECCN].filter(
            (value) => value !== undefined,
          );
          rows.push({
            id:
              id +
              (idComponents.length > 0 ? `${hierarchyDataSepartor}${idComponents.join(dataComponentSepartor)}` : ''),
            ...record,
            ...result,
          });
        });
      } else {
        rows.push({
          id,
          ...result,
        });
      }
      previousHierarchyNode = { id, ...result };
    });

    const countryOptions = countryColumn.valueOptions as { value: string; label: string }[] | undefined;

    return {
      rows,
      pnList: [...pnList].sort(),
      vendorCodeList: [...vendorCodeList].sort(),
      vendorNameList: [...vendorNameList].sort(),
      vendorCountryList: countryOptions?.filter(({ value }) => vendorCountryList.has(value)) ?? [],
      vendorSourceList: [...vendorSourceList].sort(),
      pgrList: [...pgrList].sort(),
      pgrBuyerList: [...pgrBuyerList].sort(),
      foreignClassificationManufacturerCountryList:
        countryOptions?.filter(({ value }) => foreignClassificationManufacturerCountryList.has(value)) ?? [],
      foreignClassificationECCNLegislationList: [...foreignClassificationECCNLegislationList].sort(),
      foreignClassificationECNNList: [...foreignClassificationECNNList].sort(),
      foreignClassificationHTSNList: [...foreignClassificationHTSNList].sort(),
    };
  }, [countryColumn.valueOptions, searchResult]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'level',
        headerName: 'Livello',
        width: 70,
        type: 'number',
        sortable: false,
      },
      {
        field: 'itemDescription',
        headerName: 'Description',
        width: 250,
        sortable: false,
      },
      {
        ...makeBuyColumn,
      },
      {
        field: 'vendorCode',
        headerName: 'Vendor code',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorCodeList,
        width: 120,
        sortable: false,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorNameList,
        width: 240,
        sortable: false,
      },
      {
        field: 'vendorSource',
        headerName: 'Vendor source',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorSourceList,
        width: 140,
        sortable: false,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: 'Vendor country',
        valueOptions: gridDataModel.vendorCountryList,
        width: 150,
      },
      {
        field: 'pgrCode',
        headerName: 'Purchasing group',
        type: 'singleSelect',
        valueOptions: gridDataModel.pgrList,
        width: 150,
        sortable: false,
      },
      {
        field: 'pgrBuyer',
        headerName: 'Buyer',
        type: 'singleSelect',
        valueOptions: gridDataModel.pgrBuyerList,
        width: 150,
        sortable: false,
      },
      {
        ...countryColumn,
        field: 'foreignClassificationManufacturerCountry',
        headerName: 'CoO (Country of Origin)',
        valueOptions: gridDataModel.foreignClassificationManufacturerCountryList,
        width: 200,
        sortable: false,
      },
      { field: 'foreignClassificationManufacturer', headerName: 'Manufacturer', width: 150 },
      {
        ...isMilitaryColumn,
        field: 'foreignClassificationECCNIsMilitary',
        headerName: 'Foreign classification',
        width: 200,
        sortable: false,
      },
      {
        field: 'foreignClassificationECCNLegislation',
        headerName: 'Legislation (Foreign classification)',
        renderCell: ({ value, row }) => (
          <>
            {row.foreignClassificationECCNCountry && (
              <CountryFlag countryCode={row.foreignClassificationECCNCountry} format="png" />
            )}{' '}
            {value}
          </>
        ),
        type: 'singleSelect',
        valueOptions: gridDataModel.foreignClassificationECCNLegislationList,
        width: 200,
        sortable: false,
      },
      {
        field: 'foreignClassificationECCN',
        headerName: 'ECCN (Foreign classification)',
        type: 'singleSelect',
        valueOptions: gridDataModel.foreignClassificationECNNList,
        width: 200,
        sortable: false,
      },
      {
        field: 'foreignClassificationHTSN',
        headerName: 'HTSN (Foreign classification)',
        type: 'singleSelect',
        valueOptions: gridDataModel.foreignClassificationHTSNList,
        width: 200,
        sortable: false,
      },
    ],
    [countryColumn, gridDataModel],
  );

  return (
    <DataGridWrapper>
      <DataGrid
        treeData
        getTreeDataPath={(row) => row.id.split(hierarchyDataSepartor)[0].split(hierarchySeparator)}
        groupingColDef={groupingColDef}
        rows={gridDataModel.rows}
        columns={columns}
        sessionStorageId="TopDownSearchDataGrid"
        slots={{
          toolbar: SearchToolbar,
        }}
        slotProps={{
          toolbar: { onBack },
        }}
      />
    </DataGridWrapper>
  );
}
