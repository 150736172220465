import { useMemo } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import { UserRole } from '../../../entities/User';

export function useCanEditVendor(): boolean {
  const { isAdmin, hasRole } = useAuth();

  const canEditVendor = useMemo(
    () => isAdmin || hasRole(UserRole.FOREIGN_CLASSIFICATION_MANAGER) || hasRole(UserRole.VENDOR_MANAGER),
    [hasRole, isAdmin],
  );

  return canEditVendor;
}
