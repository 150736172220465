import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadThirdPartyTypeListQuery } from '../../services/thirdPartyTypeApi';

export type ThirdPartyTypeSelectProps = Omit<TextFieldProps, 'select'> & {
  disableClearable?: boolean;
};

function ThirdPartyTypeSelectComponent(props: ThirdPartyTypeSelectProps, ref: React.Ref<unknown>) {
  const { value, disabled, error, helperText, label, disableClearable, ...textFieldProps } = props;
  const thirdPartyTypeList = useReadThirdPartyTypeListQuery();

  const apiError = thirdPartyTypeList.error as ServerError | undefined;

  const list = useMemo(() => {
    const list = new Array<JSX.Element>();
    if (!disableClearable || !thirdPartyTypeList.data?.length) {
      list.push(
        <MenuItem value="" key="EMPTY">
          &nbsp;
        </MenuItem>,
      );
    }
    if (thirdPartyTypeList.data?.length) {
      return list.concat(
        thirdPartyTypeList.data.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        )),
      );
    }
    return list;
  }, [thirdPartyTypeList.data, disableClearable]);

  return (
    <TextField
      select
      SelectProps={{ ref }}
      value={value && thirdPartyTypeList.data?.length ? value : ''}
      disabled={
        disabled ||
        thirdPartyTypeList?.data?.length === 0 ||
        thirdPartyTypeList.isError ||
        thirdPartyTypeList.isFetching
      }
      error={error || Boolean(apiError)}
      label={label ?? 'ThirdParty'}
      helperText={helperText || apiError?.data?.message || apiError?.message}
      {...textFieldProps}
    >
      {list}
    </TextField>
  );
}

export const ThirdPartyTypeSelect = forwardRef(ThirdPartyTypeSelectComponent);
