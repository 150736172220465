import { z } from 'zod';
import { ECCNSchema } from './ECCN';

export const ItalianClassificationSchema = z.object({
  pn: z.string(),
  eccn: ECCNSchema,
  htsn: z.string().optional(),
});

export type ItalianClassification = z.infer<typeof ItalianClassificationSchema>;
