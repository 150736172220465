import { useCallback, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridInputRowSelectionModel } from '@mui/x-data-grid-premium';
import { SelectItemListDialogProps } from './SelectItemListDialogProps';

type SelectAvailablePNListDialogProps = SelectItemListDialogProps<string> & {
  availablePnList: string[];
};

export function SelectAvailablePNListDialog(props: SelectAvailablePNListDialogProps): JSX.Element {
  const { onClose, availablePnList, TransitionProps, ...dialogProps } = props;

  const [selectionModel, setSelectionModel] = useState<GridInputRowSelectionModel>([]);

  const [showError, setShowError] = useState(false);

  const columns = useMemo(() => [{ field: 'id', headerName: 'P/N', width: 250 }], []);

  const onSubmit = useCallback(() => {
    if (Array.isArray(selectionModel) && selectionModel.length > 0) {
      onClose(selectionModel.map((value) => value.toString()));
    } else {
      setShowError(true);
    }
  }, [onClose, selectionModel]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          setSelectionModel([]);
          setShowError(false);
          TransitionProps?.onExit?.(node);
        },
      }}
    >
      <DialogTitle>Seleziona P/N</DialogTitle>
      <DialogContent sx={{ height: 'calc(100vh - 200px)', padding: 0 }}>
        <DataGridPremium
          rows={
            availablePnList.map((data) => {
              return { id: data };
            }) ?? []
          }
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={setSelectionModel}
          rowSelectionModel={selectionModel}
          density="compact"
          hideFooter
        />
      </DialogContent>
      <DialogActions>
        {showError && (selectionModel as string[]).length === 0 && (
          <Typography variant="caption" color="error" sx={{ flexGrow: 1 }}>
            Seleziona almeno un elemento
          </Typography>
        )}
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Annulla
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
