import { useMemo } from 'react';
import { CustomTableFooter } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useUnitOfMeasureColDef } from '../../components/DataGrid';
import { EUSDetailItem } from '../../components/EUS/EUSListDetails';
import { EUS, EUSFieldDescriptionlMap, EUSPO } from '../../entities/EUS';
import { NOT_AVAILABLE } from '../../utils/utils';

interface EUSPODetailsDataGridProps {
  eus: EUS;
}

type eusPORowsType = EUSPO & {
  pn: string;
};

export function EUSPODetailsDataGrid(props: EUSPODetailsDataGridProps): JSX.Element {
  const { eus } = props;

  const unitOfMeasureColumn = useUnitOfMeasureColDef();

  const rows = useMemo(() => {
    if (eus && eus.pnList) {
      const rows: eusPORowsType[] = [];
      eus.pnList.forEach((item) => {
        item.poList?.forEach((po) => {
          rows.push({ ...po, pn: item.pn });
        });
      });
      return rows;
    }
    return [];
  }, [eus]);

  const columns = useMemo<GridColDef<eusPORowsType>[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 120 },
      { field: 'po', headerName: 'P.O.', width: 120 },
      {
        field: 'qty',
        headerName: 'Quantità',
        width: 150,
        valueFormatter: (value: number) => value?.toLocaleString() ?? NOT_AVAILABLE,
      },
      { ...unitOfMeasureColumn, valueFormatter: (value) => value ?? NOT_AVAILABLE },
      {
        field: 'notes',
        headerName: EUSFieldDescriptionlMap.poNotes,
        flex: 1,
        minWidth: 300,
        filterable: false,
        sortable: false,
        valueFormatter: (value) => value || NOT_AVAILABLE,
      },
    ],
    [unitOfMeasureColumn],
  );

  return (
    <Box>
      {rows.length > 0 ? (
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
            {EUSFieldDescriptionlMap.poList}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 300,
            }}
          >
            <DataGridPremium
              rows={rows}
              columns={columns}
              getRowId={(row) => row.pn + row.po}
              density="compact"
              slots={{
                footer: CustomTableFooter,
              }}
              disableColumnSelector
              disableColumnFilter
              disableDensitySelector
              disableAggregation
              disableRowGrouping
            />
          </Box>
        </Box>
      ) : (
        <EUSDetailItem label={EUSFieldDescriptionlMap.poList} value={'⚠️ Non specificati'} />
      )}
    </Box>
  );
}
