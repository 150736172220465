import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FilterOperator } from '@top-solution/microtecnica-utils';
import { z } from 'zod';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ECCN } from '../../../entities/ECCN';
import { EUSEditModel, EUSEditSchema } from '../../../entities/EUS';
import { useECCNMap } from '../../../hooks/useECCNMap';
import { useReadForeignClassificationListQuery } from '../../../services/foreignClassificationApi';
import { ECCNAutocomplete } from '../../Form';
import { DeleteIcon, DuplicateIcon } from '../../Icons';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

export const EUSEditECCNSchema = EUSEditSchema.pick({
  pnList: true,
});

type EUSEditECCN = z.infer<typeof EUSEditECCNSchema>;

export function EUSEditECCNForm(): JSX.Element {
  const { data, handleNext, handleSave, handleBack } = useContext(EUSFormContext);

  const readEccn = useECCNMap();

  const readForeignClassificationListParams = useMemo(
    () => ({
      offset: 0,
      limit: 10 ** 6,
      filters: [{ field: 'vendorCode', operator: FilterOperator.equals, value: data.vendor.id }],
    }),
    [data.vendor.id],
  );
  const readForeignClassificationList = useReadForeignClassificationListQuery(readForeignClassificationListParams);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<EUSEditECCN>({
    defaultValues: data,
    resolver: zodResolver(EUSEditECCNSchema),
  });

  useEffect(() => {
    if (readForeignClassificationList.data) {
      const map = new Map<string, ECCN['id']>();
      readForeignClassificationList.data.data.reduce((map, item) => {
        map.set(item.pn, item.eccn.id);
        return map;
      }, map);

      const pnList = getValues('pnList');
      pnList?.forEach((value, index) => {
        if (value.eccn === null && map.has(value.pn)) {
          setValue(`pnList.${index}.eccn`, map.get(value.pn) ?? null);
        }
      });
    }
  }, [getValues, readForeignClassificationList.data, setValue]);

  const handleDuplicateClick = useCallback(
    (eccn: string) => {
      const pnList = getValues('pnList');
      pnList?.forEach((value, index) => {
        if (value.eccn === null) {
          setValue(`pnList.${index}.eccn`, eccn);
        }
      });
    },
    [getValues, setValue],
  );

  const onSubmit = useMemo(
    () => (formData: EUSEditECCN, callback: (editModel: EUSEditModel) => void) => {
      callback({ ...data, ...formData });
    },
    [data],
  );

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, handleNext))}>
      <EUSFormFieldsWrapper>
        <Stack gap={2} marginY={2}>
          {data.pnList?.map((item, index) => (
            <Controller
              control={control}
              key={item.pn}
              name={`pnList.${index}.eccn`}
              render={({ field: { value, onChange, ...field } }) => {
                const selectedEccn = value && readEccn.map?.get(value);
                return (
                  <Stack direction="row" gap={2} alignItems="center">
                    <Typography variant="subtitle1" sx={{ minWidth: '20ch', textAlign: 'right' }}>
                      P/N {item.pn}
                    </Typography>
                    <ECCNAutocomplete
                      label="ECCN"
                      size="small"
                      sx={{ minWidth: '20ch' }}
                      value={value ?? ''}
                      onChange={(_, value) => onChange(value)}
                      disabled={readEccn.isFetching || readForeignClassificationList.isFetching}
                      {...field}
                    />

                    {selectedEccn && (
                      <>
                        <TextField
                          value={selectedEccn.isMilitary ? 'Militare' : 'Civile'}
                          label="Classificazione"
                          size="small"
                          disabled
                        />
                        <TextField value={selectedEccn.legislation} label="Legislazione" size="small" disabled />
                        <TextField value={selectedEccn.country} label="Country" size="small" disabled />

                        <div>
                          <Tooltip title={`Elimina classificazione P/N ${item.pn}`}>
                            <IconButton onClick={() => onChange(null)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Applica a tutte le righe vuote">
                            <IconButton onClick={() => handleDuplicateClick(value)}>
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </Stack>
                );
              }}
            />
          ))}
        </Stack>
      </EUSFormFieldsWrapper>
      <EUSFormButtonsWrapper
        isDirty={isDirty}
        onSave={handleSubmit((data) => {
          onSubmit(data, handleSave);
          reset(data);
        })}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
        <Button type="submit" variant="outlined" color="primary" disabled={readEccn.isFetching}>
          Avanti
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog onSaveAndExit={handleSubmit((data) => onSubmit(data, handleSave))} />
    </form>
  );
}
