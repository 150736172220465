import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { EndUser } from '../../entities/EndUser';
import { useRemoveEndUserMutation } from '../../services/endUserApi';

type EndUserDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: EndUser;
  onClose: (value?: EndUser) => void;
};

export function EndUserDeleteConfirm(props: EndUserDeleteConfirmProps): JSX.Element {
  const { data, onClose, TransitionProps, ...confirmProps } = props;
  const [removeEndUser, removeEndUserStatus] = useRemoveEndUserMutation();

  const handleConfirm = useCallback(async () => {
    await removeEndUser(data).unwrap();
    onClose(data);
  }, [removeEndUser, data, onClose]);

  return (
    <DeleteConfirmDialog
      title={`Vuoi davvero eliminare l'end-user "${data.name}"?`}
      confirmText="confermo"
      inProgress={removeEndUserStatus.isLoading}
      error={removeEndUserStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeEndUserStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
