import { ReactNode } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { DefinitionList } from '../DefinitionList';
import { useReadProgramPMEmailList } from './useReadProgramPMEmailList';

interface EmailNotificationListProps {
  programId: string;
  children?: ReactNode | ReactNode[];
}

export function EmailNotificationList(props: EmailNotificationListProps): JSX.Element {
  const { programId, children } = props;

  const readEmailNotificationList = useReadProgramPMEmailList(programId);

  return readEmailNotificationList.isFetching ? (
    <CircularProgress />
  ) : readEmailNotificationList.error ? (
    <ErrorAlert error={readEmailNotificationList.error} />
  ) : (
    readEmailNotificationList.data &&
    (readEmailNotificationList.data.length === 0 ? (
      <Alert severity="warning">Non ci sono PM legati a questo programma</Alert>
    ) : (
      <>
        <DefinitionList
          data={[
            {
              term: 'PM OE',
              definition:
                readEmailNotificationList.oe.length > 0
                  ? readEmailNotificationList.oe.map((email) => <div key={email}>{email}</div>)
                  : '–',
            },
            {
              term: 'PM MRO',
              definition:
                readEmailNotificationList.mro.length > 0
                  ? readEmailNotificationList.mro.map((email) => <div key={email}>{email}</div>)
                  : '–',
            },
          ]}
        />
        {children}
      </>
    ))
  );
}
