import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { SerniCategory, SerniCategorySchema } from '../entities/SerniCategory';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/serni-codes';

const serniCategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSerniCategoryList: builder.query<PagedResponse<SerniCategory>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(SerniCategorySchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.SERNI_CATEGORY, id: 'LIST' }],
    }),
    createSerniCategory: builder.mutation<void, SerniCategory>({
      query: (serniCategory) => ({
        url,
        method: 'POST',
        body: serniCategory,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_CATEGORY, id: 'LIST' }],
    }),
    updateSerniCategory: builder.mutation<void, SerniCategory>({
      query: (serniCategory) => ({
        url: `${url}/item/${encodeURIComponent(serniCategory.pn)}/program/${serniCategory.programId}`,
        method: 'PUT',
        body: serniCategory,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_CATEGORY, id: 'LIST' }],
    }),
    removeSerniCategory: builder.mutation<void, SerniCategory>({
      query: (serniCategory) => ({
        url: `${url}/item/${encodeURIComponent(serniCategory.pn)}/program/${serniCategory.programId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SERNI_CATEGORY, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadSerniCategoryListQuery,
  useRemoveSerniCategoryMutation,
  useUpdateSerniCategoryMutation,
  useCreateSerniCategoryMutation,
} = serniCategoryApi;
