import { useCallback, useMemo, useState } from 'react';
import { DataGrid, ErrorAlert, muiFiltersToPagedRequestFilters, usePagination } from '@top-solution/microtecnica-mui';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColDef, GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-premium';
import { ThirdParty } from '../../entities/ThirdParty';
import { useReadThirdPartyListQuery } from '../../services/thirdPartyApi';
import { useThirdPartyTypeColDef } from '../DataGrid/useThirdPartyTypeColDef';

type ThirdPartyWarningDialogProps = DialogProps;

export function ThirdPartyWarningDialog(props: ThirdPartyWarningDialogProps): JSX.Element {
  const { onClose, ...dialogProps } = props;
  const thirdPartyTypeColumn = useThirdPartyTypeColDef();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const searchParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readThirdPartyList = useReadThirdPartyListQuery(searchParams);

  const columns = useMemo<GridColDef<ThirdParty>[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 30, filterable: false, sortable: false },
      { field: 'name', headerName: 'Nome', width: 250 },
      {
        ...thirdPartyTypeColumn,
        field: 'typeName',
        width: 200,
        valueGetter: (value, row) => row.type.id,
      },
      { field: 'address', headerName: 'Indirizzo', width: 450 },
      { field: 'notes', headerName: 'Note', width: 400 },
    ],
    [thirdPartyTypeColumn],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  if (readThirdPartyList.error) {
    return <ErrorAlert error={readThirdPartyList.error} />;
  }

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps} onClose={onClose}>
      <DialogTitle>Attenzione: verificare lista Terze Parti</DialogTitle>
      <DialogContent sx={{ height: 'calc(100vh - 300px)', padding: 0 }}>
        <DataGrid
          rows={readThirdPartyList.data?.data ?? []}
          columns={columns}
          loading={readThirdPartyList.isFetching}
          pagination
          paginationMode="server"
          rowCount={readThirdPartyList.data?.total ?? 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          filterModel={filterModel}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose ? (e) => onClose(e, 'escapeKeyDown') : undefined}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
}
