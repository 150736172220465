import { DataGrid } from '@top-solution/microtecnica-mui';
import Typography from '@mui/material/Typography';
import { SoldPn } from '../../entities/SoldPn';
import { userColumn } from '../DataGrid/userColumn';

interface SoldPnApprovalsListProps {
  approvals: SoldPn['approvals'];
}

export function SoldPnApprovalsList(props: SoldPnApprovalsListProps): JSX.Element {
  const { approvals } = props;

  return (
    <>
      <Typography variant="subtitle2">Approvazioni:</Typography>
      <DataGrid
        rows={approvals ?? []}
        getRowId={(row) => row.username}
        columns={[
          { ...userColumn, flex: 1 },
          {
            field: 'timestamp',
            headerName: 'Data',
            flex: 1,
            valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
          },
        ]}
        disableColumnMenu
        disableColumnReorder
        hideFooter
        autoHeight
      />
    </>
  );
}
