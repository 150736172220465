import { z } from 'zod';
import { Customer, CustomerSchema } from './Customer';

export const SoldPnSchema = z.object({
  pn: z.string(),
  isMake: z.boolean().optional(),
  procType: z.string().optional(),
  programId: z.string(),
  customer: CustomerSchema.nullable(),
  shipTo: CustomerSchema.nullable(),
  fromPir: z.boolean(),
  fromSos: z.boolean(),
  fromMro: z.boolean(),
  approvals: z
    .object({
      username: z.string().optional(),
      timestamp: z.string().datetime({ offset: true }).optional(),
    })
    .array()
    .optional(),
});

export type SoldPn = z.infer<typeof SoldPnSchema>;

export type ManualSoldPn = Omit<SoldPn, 'customer' | 'shipTo'> & {
  customer: Customer;
  shipTo: Customer;
};

export enum SoldPnSource {
  PIR = 'PIR',
  SOS = 'SOS',
  MRO = 'MRO',
  Manual = 'Manual',
}

export const soldPnSourceList = [SoldPnSource.SOS, SoldPnSource.MRO, SoldPnSource.PIR, SoldPnSource.Manual];

export function getSource(item: SoldPn): SoldPnSource {
  if (item.fromPir) {
    return SoldPnSource.PIR;
  }
  if (item.fromSos) {
    return SoldPnSource.SOS;
  }
  if (item.fromMro) {
    return SoldPnSource.MRO;
  }
  return SoldPnSource.Manual;
}

export function isManual(item: SoldPn): boolean {
  return !item.fromPir && !item.fromSos && !item.fromMro;
}

export interface SoldPnIdentifier {
  pn: SoldPn['pn'];
  programId: SoldPn['programId'];
  customerId: Customer['id'] | null;
  shipToId: Customer['id'] | null;
}
