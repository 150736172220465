import { useCallback, forwardRef, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { FilterOperator, ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Vendor } from '../../entities/Vendor';
import { useReadSupplierListQuery } from '../../services/suppliersApi';

export type ItemVendorSelectProps = Omit<TextFieldProps, 'select'> & {
  pn: string | null;
  value: Vendor | null;
  onChange: (value: Vendor | null, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function ItemVendorSelect(props: ItemVendorSelectProps, ref: React.Ref<unknown>) {
  const { pn, onChange, value, disabled, error, helperText, ...textFieldProps } = props;

  const readItemVendorListParams = useMemo(
    () =>
      pn
        ? {
            sort: ['vendorName'],
            filters: [{ field: 'pn', value: pn, operator: FilterOperator.equals }],
            offset: 0,
            limit: 10,
          }
        : skipToken,
    [pn],
  );

  const itemVendorList = useReadSupplierListQuery(readItemVendorListParams);

  const vendorMap = useMemo(
    () => itemVendorList.data?.data.reduce((map, { vendor }) => map.set(vendor.id, vendor), new Map<string, Vendor>()),
    [itemVendorList.data?.data],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const id = event.target.value;
      const vendor = vendorMap?.get(id) ?? null;
      onChange(vendor, event);
    },
    [onChange, vendorMap],
  );

  const apiError = itemVendorList.error as ServerError | undefined;

  return (
    <>
      <TextField
        select
        SelectProps={{ ref }}
        value={value?.id || ''}
        onChange={handleChange}
        disabled={
          disabled || !pn || !itemVendorList.data?.data.length || itemVendorList.isError || itemVendorList.isFetching
        }
        error={error || Boolean(apiError) || itemVendorList.data?.data.length === 0}
        helperText={
          helperText ||
          apiError?.data.message ||
          (itemVendorList.data?.data.length === 0 && `Nessun vendor trovato per il P/N ${pn}`) ||
          ' '
        }
        {...textFieldProps}
      >
        {vendorMap ? (
          [...vendorMap.entries()].map(([id, { name }]) => (
            <MenuItem key={id} value={id}>
              {id} - {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            {' '}
          </MenuItem>
        )}
      </TextField>
    </>
  );
}

export default forwardRef(ItemVendorSelect);
