/**
 * It takes a string with multiple lines, and returns an array of strings, where each string is a line
 * from the input string, and where each line is trimmed, and where duplicate lines are removed
 * @param {string} value - the value of the input
 * @returns an array of strings, where each string is a line from the input string
 */
export function parseMultilineInputValue(value: string): string[] {
  const list = new Array<string>();
  if (value) {
    const set = new Set<string>();
    value.split('\n').forEach((item, index, array) => {
      const trimmedValue = item.trim();
      if (trimmedValue && !set.has(trimmedValue)) {
        // If this is a new value, push it in the list
        list.push(trimmedValue);
      } else if (index === array.length - 1 && (!trimmedValue || set.has(trimmedValue))) {
        // Else "empty" the last row
        list.push('');
      }
      if (trimmedValue) {
        set.add(trimmedValue);
      }
    });
  }
  return list;
}
