import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { EUS } from '../../entities/EUS';
import { useRemoveEUSMutation } from '../../services/eusApi';

type EUSDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: EUS;
  onClose: (value?: EUS) => void;
  onConfirm?: () => void;
};

export function EUSDeleteConfirm(props: EUSDeleteConfirmProps): JSX.Element {
  const { data, onClose, onConfirm, TransitionProps, ...confirmProps } = props;
  const [removeEUS, removeEUSStatus] = useRemoveEUSMutation();

  const handleConfirm = useCallback(async () => {
    await removeEUS(data).unwrap();
    onClose(data);
    if (onConfirm) {
      onConfirm();
    }
  }, [removeEUS, data, onClose, onConfirm]);

  return (
    <DeleteConfirmDialog
      title={`Vuoi davvero eliminare la pratica "${data.protocolNum}"?`}
      confirmText="confermo"
      inProgress={removeEUSStatus.isLoading}
      error={removeEUSStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeEUSStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
