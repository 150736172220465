import { PlantSchema, ProgramSchema } from '@top-solution/microtecnica-utils';
import { add, differenceInCalendarDays } from 'date-fns';
import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { CustomerSchema } from './Customer';
import { EndUserSchema } from './EndUser';
import { EUSStatusSchema, SupplierLicenseTypeSchema, TurnbackTypeSchema } from './EUSAttributes';
import { PurchaseGroupSchema } from './PurchaseGroup';
import { ThirdPartySchema } from './ThirdParty';
import { VendorSchema } from './Vendor';

export const maxAllowedTat = 10;

// at the moment is not used
export const EUSTurnbackSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type EUSTurnback = z.infer<typeof EUSTurnbackSchema>;

export const EUSCompletePOSchema = z.object({
  po: z.string(),
  qty: z.number().optional(),
  unitOfMeasureId: z.number().optional(),
  notes: z.string().optional().optional(),
});

export type EUSCompletePO = z.infer<typeof EUSCompletePOSchema>;

export const EUSPOSchema = EUSCompletePOSchema.extend({
  qty: EUSCompletePOSchema.shape.qty.optional(),
  unitOfMeasureId: EUSCompletePOSchema.shape.unitOfMeasureId.optional(),
});

export type EUSPO = z.infer<typeof EUSPOSchema>;

export const EUSEditPOSchema = EUSCompletePOSchema.extend({
  qty: EUSCompletePOSchema.shape.qty.nullable(),
  unitOfMeasureId: EUSCompletePOSchema.shape.unitOfMeasureId.nullable(),
  notes: EUSCompletePOSchema.shape.notes.unwrap().nullable(),
});

export type EUSEditPO = z.infer<typeof EUSEditPOSchema>;

export const EUSCompleteCustomerItem = z.object({
  enditem: z.string(),
  programId: ProgramSchema.shape.id,
  customer: CustomerSchema,
  shipTo: CustomerSchema.optional(),
});

export type EUSCompleteCustomerItem = z.infer<typeof EUSCompleteCustomerItem>;

export const EUSEditCustomerItemSchema = EUSCompleteCustomerItem.omit({ customer: true, shipTo: true }).extend({
  enditem: EUSCompleteCustomerItem.shape.enditem.nullable(),
  programId: EUSCompleteCustomerItem.shape.programId.nullable(),
  customerId: CustomerSchema.shape.id.nullable(),
  shipToId: CustomerSchema.shape.id.nullable(),
});

export type EUSEditCustomerItem = z.infer<typeof EUSEditCustomerItemSchema>;

export const EUSCustomerItemSchema = EUSCompleteCustomerItem.extend({
  enditem: EUSCompleteCustomerItem.shape.enditem.optional(),
  programId: EUSCompleteCustomerItem.shape.programId.optional(),
  customer: EUSCompleteCustomerItem.shape.customer.optional(),
});

export type EUSCustomerItem = z.infer<typeof EUSCustomerItemSchema>;

export const EUSCompleteEnduserItemSchema = z.object({
  enduser: EndUserSchema,
  enduserType: EndUserSchema.shape.type,
});

export type EUSCompleteEnduserItem = z.infer<typeof EUSCompleteEnduserItemSchema>;

export const EUSEditEnduserItemSchema = EUSCompleteEnduserItemSchema.omit({ enduser: true }).extend({
  enduserId: EndUserSchema.shape.id,
  enduserType: EUSCompleteEnduserItemSchema.shape.enduserType.unwrap().nullable(),
});

export type EUSEditEnduserItem = z.infer<typeof EUSEditEnduserItemSchema>;

export const EUSEnduserItemSchema = EUSCompleteEnduserItemSchema;

export type EUSEnduserItem = z.infer<typeof EUSEnduserItemSchema>;

export const EUSCompleteItemSchema = z.object({
  pn: z.string().min(3),
  eccn: z.string().min(3),
  poList: EUSCompletePOSchema.array(),
  customerList: EUSCompleteCustomerItem.array().min(1),
});

export type EUSCompleteItem = z.infer<typeof EUSCompleteItemSchema>;

export const EUSEditItemSchema = EUSCompleteItemSchema.extend({
  eccn: EUSCompleteItemSchema.shape.eccn.nullable(),
  poList: EUSEditPOSchema.array(),
  customerList: EUSEditCustomerItemSchema.array(),
});

export type EUSEditItem = z.infer<typeof EUSEditItemSchema>;

export const EUSItemSchema = EUSCompleteItemSchema.extend({
  eccn: EUSCompleteItemSchema.shape.eccn.optional(),
  poList: EUSPOSchema.array(),
  customerList: EUSCustomerItemSchema.array(),
});

export type EUSItem = z.infer<typeof EUSItemSchema>;

export const EUSCompleteSchema = z.object({
  id: z.number(),
  protocolNum: z.string().regex(/^SCT\/EU\/[0-9]{4}\/[0-9]{3}(\/\D){0,1}$/),
  eusReleaseDate: z.string().datetime({ offset: true }).optional(),
  eusLinkedLicense: z.string().optional(),
  plantId: PlantSchema.shape.id,
  pgr: PurchaseGroupSchema,
  vendor: VendorSchema,
  vendorRequestDate: z.string().datetime({ offset: true }),
  pnList: EUSCompleteItemSchema.array().min(1),
  enduserList: EUSCompleteEnduserItemSchema.array().min(1),
  thirdPartyList: ThirdPartySchema.array(),
  additionalEUSRestrictions: z.string().optional(),
  attachmentList: AttachmentSchema.array(),
  originalSentToSupplier: z.boolean().optional(),
  statusId: EUSStatusSchema.shape.id,
  supplierLicenseTypeId: SupplierLicenseTypeSchema.shape.id,
  supplierLicenseTypeDuration: z.number().min(-1).optional(),
  tat: z.number().optional(),
  turnbackTypeId: TurnbackTypeSchema.shape.id.optional(),
  turnbackNotes: z.string().optional(),
  createdBy: z.string(),
  createdDate: z.string().datetime({ offset: true }),
  updatedBy: z.string().optional(),
  updatedDate: z.string().datetime({ offset: true }).optional(),
  notes: z.string().optional(),
});

export type EUSComplete = z.infer<typeof EUSCompleteSchema>;

export const EUSSchema = EUSCompleteSchema.extend({
  vendorRequestDate: EUSCompleteSchema.shape.vendorRequestDate.optional(),
  pnList: EUSItemSchema.array(),
  enduserList: EUSEnduserItemSchema.array(),
  originalSentToSupplier: EUSCompleteSchema.shape.originalSentToSupplier.optional(),
  // createdBy: EUSCompleteSchema.shape.createdBy.optional(),
  // createdDate: EUSCompleteSchema.shape.createdDate.optional(),
  // tat: EUSCompleteSchema.shape.tat.optional(),
});

export type EUS = z.infer<typeof EUSSchema>;

export const EUSEditSchema = EUSSchema.omit({ tat: true }).extend({
  id: EUSSchema.shape.id.optional(),
  protocolNum: EUSSchema.shape.protocolNum.optional(),
  eusReleaseDate: z.date().nullable(),
  eusLinkedLicense: EUSSchema.shape.eusLinkedLicense.nullable(),
  vendorRequestDate: z.date().nullable(),
  pnList: EUSEditItemSchema.array(),
  enduserList: EUSEditEnduserItemSchema.array(),
  additionalEUSRestrictions: EUSSchema.shape.additionalEUSRestrictions.nullable(),
  thirdPartyList: ThirdPartySchema.shape.id.array(),
  originalSentToSupplier: EUSSchema.shape.originalSentToSupplier.unwrap().nullable(),
  supplierLicenseTypeDuration: EUSSchema.shape.supplierLicenseTypeDuration.unwrap(),
  turnbackTypeId: EUSSchema.shape.turnbackTypeId.unwrap().nullable(),
  turnbackNotes: EUSSchema.shape.turnbackNotes.unwrap().nullable(),
  createdBy: EUSSchema.shape.createdBy.optional(),
  createdDate: EUSSchema.shape.createdDate.optional(),
  notes: EUSSchema.shape.notes.unwrap().nullable(),
});

export type EUSEditModel = z.infer<typeof EUSEditSchema>;

export function convertEUSToEUSEditModel(eus: EUS): EUSEditModel {
  return {
    id: eus.id,
    protocolNum: eus.protocolNum,
    eusReleaseDate: eus.eusReleaseDate ? new Date(eus.eusReleaseDate) : null,
    eusLinkedLicense: eus.eusLinkedLicense ?? null,
    pgr: eus.pgr,
    vendor: eus.vendor,
    vendorRequestDate: eus.vendorRequestDate ? new Date(eus.vendorRequestDate) : null,
    pnList: eus.pnList.map((item) => ({
      ...item,
      eccn: item.eccn ?? null,
      poList: item.poList.map((item) => ({
        ...item,
        qty: item.qty ?? null,
        unitOfMeasureId: item.unitOfMeasureId ?? null,
        notes: item.notes ?? null,
      })),
      customerList: item.customerList.map((item) => ({
        enditem: item.enditem ?? null,
        programId: item.programId ?? null,
        customerId: item.customer?.id ?? null,
        shipToId: item.shipTo?.id ?? null,
      })),
    })),
    enduserList: eus.enduserList.map((item) => ({
      enduserId: item.enduser.id,
      enduserType: item.enduserType ?? null,
    })),
    thirdPartyList: eus.thirdPartyList?.map(({ id }) => id) ?? [],
    additionalEUSRestrictions: eus.additionalEUSRestrictions,
    attachmentList: eus.attachmentList,
    originalSentToSupplier: eus.originalSentToSupplier ?? null,
    plantId: eus.plantId,
    statusId: eus.statusId,
    supplierLicenseTypeId: eus.supplierLicenseTypeId,
    supplierLicenseTypeDuration: eus.supplierLicenseTypeDuration ?? 0,
    turnbackTypeId: eus.turnbackTypeId ?? null,
    turnbackNotes: eus.turnbackNotes ?? '',
    createdBy: eus.createdBy,
    createdDate: eus.createdDate,
    updatedBy: eus.updatedBy,
    updatedDate: eus.updatedDate,
    notes: eus.notes ?? '',
  };
}

export const EUSFieldLabelMap: Record<string, string> = {
  id: 'No.',
  plantId: 'Plant',
  protocolNum: 'Protocollo Documento',
  vendorRequestDate: 'Data richiesta EUS',
  statusId: 'Stato avanzamento',
  turnbackTypeId: 'Turnback',
  turnbackNotes: 'Note Turnback',
  eusLinkedLicense: 'Licenze collegate',
  supplierLicenseTypeId: 'Licenza fornitore',
  supplierLicenseTypeDuration: 'Validità licenza',
  supplierLicenseTypeValidity: 'Scadenza licenza',
  additionalEUSRestrictions: 'Restrizioni EUS & commenti',
  vendor: 'Fornitore',
  vendorCode: 'Codice fornitore',
  vendorName: 'Nome fornitore',
  vendorCountry: 'Paese fornitore',
  pnList: 'Part Number(s)',
  eccn: 'Classificazione',
  poList: 'PO',
  pgr: 'Buyer',
  customerList: 'Customer',
  enduserList: 'End user',
  attachmentList: 'Allegati',
  originalSentToSupplier: 'Documento EUS spedito in originale',
  eusReleaseDate: 'Data emissione EUS',
  unitOfMeasureId: 'Unità di misura',
  item: 'P/N',
  enditem: 'End item',
  programId: 'Program',
  customer: 'Customer',
  shipTo: 'Ship to',
  po: 'PO',
  qty: 'Quantità',
  uom: 'Unità di misura',
  enduserType: 'Ultimate/Intermediate',
  tat: 'TAT',
  notes: 'Note',
};

export const EUSFieldDescriptionlMap: Record<string, string> = {
  plantId: 'Microtecnica Plant / Sito',
  protocolNum: 'Protocol doc.no / Num protocollo documento',
  statusId: 'EUS doc issue status / Status emissione doc. EUS',
  turnbackTypeId: 'Turnback Category / Categoria Turnback',
  turnbackNotes: 'Turnback Notes / Note Turnback',
  supplierLicenseTypeId: 'Supplier’s License type / Tipologia licenze fornitore',
  supplierLicenseTypeDuration: 'EUS validity days / GG validità EUS',
  supplierLicenseTypeValidity: 'EUS valid till / EUS valido fino a',
  additionalEUSRestrictions:
    'Additional EUS restrictions & comments / restrizioni addizionali applicabili a EUS & commenti',
  eusLinkedLicense: 'License linked to EUU / Licenza relativa a doc. EUS',
  vendorRequestDate: 'Date when suppliers required EUS / Data di richiesta EUS  da parte del fornitore',
  originalSentToSupplier: 'Original of EUS doc sent to supplier / Doc. EUS in originale spedito al fornitore',
  pgr: 'Buyer / PRGroup',
  vendor: 'Vendor Code - Name / Nome - Cod. fornitore',
  vendorCode: 'Vendor Code / Cod. fornitore',
  vendorName: 'Vendor Name / Nome fornitore',
  vendorCountry: 'Vendor Country / Paese fornitore',
  pnList: 'Part Number(s)',
  eccn: 'Item Classification type / Classifica item fornitore (ITAR, SERIE600, DUAL USE, UE MIL, CIVILE)',
  poList: 'P.O. (Purchase Orders)',
  poNotes: 'Dettagli - Valori/Qty Value/Qty - Details',
  customerList: 'Customer',
  enduserList: 'Ultimate/Intermediate End Users / Utilizzatori Finali/Intermedi',
  thirdPartyList: 'Third parties (e.g. Gov.entity, freightforwarder…) / Terze parti (Enti gov., spedizionieri)',
  attachmentList: 'Attachments / Allegati',
  eusReleaseDate: 'EUS doc. issue date / Data emissione doc. EUS',
  unitOfMeasureId: 'Unità di misura',
  enditem: 'End item (where used) PN / Complessivo superiore PN ',
  programId: 'Program / programma',
  customer: 'Customer  Sold To / Cliente ref. commerciale',
  shipTo: 'Customer Ship To  / Cliente  Rif Consegna',
  po: 'P.O. (Purchase Order)',
  qty: 'Quantity / Quantità',
  uom: 'UoM (unit of measure) / Unità di misura',
  enduserType: 'End User Role (Ultimate or Intermediate)  / Ruolo End user (Finale o Intermedio)',
  notes: 'Notes / Note',
};

export function getEUSExpireDate(eus: EUS): Date | undefined {
  if (eus.eusReleaseDate && eus.supplierLicenseTypeDuration && eus.supplierLicenseTypeDuration > 0) {
    return add(new Date(eus.eusReleaseDate), { days: eus.supplierLicenseTypeDuration });
  }
}

export function isEUSExpiring(eus: EUS): boolean {
  const expireDate = getEUSExpireDate(eus);
  if (expireDate) {
    return differenceInCalendarDays(expireDate, new Date()) <= 45;
  }
  return false;
}
