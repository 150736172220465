import { useMemo } from 'react';
import { CustomTableFooter, ErrorAlert, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { isMilitaryColumn, useECCN } from '../../components/DataGrid';
import { EUS, EUSFieldDescriptionlMap, EUSItem } from '../../entities/EUS';
import { EUSDetailItem } from './EUSDetailPage';

interface EUSEccnDetailsDataGridProps {
  eus: EUS;
}

export function EUSEccnDetailsDataGrid(props: EUSEccnDetailsDataGridProps): JSX.Element {
  const { eus } = props;

  const countryColumn = useCountryGridColDef({ hideFlag: true });
  const readECCN = useECCN();

  const columns = useMemo<GridColDef<EUSItem>[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 150 },
      {
        field: 'eccn',
        headerName: 'ECCN',
        width: 250,
        valueGetter: (value, row) => row.eccn ?? '⚠️ Classificazione mancante',
      },
      {
        ...isMilitaryColumn,
        valueGetter: (value, row) => row.eccn && readECCN.map?.[row.eccn]?.isMilitary,
      },
      {
        field: 'legislation',
        headerName: 'Legislazione',
        width: 250,
        valueGetter: (value, row) => row.eccn && readECCN.map?.[row.eccn]?.legislation,
      },
      {
        ...countryColumn,
        valueGetter: (value, row) => row.eccn && readECCN.map?.[row.eccn]?.country,
        width: 250,
      },
    ],
    [countryColumn, readECCN.map],
  );

  if (readECCN.error) {
    return <ErrorAlert error={readECCN.error} />;
  }

  return (
    <Box>
      {eus.pnList ? (
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
            {EUSFieldDescriptionlMap.eccn}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 300,
            }}
          >
            <DataGridPremium
              density="compact"
              getRowId={(row) => row.pn}
              rows={readECCN.map ? eus.pnList : []}
              columns={columns}
              loading={readECCN.isLoading}
              slots={{
                footer: CustomTableFooter,
              }}
              disableColumnSelector
              disableColumnFilter
              disableDensitySelector
              disableAggregation
              disableRowGrouping
            />
          </Box>
        </Box>
      ) : (
        <EUSDetailItem label={EUSFieldDescriptionlMap.eccn} value="⚠️ Non specificata" />
      )}
    </Box>
  );
}
