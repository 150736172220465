import { format, isValid } from 'date-fns';
import { it } from 'date-fns/locale';

export function formatStringDate(value: string | Date): string {
  const date = typeof value === 'string' ? new Date(value) : value;
  if (isValid(date)) {
    return format(date, 'P', { locale: it });
  }
  return value.toString();
}

export const NOT_AVAILABLE = 'N/A';
