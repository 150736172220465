import { useMemo } from 'react';
import { ECCN } from '../entities/ECCN';
import { useReadECCNListQuery } from '../services/eccnApi';

type UseECCNList = ReturnType<typeof useReadECCNListQuery> & {
  map?: Map<string, ECCN>;
};

export function useECCNMap(): UseECCNList {
  const eccnList = useReadECCNListQuery();
  const map = useMemo(
    () => eccnList.data?.reduce((map, item) => map.set(item.id, item), new Map<ECCN['id'], ECCN>()),
    [eccnList.data],
  );

  return {
    map,
    ...eccnList,
  };
}
