import { useMemo } from 'react';
import { CustomTableFooter } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useThirdPartyTypeColDef } from '../../components/DataGrid';
import { EUS, EUSFieldDescriptionlMap } from '../../entities/EUS';
import { ThirdParty } from '../../entities/ThirdParty';

interface EUSThirdPartiesDetailsDataGridProps {
  eus: EUS;
}

export function EUSThirdPartiesDetailsDataGrid(props: EUSThirdPartiesDetailsDataGridProps): JSX.Element {
  const { eus } = props;

  const thirdPartyTypeColumn = useThirdPartyTypeColDef();

  const columns = useMemo<GridColDef<ThirdParty>[]>(
    () => [
      { field: 'name', headerName: 'Nome', width: 250 },
      {
        ...thirdPartyTypeColumn,
        field: 'typeName',
        width: 200,
        valueGetter: (value, row) => row.type.id,
      },
      { field: 'address', headerName: 'Indirizzo', width: 450, filterable: false, sortable: false },
      { field: 'notes', headerName: 'Note', width: 400, filterable: false, sortable: false },
    ],
    [thirdPartyTypeColumn],
  );

  return (
    <Box>
      {eus.pnList && (
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
            {EUSFieldDescriptionlMap.thirdPartyList}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 300,
            }}
          >
            <DataGridPremium
              density="compact"
              rows={eus.thirdPartyList}
              columns={columns}
              slots={{
                footer: CustomTableFooter,
              }}
              disableColumnSelector
              disableColumnFilter
              disableDensitySelector
              disableAggregation
              disableRowGrouping
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
