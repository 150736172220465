import { PurchaseGroup, PurchaseGroupSchema } from '../entities/PurchaseGroup';
import { Vendor, VendorSchema } from '../entities/Vendor';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/purchase-groups';

const purchaseGroupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPurchaseGroupList: builder.query<PurchaseGroup[], void>({
      query: () => url,
      transformResponse: (data) => PurchaseGroupSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PURCHASE_GROUP, id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),
    readPurchaseGroupVendorList: builder.query<Vendor[], PurchaseGroup['id']>({
      query: (id) => `${url}/${id}/vendors`,
      transformResponse: (data) => VendorSchema.array().parse(data),
      providesTags: (response, error, id) => [{ type: TAG_TYPES.PURCHASE_GROUP, id: `VENDORS-${id}` }],
      keepUnusedDataFor: 3600,
    }),
  }),
  overrideExisting: false,
});

export const { useReadPurchaseGroupListQuery, useReadPurchaseGroupVendorListQuery } = purchaseGroupApi;
