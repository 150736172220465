import { useCallback, forwardRef, useMemo } from 'react';
import { FilterOperator, PagedRequestFilter, ServerError } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { PurchaseGroup } from '../../entities/PurchaseGroup';
import { useReadSupplierListQuery } from '../../services/suppliersApi';

export type ItemVendorPurchaseGroupSelectProps = Omit<TextFieldProps, 'select'> & {
  pn?: string;
  vendorCode?: string;
  value: PurchaseGroup | null;
  onChange: (value: PurchaseGroup | null, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function ItemVendorPurchaseGroupSelect(props: ItemVendorPurchaseGroupSelectProps, ref: React.Ref<unknown>) {
  const { pn, vendorCode, onChange, value, disabled, error, helperText, ...textFieldProps } = props;

  const supplierListReadParams = useMemo(() => {
    const filters = new Array<PagedRequestFilter>();
    if (pn !== undefined) {
      filters.push({ field: 'pn', value: pn, operator: FilterOperator.equals });
    }
    if (vendorCode !== undefined) {
      filters.push({ field: 'vendorCode', value: vendorCode, operator: FilterOperator.equals });
    }
    return { sort: ['vendorName'], filters, offset: 0, limit: 10 ** 7 };
  }, [pn, vendorCode]);

  const supplierList = useReadSupplierListQuery(supplierListReadParams, { skip: !pn && !vendorCode });
  const isEmpty = supplierList.data?.data.length === 0;

  const pgrMap = useMemo(
    () => supplierList.data?.data.reduce((map, { pgr }) => map.set(pgr.id, pgr), new Map<string, PurchaseGroup>()),
    [supplierList.data?.data],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const vendor = pgrMap?.get(event.target.value) ?? null;
      onChange(vendor, event);
    },
    [onChange, pgrMap],
  );

  const apiError = supplierList.error as ServerError | undefined;

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        select
        SelectProps={{ ref }}
        value={value?.id ?? ''}
        onChange={handleChange}
        disabled={Boolean(!pn && !vendorCode) || isEmpty || supplierList.isError || supplierList.isFetching || disabled}
        error={Boolean(apiError) || isEmpty || error}
        helperText={
          apiError?.data.message ||
          (isEmpty &&
            `Nessun buyer trovato per${pn !== undefined ? ` P/N ${pn}` : ''}${
              vendorCode !== undefined ? ` Vendor ${vendorCode}` : ''
            }`) ||
          helperText
        }
        {...textFieldProps}
      >
        {pgrMap ? (
          [...pgrMap.entries()].map(([id, pgr]) => (
            <MenuItem key={id} value={id}>
              {id} - {pgr.buyer}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            {' '}
          </MenuItem>
        )}
      </TextField>
      {supplierList.isFetching && (
        <CircularProgress color="secondary" size="2em" sx={{ position: 'absolute', top: 12, right: 40 }} />
      )}
    </Box>
  );
}

export default forwardRef(ItemVendorPurchaseGroupSelect);
