import { DataGrid, DataGridProps, ErrorAlert } from '@top-solution/microtecnica-mui';

import { useReadEUSListQuery } from '../../services/eusApi';
import { useEUSListDataGridColumns } from './useEUSListDataGridColumns';

type EUSListDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
};

export function EUSListDataGrid(props: EUSListDataGridProps) {
  const { columns, ...gridProps } = props;
  const defaultColumns = useEUSListDataGridColumns();

  const eusList = useReadEUSListQuery();

  if (eusList.error) {
    return <ErrorAlert error={eusList.error} />;
  }

  return (
    <DataGrid
      density="compact"
      rows={eusList.data ?? []}
      columns={columns ?? defaultColumns}
      sessionStorageId="EUSListPageDataGrid"
      loading={eusList.isFetching}
      initialState={{ sorting: { sortModel: [{ field: 'protocolNum', sort: 'desc' }] } }}
      pagination
      {...gridProps}
    />
  );
}
