import { useMemo } from 'react';
import { BaseQueryFn, TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react';
import { EUSStatus } from '../entities/EUSAttributes';
import { useReadStatusListQuery } from '../services/eusAttributesApi';

type UseEUSStatus = TypedUseQueryHookResult<EUSStatus[], void, BaseQueryFn> & {
  map: Map<EUSStatus['id'], EUSStatus> | null;
};

export function useEUSStatus(): UseEUSStatus {
  const readStatusList = useReadStatusListQuery();

  const map = useMemo(
    () =>
      readStatusList.data?.reduce((map, status) => map.set(status.id, status), new Map<EUSStatus['id'], EUSStatus>()) ??
      null,
    [readStatusList.data],
  );

  return {
    ...readStatusList,
    map,
  };
}
