import { z } from 'zod';
import { ECCNSchema } from './ECCN';

export const TechDataClassificationSchema = z.object({
  pn: z.string(),
  filename: z.string(),
  has3dModel: z.boolean(),
  spec: z.string().optional(),
  htsn: z.string().optional(),
  eccn: ECCNSchema.nullable(),
});

export type TechDataClassification = z.infer<typeof TechDataClassificationSchema>;
