import { useMemo, useState } from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import Chip from '@mui/material/Chip';
import { GridActionsCellItem, GridActionsColDef, GridColDef } from '@mui/x-data-grid-premium';
import { AddIcon, DeleteIcon, EyeIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import {
  RecipientListDataGrid,
  useRecipientListDataGridColumns,
  RecipientAddDialog,
  RecipientDeleteConfirm,
  RecipientCheckDialog,
} from '../../../components/Recipient';
import { getSource, isManual, ManualSoldPn, SoldPn, soldPnSourceList } from '../../../entities/SoldPn';
import { UserRole } from '../../../entities/User';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, recipientSection } from '../../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: recipientSection.title }];

const pinnedColumns = { right: ['actions'] };

export function RecipientListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const [recipientToAdd, setRecipientToAdd] = useState<SoldPn | null>(null);
  const [recipientToCheck, setRecipientToCheck] = useState<SoldPn | null>(null);
  const [recipientToDelete, setRecipientToDelete] = useState<SoldPn | null>(null);
  const defaultColumns = useRecipientListDataGridColumns();

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const columns: (GridColDef<SoldPn> | GridActionsColDef<SoldPn>)[] = [
      ...defaultColumns,
      {
        field: 'source',
        headerName: 'Fonte',
        type: 'singleSelect',
        valueOptions: soldPnSourceList,
        valueGetter: (value, row) => getSource(row),
        renderCell: ({ value }) => <Chip size="small" label={value} />,
        width: 120,
        sortable: false,
        filterable: true,
      },
    ];
    if (isAdmin || !recipientSection.role || hasRole(recipientSection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          const actions = [];
          if (isManual(row)) {
            actions.push(
              <GridActionsCellItem
                key="check"
                icon={<EyeIcon />}
                label="Check"
                onClick={() => setRecipientToCheck(row)}
              />,
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label="Elimina"
                onClick={() => setRecipientToDelete(row)}
              />,
            );
          } else if (row.fromPir) {
            actions.push(
              <GridActionsCellItem
                key="add"
                icon={<AddIcon />}
                label="Aggiungi associazione"
                onClick={() => setRecipientToAdd(row)}
              />,
            );
          }
          return actions;
        },
        width: 80,
        resizable: false,
      });
    }
    return columns;
  }, [defaultColumns, hasRole, isAdmin]);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper>
          <RecipientListDataGrid columns={columns} pinnedColumns={pinnedColumns} />
        </DataGridWrapper>
        {recipientToAdd && (
          <RecipientAddDialog
            soldPn={recipientToAdd}
            open={Boolean(recipientToAdd)}
            onClose={() => setRecipientToAdd(null)}
          />
        )}
        {recipientToDelete && (
          <RecipientDeleteConfirm
            soldPn={recipientToDelete}
            open={Boolean(recipientToDelete)}
            onClose={() => setRecipientToDelete(null)}
          />
        )}
        {recipientToCheck && recipientToCheck.customer && recipientToCheck.shipTo && (
          <RecipientCheckDialog
            soldPn={recipientToCheck as ManualSoldPn}
            open={Boolean(recipientToCheck)}
            onClose={() => setRecipientToCheck(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
