import { z } from 'zod';

export const PurchaseGroupSchema = z.object({
  id: z.string(),
  buyer: z.string(),
  // email may be "", that's why .email() is not used
  email: z.string().optional(),
});

export type PurchaseGroup = z.infer<typeof PurchaseGroupSchema>;
