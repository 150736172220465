import { useCallback, useMemo, useState, forwardRef, useEffect } from 'react';
import { useReadCountriesQuery, ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ECCN } from '../../entities/ECCN';
import { useReadECCNListQuery } from '../../services/eccnApi';
import { FormRow } from '.';

type ECCNGroupSelectProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: ECCN | null;
  onChange: (value: ECCN | null) => void;
};

function ECCNGroupSelect(props: ECCNGroupSelectProps, ref: React.Ref<unknown>): JSX.Element {
  const { value, onChange, required, sx } = props;
  const eccnList = useReadECCNListQuery();
  const countries = useReadCountriesQuery();
  const [selectedCountry, setSelectedCountry] = useState(value?.country ?? '');
  const [selectedLegislation, setSelectedLegislation] = useState(value?.legislation ?? '');

  const countryList = useMemo(() => {
    if (eccnList.data && countries.data) {
      const availableCountries = eccnList.data.reduce((set, { country }) => set.add(country), new Set<string>());
      return countries.data.list.filter(({ id }) => availableCountries.has(id));
    }
    return [];
  }, [eccnList.data, countries.data]);

  const legislationList = useMemo(() => {
    if (eccnList.data && selectedCountry) {
      const set = eccnList.data
        .filter(({ country }) => country === selectedCountry)
        .reduce((set, { legislation }) => set.add(legislation), new Set<string>());
      return [...set];
    }
    return [];
  }, [eccnList.data, selectedCountry]);

  const filteredECCNList = useMemo(() => {
    if (eccnList.data && selectedCountry && selectedLegislation) {
      return eccnList.data
        .filter(({ country, legislation }) => country === selectedCountry && legislation === selectedLegislation)
        .map(({ id }) => id);
    }
    return [];
  }, [eccnList.data, selectedCountry, selectedLegislation]);

  const handleChangeCountry = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedCountry(event.target.value);
      setSelectedLegislation('');
      onChange(null);
    },
    [onChange],
  );

  const handleChangeLegislation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedLegislation(event.target.value);
      onChange(null);
    },
    [onChange],
  );

  const handleECCNChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const eccn = eccnList.data?.find(({ id }) => id === event.target.value);
      onChange(eccn || null);
    },
    [eccnList.data, onChange],
  );

  useEffect(() => {
    if (value) {
      if (value.country !== selectedCountry) {
        setSelectedCountry(value.country);
      }
      if (value.legislation !== selectedLegislation) {
        setSelectedLegislation(value.legislation);
      }
    }
  }, [selectedCountry, selectedLegislation, value]);

  return (
    <FormRow className="ECCNGroupSelect-root" sx={{ display: 'flex', ...sx }}>
      <TextField
        select
        sx={{ flex: 2 }}
        className="ECCNGroupSelect-SelectCountry"
        label="Nazione di applicazione normativa Import/Export"
        required={required}
        value={selectedCountry}
        onChange={handleChangeCountry}
        disabled={eccnList.isFetching || countries.isFetching || countryList.length === 0}
        error={eccnList.isError || countries.isError}
        helperText={(countries.error as ServerError)?.data?.message || ' '}
      >
        {countryList.map((country) => (
          <MenuItem key={country.id} value={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        sx={{ flex: 1 }}
        className="ECCNGroupSelect-SelectLegislation"
        label="Normativa"
        required={required}
        value={selectedLegislation}
        onChange={handleChangeLegislation}
        disabled={eccnList.isFetching || legislationList.length === 0}
        error={eccnList.isError}
        helperText={(eccnList.error as ServerError)?.data?.message || ' '}
      >
        {legislationList.map((legislation) => (
          <MenuItem key={legislation} value={legislation}>
            {legislation}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        sx={{ flex: 1 }}
        className="ECCNGroupSelect-SelectECCN"
        label="ECCN"
        required={required}
        value={value?.id || ''}
        onChange={handleECCNChange}
        disabled={eccnList.isFetching || filteredECCNList.length === 0}
        error={eccnList.isError}
        helperText={(eccnList.error as ServerError)?.data?.message || ' '}
        inputRef={ref}
      >
        {filteredECCNList.map((code) => (
          <MenuItem key={code} value={code}>
            {code}
          </MenuItem>
        ))}
      </TextField>
    </FormRow>
  );
}

export default forwardRef(ECCNGroupSelect);
