import { useMemo } from 'react';
import { BottomUpSearchFields, BottomUpSearchParams } from '../../../entities/BottomUpSearch';
import { EUSEditModel } from '../../../entities/EUS';
import { VendorSource } from '../../../entities/Vendor';

export function useBottomUpSearchParams(
  data: EUSEditModel,
  fields: Partial<BottomUpSearchFields>,
): BottomUpSearchParams {
  return useMemo(
    () => ({
      fields: {
        enditem: false,
        pgr: false,
        vendor: false,
        program: false,
        customer: false,
        enduser: false,
        foreignClassification: false,
        italianClassification: false,
        techDataClassification: false,
        serni: false,
        ...fields,
      },
      filters: {
        pn: data.pnList ? data.pnList?.map((pn) => pn.pn) : [],
        pgr: [],
        vendor: data.vendor?.id && data.vendor.source !== VendorSource.Manual ? [data.vendor?.id] : [],
        eccn: [],
      },
    }),
    [data, fields],
  );
}
