import { BottomUpSearchParams, BottomUpSearchResult, BottomUpSearchResultSchema } from '../entities/BottomUpSearch';
import { TopDownSearchParams, TopDownSearchResult, TopDownSearchResultSchema } from '../entities/TopDownSearch';
import { api } from './baseApi';

const url = 'v1/search';

const cleanStringFilters = (list?: string[]): string[] => {
  return list?.filter((list) => Boolean(list)).map((value) => value.trim()) ?? [];
};

const parseNumberFilters = (list?: string[]): number[] => {
  return list?.map((value) => parseInt(value)).filter((value) => !isNaN(value)) ?? [];
};

const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    bottomUpSearch: builder.query<BottomUpSearchResult[], BottomUpSearchParams>({
      query: (searchParams) => ({
        url: `${url}/bottom-up`,
        method: 'POST',
        body: {
          fields: searchParams.fields,
          filters: {
            pn: cleanStringFilters(searchParams.filters.pn),
            vendor: cleanStringFilters(searchParams.filters.vendor),
            pgr: cleanStringFilters(searchParams.filters.pgr),
            eccn: cleanStringFilters(searchParams.filters.eccn),
          },
        },
      }),
      transformResponse: (data) => BottomUpSearchResultSchema.array().parse(data),
    }),
    topDownSearch: builder.query<TopDownSearchResult, TopDownSearchParams>({
      query: (searchParams) => ({
        url: `${url}/top-down`,
        method: 'POST',
        body: {
          pn: cleanStringFilters(searchParams.pn),
          program: cleanStringFilters(searchParams.program),
          enduser: parseNumberFilters(searchParams.enduser),
          customer: cleanStringFilters(searchParams.customer),
        },
      }),
      transformResponse: (data) => TopDownSearchResultSchema.parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useBottomUpSearchQuery, useLazyBottomUpSearchQuery, useTopDownSearchQuery, useLazyTopDownSearchQuery } =
  searchApi;
