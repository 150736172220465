import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/query';
import { CountryAutocomplete, ErrorAlert, PlantSelect } from '@top-solution/microtecnica-mui';
import { FilterOperator, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import equal from 'fast-deep-equal';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers-pro';
import {
  EUSEditItem,
  EUSEditModel,
  EUSEditSchema,
  EUSFieldDescriptionlMap,
  EUSFieldLabelMap,
} from '../../../entities/EUS';
import { SupplierLicenseType } from '../../../entities/EUSAttributes';
import { VendorSource } from '../../../entities/Vendor';
import { useCanEditVendor } from '../../../pages/Admin/Vendor/useCanEditVendor';
import { VendorEditDialog } from '../../../pages/Admin/Vendor/VendorEditDialog';
import { useReadStatusListQuery, useReadSupplierLicenseTypeListQuery } from '../../../services/eusAttributesApi';
import { useReadSupplierListQuery } from '../../../services/suppliersApi';
import { useReadEUSTurnbackListQuery } from '../../../services/turnbackApi';
import { parseMultilineInputValue } from '../../../utils/parseMultilineInputValue';
import { NOT_AVAILABLE } from '../../../utils/utils';
import { rowIdSeparator } from '../../DataGrid';
import { SelectAvailablePNListDialog } from '../../Dialog/SelectAvailablePNListDialog';
import { VendorAutocomplete } from '../../Form';
import AttachmentsField from '../../Form/AttachmentField/AttachmentsField';
import PurchaseGroupAutocompleteComponent from '../../Form/PurchaseGroupAutocomplete';
import { AddIcon } from '../../Icons';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

export const EUSInitialDataSchema = EUSEditSchema.pick({
  plantId: true,
  supplierLicenseTypeId: true,
  supplierLicenseTypeDuration: true,
  originalSentToSupplier: true,
  attachmentList: true,
  vendorRequestDate: true,
  eusLinkedLicense: true,
  eusReleaseDate: true,
  pgr: true,
  vendor: true,
  statusId: true,
  turnbackTypeId: true,
  turnbackNotes: true,
}).extend({ pnList: z.string().array().min(1) });

export type EUSInitialData = z.infer<typeof EUSInitialDataSchema>;

export function EUSInitialDataForm(): JSX.Element {
  const { data, defaultValues, handleNext, handleSave } = useContext(EUSFormContext);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<EUSInitialData>({
    defaultValues: {
      ...data,
      pnList: data.pnList?.map((item) => item.pn) ?? [],
    },
    resolver: zodResolver(EUSInitialDataSchema),
  });

  const canEditVendor = useCanEditVendor();
  const [showAddVendorDialog, setShowAddVendorDialog] = useState(false);

  const [missingPnList, setMissingPnList] = useState<Set<string> | null>(null);
  const [selectAvailablePNListDialogOpen, setSelectAvailablePNListDialogOpen] = useState(false);

  const turnbackTypeId = useWatch({
    control,
    name: 'turnbackTypeId',
  });

  const vendor = useWatch({
    control,
    name: 'vendor',
  });

  const supplierLicenseTypeId = useWatch({
    control,
    name: 'supplierLicenseTypeId',
  });

  const readSupplierListParams = useMemo(
    () =>
      !vendor?.id
        ? skipToken
        : {
            offset: 0,
            limit: 10 ** 7,
            filters: [{ field: 'vendorCode', value: vendor.id, operator: FilterOperator.equals }],
          },
    [vendor],
  );

  const readSupplierList = useReadSupplierListQuery(readSupplierListParams);
  const readPlantList = useReadPlantsQuery();
  const readSupplierLicensesTypeList = useReadSupplierLicenseTypeListQuery();
  const readStatusList = useReadStatusListQuery();
  const readEUSTurnbackList = useReadEUSTurnbackListQuery();

  const availablePnList = useMemo(() => {
    const set = new Set<string>();
    readSupplierList.data?.data.forEach((supplier) => set.add(supplier.pn));
    return set;
  }, [readSupplierList.data?.data]);

  const onSubmit = useMemo(
    () => (formData: EUSInitialData, callback: (editModel: EUSEditModel) => void) => {
      formData.pnList = formData.pnList?.filter((pn) => Boolean(pn)) ?? null;

      if (formData.pnList.length) {
        const missingPnList = new Set<string>();
        if (vendor.source !== VendorSource.Manual) {
          formData.pnList.forEach((pn) => {
            if (!availablePnList.has(pn)) {
              missingPnList.add(pn);
            }
          });
        }
        setMissingPnList(missingPnList.size > 0 ? missingPnList : null);
        if (missingPnList.size === 0) {
          const dataByPn = data.pnList?.reduce((map, item) => map.set(item.pn, item), new Map<string, EUSEditItem>());
          callback({
            ...data,
            ...formData,
            pnList: formData.pnList.map((pn) => dataByPn?.get(pn) ?? { pn, eccn: null, customerList: [], poList: [] }),
          });
        }
      }
    },
    [availablePnList, data, vendor],
  );

  const selectedLicense = useMemo<SupplierLicenseType | null>(() => {
    if (supplierLicenseTypeId !== null) {
      const license = readSupplierLicensesTypeList.data?.find(({ id }) => id === supplierLicenseTypeId);
      if (license) {
        return license;
      }
    }
    return null;
  }, [readSupplierLicensesTypeList.data, supplierLicenseTypeId]);

  useEffect(() => {
    if (selectedLicense?.validity) {
      setValue('supplierLicenseTypeDuration', selectedLicense.validity);
    }
  }, [selectedLicense, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit((data) => onSubmit(data, handleNext))}>
        <EUSFormFieldsWrapper sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
          <Grid container spacing={2} alignContent="flex-start">
            <Grid xs={12} sm={6}>
              {readStatusList.error ? (
                <ErrorAlert error={readStatusList.error} />
              ) : (
                readStatusList.data && (
                  <Controller
                    name="statusId"
                    control={control}
                    render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        select
                        fullWidth
                        label={EUSFieldLabelMap[field.name]}
                        value={value ?? ''}
                        onChange={(event) => {
                          const value = parseInt(event.target.value);
                          onChange((isNaN(value) ? null : value) as number);
                        }}
                        error={Boolean(error)}
                        helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                        {...field}
                      >
                        {!value && <MenuItem>&nbsp;</MenuItem>}
                        {readStatusList.data
                          ?.filter((status) => {
                            if (status.release) {
                              // The "release" status cannot be chosen
                              // https://github.com/top-solution/microtecnica.procurement-tool/issues/368
                              return false;
                            }
                            if (data.eusReleaseDate) {
                              // If the EUS has been released, only "draft"" status can be chosen
                              // https://github.com/top-solution/microtecnica.procurement-tool/issues/368
                              return status.draft;
                            }
                            return true;
                          })
                          .map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                )
              )}
            </Grid>
            <Grid xs={12} sm={6}>
              {readEUSTurnbackList.error ? (
                <ErrorAlert error={readEUSTurnbackList.error} />
              ) : (
                readEUSTurnbackList.data && (
                  <Controller
                    name="turnbackTypeId"
                    control={control}
                    render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        select
                        fullWidth
                        label={EUSFieldLabelMap[field.name]}
                        value={value ?? ''}
                        onChange={(event) => {
                          const value = parseInt(event.target.value);
                          if (isNaN(value)) {
                            setValue('turnbackNotes', null);
                          }
                          onChange((isNaN(value) ? null : value) as number);
                        }}
                        error={Boolean(error)}
                        helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                        {...field}
                      >
                        <MenuItem>&nbsp;</MenuItem>
                        {readEUSTurnbackList.data?.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )
              )}
            </Grid>
            {turnbackTypeId !== null && (
              <Grid xs={12}>
                <Controller
                  name="turnbackNotes"
                  control={control}
                  render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      label={EUSFieldLabelMap[field.name]}
                      value={value ?? ''}
                      onChange={(event) => onChange(event.target.value || null)}
                      error={Boolean(error)}
                      helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid xs={12} sm={6}>
              <Controller
                name="vendorRequestDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={EUSFieldLabelMap[field.name]}
                    {...field}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        error: Boolean(error),
                        helperText: error?.message || EUSFieldDescriptionlMap[field.name],
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Controller
                name="originalSentToSupplier"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label={EUSFieldLabelMap[field.name]}
                    value={typeof value !== 'boolean' ? '' : value ? 'true' : 'false'}
                    onChange={(event) => {
                      const value = event.target.value;
                      onChange(value === 'true' ? true : value === 'false' ? false : null);
                    }}
                    error={Boolean(error)}
                    helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                    {...field}
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="true">Sì</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Controller
                name="plantId"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                  <PlantSelect
                    size="small"
                    value={value && readPlantList.data ? readPlantList.data?.map[value] : null}
                    label={EUSFieldLabelMap[field.name]}
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                    onChange={(_, value) => {
                      onChange((value?.id ?? null) as number);
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Controller
                name="pgr"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                  <PurchaseGroupAutocompleteComponent
                    size="small"
                    label={EUSFieldLabelMap[field.name]}
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message || EUSFieldDescriptionlMap[field.name]}
                    onChange={(_, value) => onChange(value)}
                    value={value ?? null}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={6} sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}>
              <Controller
                name="vendor"
                control={control}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                  <>
                    {!value && canEditVendor && (
                      <>
                        <Tooltip title="Aggiungi fornitore mancante">
                          <IconButton
                            size="small"
                            sx={{ marginTop: '3px' }}
                            onClick={() => setShowAddVendorDialog(true)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <VendorAutocomplete
                      size="small"
                      label={EUSFieldLabelMap[field.name]}
                      fullWidth
                      error={Boolean(error)}
                      helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                      value={value}
                      onChange={(_, value) => {
                        onChange(value);
                        setMissingPnList(null);
                      }}
                      {...field}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <CountryAutocomplete
                size="small"
                label={EUSFieldLabelMap.vendorCountry}
                helperText={EUSFieldDescriptionlMap.vendorCountry}
                value={vendor?.country ?? null}
                disabled
              />
            </Grid>
            <Grid xs={12} sm={6}>
              {readSupplierLicensesTypeList.error ? (
                <ErrorAlert error={readSupplierLicensesTypeList.error} />
              ) : (
                readSupplierLicensesTypeList.data && (
                  <Controller
                    name="supplierLicenseTypeId"
                    control={control}
                    render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        select
                        fullWidth
                        label={EUSFieldLabelMap[field.name]}
                        value={value ?? ''}
                        onChange={(event) => {
                          const value = parseInt(event.target.value);
                          onChange((isNaN(value) ? null : value) as number);
                        }}
                        error={Boolean(error)}
                        helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                        {...field}
                      >
                        {!value && <MenuItem>&nbsp;</MenuItem>}
                        {readSupplierLicensesTypeList.data?.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )
              )}
            </Grid>
            <Grid xs={12} sm={6}>
              <Controller
                control={control}
                name="supplierLicenseTypeDuration"
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    label={EUSFieldLabelMap[field.name]}
                    value={value === -1 ? NOT_AVAILABLE : (value ?? '')}
                    disabled={!selectedLicense || selectedLicense.validity !== 0}
                    onChange={(event) => onChange(parseInt(event.target.value))}
                    error={Boolean(error)}
                    helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
                    fullWidth
                    // InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                name="eusLinkedLicense"
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    size="small"
                    multiple
                    freeSolo
                    autoSelect
                    options={[]}
                    renderInput={(params) => (
                      <TextField
                        label={EUSFieldLabelMap[field.name]}
                        error={Boolean(error)}
                        helperText={
                          (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
                          EUSFieldDescriptionlMap[field.name]
                        }
                        {...params}
                      />
                    )}
                    value={value ? (value.split(rowIdSeparator) ?? []) : []}
                    onChange={(_, value) => onChange(value.join(rowIdSeparator))}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="attachmentList"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <AttachmentsField
                    {...field}
                    value={field.value ?? []}
                    sx={{ flex: 1 }}
                    label={EUSFieldLabelMap[field.name]}
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              position: 'relative',
              minWidth: 240,
              '.MuiInputBase-input': {
                minHeight: { xs: '6em', md: '20em' },
                maxHeight: '35em',
              },
            }}
          >
            {readSupplierList.error ? (
              <ErrorAlert error={readSupplierList.error} />
            ) : (
              <Controller
                control={control}
                name="pnList"
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                  <>
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      label={EUSFieldLabelMap[field.name]}
                      error={Boolean(error)}
                      helperText={error?.message || ' '}
                      value={value?.join('\n') || ''}
                      onChange={(event) => onChange(parseMultilineInputValue(event.target.value))}
                      {...field}
                    />
                    <Box sx={{ position: 'absolute', top: 3, right: 3 }}>
                      {readSupplierList.isFetching ? (
                        <CircularProgress size={20} sx={{ margin: 1.5 }} />
                      ) : (
                        <Tooltip title="Aggiungi P/N">
                          <IconButton
                            size="small"
                            onClick={() => setSelectAvailablePNListDialogOpen(true)}
                            color="secondary"
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </>
                )}
              />
            )}
            {missingPnList && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                <AlertTitle>I seguenti P/N non risultano venduti da {vendor?.name}</AlertTitle>
                <ul>
                  {[...missingPnList].map((pn) => (
                    <li key={pn}>{pn}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <SelectAvailablePNListDialog
              open={selectAvailablePNListDialogOpen}
              onClose={(pnList) => {
                if (pnList) {
                  const list = [...(getValues('pnList') ?? []), ...(pnList ?? [])];
                  setValue('pnList', parseMultilineInputValue(list.join('\n')));
                }
                setSelectAvailablePNListDialogOpen(false);
              }}
              availablePnList={Array.from(availablePnList)}
            />
          </Box>
        </EUSFormFieldsWrapper>
        <EUSFormButtonsWrapper
          onSave={handleSubmit((data) => {
            onSubmit(data, handleSave);
            reset(data);
          })}
          isDirty={!equal(defaultValues, data) || isDirty}
        >
          <Button type="submit" variant="outlined" disabled={readSupplierList.isFetching} color="primary">
            Avanti
          </Button>
        </EUSFormButtonsWrapper>
        <EUSCancelConfirmDialog onSaveAndExit={handleSubmit((data) => onSubmit(data, handleSave))} />
      </form>
      <VendorEditDialog
        open={showAddVendorDialog}
        onClose={(vendor) => {
          if (vendor) {
            setValue('vendor', vendor);
          }
          setShowAddVendorDialog(false);
        }}
      />
    </>
  );
}
