import { useState } from 'react';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '../..';
import { Layout } from '../../../components/Layout';
import { TopDownSearchResult, TopDownSearchParams } from '../../../entities/TopDownSearch';
import { topDownSearch } from '../../sections';
import { TopDownSearchDataGrid } from './TopDownSearchDataGrid';
import { TopDownSearchForm } from './TopDownSearchForm';

const breadcrumbs = [{ title: topDownSearch.title }];

export function TopDownSearchPage(): JSX.Element {
  const [searchParams, setSearchParams] = useState<TopDownSearchParams>({
    pn: [],
    program: [],
    customer: [],
    enduser: [],
  });
  const [searchResult, setSearchResult] = useState<TopDownSearchResult | null>(null);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        {searchResult ? (
          <TopDownSearchDataGrid
            searchResult={searchResult}
            searchParams={searchParams}
            onBack={() => setSearchResult(null)}
          />
        ) : (
          <TopDownSearchForm
            searchParams={searchParams}
            onResultLoaded={(results, params) => {
              setSearchParams(params);
              setSearchResult(results);
            }}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
