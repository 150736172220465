import { useMemo } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useReadCountriesQuery, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EUS, EUSFieldDescriptionlMap, EUSFieldLabelMap, getEUSExpireDate, isEUSExpiring } from '../../entities/EUS';
import { useReadStatusListQuery, useReadSupplierLicenseTypeListQuery } from '../../services/eusAttributesApi';
import { useReadEUSTurnbackListQuery } from '../../services/turnbackApi';
import { formatStringDate, NOT_AVAILABLE } from '../../utils/utils';
import { rowIdSeparator } from '../DataGrid';

interface EUSListDetailsProps {
  eus: EUS;
}

type EUSDetailItemProps = Omit<BoxProps, 'children'> & {
  label: string;
  value?: string;
};

export function EUSDetailItem(props: EUSDetailItemProps): JSX.Element {
  const { label, value, sx, ...boxProps } = props;
  return (
    <Box sx={{ display: 'flex', columnGap: 1, ...sx }} {...boxProps}>
      <Typography variant="body1" sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}>
        {label}:
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
}

export function EUSListDetails(props: EUSListDetailsProps): JSX.Element {
  const { eus } = props;

  const readStatusList = useReadStatusListQuery();
  const readSupplierLicenseTypeList = useReadSupplierLicenseTypeListQuery();
  const readCountries = useReadCountriesQuery();
  const readPlants = useReadPlantsQuery();
  const readEUSTurnbackList = useReadEUSTurnbackListQuery();

  const licenseType = useMemo(
    () => readSupplierLicenseTypeList.data?.find((license) => license.id === eus.supplierLicenseTypeId),
    [eus.supplierLicenseTypeId, readSupplierLicenseTypeList.data],
  );

  const expireDate = useMemo(() => getEUSExpireDate(eus), [eus]);

  return (
    <Box>
      {readStatusList.error ? (
        <ErrorAlert error={readStatusList.error} />
      ) : (
        eus.statusId && (
          <EUSDetailItem
            label={EUSFieldDescriptionlMap.statusId}
            value={readStatusList.data?.find((status) => status.id === eus.statusId)?.name}
          />
        )
      )}
      {eus.eusReleaseDate && (
        <EUSDetailItem
          label={EUSFieldDescriptionlMap.eusReleaseDate}
          value={formatStringDate(eus.eusReleaseDate as string)}
        />
      )}
      {readPlants.error ? (
        <ErrorAlert error={readPlants.error} />
      ) : (
        eus.plantId && (
          <EUSDetailItem label={EUSFieldDescriptionlMap.plantId} value={readPlants.data?.map[eus.plantId].name} />
        )
      )}
      <EUSDetailItem label={EUSFieldDescriptionlMap.pgr} value={eus.pgr.buyer} />
      <EUSDetailItem label={EUSFieldDescriptionlMap.vendorCode} value={eus.vendor.id} />
      <EUSDetailItem label={EUSFieldDescriptionlMap.vendorName} value={eus.vendor.name} />
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.vendorCountry}
        value={readCountries.data?.byISO[eus.vendor.country]?.name ?? eus.vendor.country}
      />
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.vendorRequestDate}
        value={eus.vendorRequestDate ? formatStringDate(eus.vendorRequestDate) : '⚠️ Non specificata'}
      />
      {readSupplierLicenseTypeList.error ? (
        <ErrorAlert error={readSupplierLicenseTypeList.error} />
      ) : (
        <>
          <EUSDetailItem label={EUSFieldDescriptionlMap.supplierLicenseTypeId} value={licenseType?.name} />
          <EUSDetailItem
            label={EUSFieldDescriptionlMap.supplierLicenseTypeDuration}
            value={
              eus.supplierLicenseTypeDuration && eus.supplierLicenseTypeDuration > 0
                ? eus.supplierLicenseTypeDuration.toString()
                : NOT_AVAILABLE
            }
          />
        </>
      )}
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.supplierLicenseTypeValidity}
        value={expireDate ? formatStringDate(expireDate) : NOT_AVAILABLE}
        sx={
          expireDate && isEUSExpiring(eus)
            ? {
                color: 'error.main',
              }
            : undefined
        }
      />
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.additionalEUSRestrictions}
        value={eus.additionalEUSRestrictions || 'Non specificate'}
      />
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.eusLinkedLicense}
        value={eus.eusLinkedLicense?.split(rowIdSeparator).join(', ') || 'Non specificate'}
      />
      <EUSDetailItem
        label={EUSFieldDescriptionlMap.originalSentToSupplier}
        value={
          eus.originalSentToSupplier === true
            ? 'Sì'
            : eus.originalSentToSupplier === false
              ? 'No'
              : '⚠️ Non specificato'
        }
      />

      {typeof eus.tat === 'number' && (
        <EUSDetailItem label={EUSFieldLabelMap.tat} value={eus.tat === 1 ? eus.tat + ' giorno' : eus.tat + ' giorni'} />
      )}
      {readEUSTurnbackList.error ? (
        <ErrorAlert error={readEUSTurnbackList.error} />
      ) : (
        eus.turnbackTypeId !== undefined && (
          <EUSDetailItem
            label={EUSFieldDescriptionlMap.turnbackTypeId}
            value={readEUSTurnbackList.data?.find((turnback) => turnback.id === eus.turnbackTypeId)?.name}
          />
        )
      )}
      {eus.turnbackNotes && <EUSDetailItem label={EUSFieldDescriptionlMap.turnbackNotes} value={eus.turnbackNotes} />}
      {eus.notes && (
        <EUSDetailItem label={EUSFieldDescriptionlMap.notes} value={eus.notes} sx={{ flexDirection: 'column' }} />
      )}
    </Box>
  );
}
