import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/query';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { FilterOperator, NumberUtils } from '@top-solution/microtecnica-utils';
import equal from 'fast-deep-equal';
import { z } from 'zod';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import {
  EUSEditModel,
  EUSEditPO,
  EUSEditSchema,
  EUSFieldDescriptionlMap,
  EUSFieldLabelMap,
} from '../../../entities/EUS';
import { UnitOfMeasure } from '../../../entities/EUSAttributes';
import { PurchaseOrder } from '../../../entities/PurchaseOrder';
import { useReadPurchaseOrderListQuery } from '../../../services/purchaseOrderApi';
import { NOT_AVAILABLE } from '../../../utils/utils';
import {
  isAutoGeneratedRow,
  rowIdSeparator,
  useUnitOfMeasureColDef,
  qtyColumn,
  stringDateColumn,
  selectionModelsEquals,
} from '../../DataGrid';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

const numberUtils = new NumberUtils({});

function renderNotAvailableOnNull({ value, formattedValue }: GridRenderCellParams) {
  return value === null ? NOT_AVAILABLE : (formattedValue ?? value);
}

type PORow = Omit<PurchaseOrder, 'unitOfMeasureId' | 'vendor' | 'qty' | 'createDate' | 'deliveryDate'> & {
  id: string;
  unitOfMeasureId: UnitOfMeasure['id'] | null;
  qty: number | null;
  notes: string | null;
  createDate: string | null;
  deliveryDate: string | null;
};

const getRowId = (pn: string, purchaseDoc: string) => `${pn}${rowIdSeparator}${purchaseDoc}`;
const getIdComponents = (id: GridRowId) => (typeof id === 'string' ? id.split(rowIdSeparator) : [null, null]);

const EUSPOSelectionFormSchema = EUSEditSchema.pick({ pnList: true });

type EUSPOSelectionForm = z.infer<typeof EUSPOSelectionFormSchema>;

const initialState: DataGridPremiumProps['initialState'] = {
  rowGrouping: {
    model: ['pn'],
  },
  columns: {
    columnVisibilityModel: { pn: false },
  },
};

export function EUSEditPOForm(): JSX.Element {
  const { data, handleNext, handleBack, handleSave } = useContext(EUSFormContext);

  const { handleSubmit, formState, setValue, reset } = useForm<EUSPOSelectionForm>({
    defaultValues: data,
    resolver: zodResolver(EUSPOSelectionFormSchema),
  });

  const readPurchaseOrderListParams = useMemo(
    () =>
      !data.pnList || !data.vendor?.id
        ? skipToken
        : {
            offset: 0,
            limit: 10 ** 7,
            sort: ['-createDate'],
            filters: [
              { field: 'pn', value: data.pnList.map((pn) => pn?.pn).join(','), operator: FilterOperator.in },
              { field: 'vendorId', value: data.vendor.id, operator: FilterOperator.equals },
            ],
          },
    [data.pnList, data.vendor?.id],
  );

  const readPurchaseOrderList = useReadPurchaseOrderListQuery(readPurchaseOrderListParams);

  const uomColumn = useUnitOfMeasureColDef();
  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 300 },
      { field: 'purchaseDoc', headerName: EUSFieldLabelMap.po, width: 150 },
      {
        ...stringDateColumn,
        field: 'createDate',
        headerName: 'Data creazione',
        width: 150,
        renderCell: renderNotAvailableOnNull,
      },
      {
        ...stringDateColumn,
        field: 'deliveryDate',
        headerName: 'Data consegna',
        width: 150,
        renderCell: renderNotAvailableOnNull,
      },
      {
        ...qtyColumn,
        editable: true,
        renderCell: renderNotAvailableOnNull,
        cellClassName: ({ value }) => (value === null ? 'empty' : ''),
        valueSetter: (value, row) => {
          let qty = row.qty;
          if (typeof value === 'string') {
            qty = numberUtils.parse(value);
          }
          return { ...row, qty };
        },
      },
      {
        ...uomColumn,
        renderCell: renderNotAvailableOnNull,
        headerName: EUSFieldLabelMap.uom,
        width: 120,
        editable: true,
        cellClassName: ({ value }) => (value === null ? 'empty' : ''),
      },
      {
        field: 'notes',
        headerName: EUSFieldDescriptionlMap.poNotes,
        flex: 1,
        minWidth: 300,
        editable: true,
      },
    ],
    [uomColumn],
  );

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const initialSelectionModel = useRef<GridRowSelectionModel>([]);

  const rows = useMemo<PORow[]>(() => {
    if (readPurchaseOrderList.data?.data) {
      const previousPoByPnMap = data.pnList?.reduce((map, item) => {
        item.poList?.forEach((po) => {
          const previousPoByPnMap = map.get(item.pn) ?? new Map<string, EUSEditPO>();
          if (po.po !== null) {
            previousPoByPnMap.set(po.po, po);
          }
          map.set(item.pn, previousPoByPnMap);
        });
        return map;
      }, new Map<string, Map<string, EUSEditPO>>());
      return [
        ...readPurchaseOrderList.data.data.map((row) => {
          const previousPoByPn = previousPoByPnMap?.get(row.pn)?.get(row.purchaseDoc);
          return {
            ...row,
            id: getRowId(row.pn, row.purchaseDoc),
            qty: previousPoByPn?.qty ?? row.qty,
            unitOfMeasureId: previousPoByPn?.unitOfMeasureId ?? row.unitOfMeasureId,
            notes: previousPoByPn?.notes ?? null,
          };
        }),
        ...data.pnList.map(({ pn }) => {
          const previousPoByPn = previousPoByPnMap?.get(pn)?.get(NOT_AVAILABLE);
          return {
            id: getRowId(pn, NOT_AVAILABLE),
            pn: pn,
            purchaseDoc: NOT_AVAILABLE,
            createDate: null,
            deliveryDate: null,
            qty: previousPoByPn?.qty ?? null,
            unitOfMeasureId: previousPoByPn?.unitOfMeasureId ?? null,
            notes: previousPoByPn?.notes ?? null,
          };
        }),
      ];
    }
    return [];
  }, [data.pnList, readPurchaseOrderList.data]);

  const [updatedRows, setUpdatedRows] = useState(new Map<PORow['id'], PORow>());

  useEffect(() => {
    if (data.pnList) {
      const set = new Set<string>();
      data.pnList.forEach((item) => {
        item.poList?.forEach((po) => {
          if (po.po !== null) {
            set.add(getRowId(item.pn, po.po));
          }
        });
      });
      const selectionModel = Array.from(set);
      setSelectionModel(selectionModel);
      initialSelectionModel.current = selectionModel;
    }
  }, [data.pnList]);

  const processRowUpdate = useCallback(
    async (newRow: PORow) => {
      // new Map() needed for change detection
      setUpdatedRows(new Map(updatedRows.set(newRow.id, newRow)));
      return newRow;
    },
    [updatedRows],
  );

  const missingPoListByPn = useMemo(() => {
    const pnWithOrders = selectionModel.reduce((set, rowId) => {
      const [pn, purchaseDoc] = getIdComponents(rowId);
      if (pn !== null && purchaseDoc !== null) {
        set.add(pn);
      }
      return set;
    }, new Set<string>());
    return data.pnList?.filter(({ pn }) => !pnWithOrders.has(pn)).map(({ pn }) => pn) ?? [];
  }, [data.pnList, selectionModel]);

  const [missingPoListChecked, setMissingPoListChecked] = useState(false);

  const onSubmit = useCallback(
    (callback: (editModel: EUSEditModel) => void, event?: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault();

      if (missingPoListByPn.length > 0) {
        setMissingPoListChecked(true);
      } else {
        const rowsById = rows?.reduce((map, row) => {
          const poData = updatedRows.get(row.id) ?? row;
          return map.set(row.id, {
            po: poData.purchaseDoc,
            qty: poData.qty,
            unitOfMeasureId: poData.unitOfMeasureId,
            notes: poData.notes,
          });
        }, new Map<string, EUSEditPO>());

        const selectedPoByPn = selectionModel.reduce((map, rowId) => {
          if (typeof rowId === 'string') {
            const [pn] = rowId.split(rowIdSeparator);
            const list = map.get(pn) ?? [];
            const po = rowsById.get(rowId);
            if (po) {
              list.push(po);
            }
            map.set(pn, list);
          }
          return map;
        }, new Map<string, EUSEditPO[]>());

        setValue('pnList', data.pnList?.map((item) => ({ ...item, poList: selectedPoByPn.get(item.pn) ?? [] })) ?? []);

        return handleSubmit((formData) => callback({ ...data, ...formData }))(event);
      }
    },
    [data, handleSubmit, missingPoListByPn.length, rows, selectionModel, setValue, updatedRows],
  );

  const pnListErrors = useMemo(() => {
    const pnListErrors: (FieldError & {
      poList?: (FieldError & { qty: { message: string; type: string; ref: string } })[];
    })[] = [];
    if (formState.errors.pnList) {
      for (const [_, error] of Object.entries(formState.errors.pnList)) {
        if (typeof error === 'object') {
          pnListErrors.push(error as FieldError);
        }
      }
    }
    return pnListErrors;
  }, [formState.errors.pnList]);

  const selectionHasChanged = useMemo(() => {
    if (selectionModelsEquals(initialSelectionModel.current, selectionModel)) {
      const changedRow = rows.find((row) => updatedRows.has(row.id) && !equal(row, updatedRows.get(row.id)));
      return changedRow !== undefined;
    }
    return true;
  }, [rows, selectionModel, updatedRows]);

  return (
    <form onSubmit={(event) => onSubmit(handleNext, event)}>
      <EUSFormFieldsWrapper>
        {readPurchaseOrderList.error ? (
          <ErrorAlert error={readPurchaseOrderList.error} />
        ) : (
          <DataGridPremium
            columns={columns}
            rows={rows}
            loading={readPurchaseOrderList.isFetching}
            density="compact"
            hideFooter
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(rowSelectionModel) => {
              if (!readPurchaseOrderList.isFetching) {
                setSelectionModel(rowSelectionModel);
              }
            }}
            processRowUpdate={processRowUpdate}
            isRowSelectable={({ id }) => !isAutoGeneratedRow(id)}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnPinning
            defaultGroupingExpansionDepth={-1}
            groupingColDef={{
              headerName: '',
              width: 200,
              hideDescendantCount: true,
            }}
            initialState={initialState}
            disableColumnMenu
          />
        )}
      </EUSFormFieldsWrapper>
      {pnListErrors.length > 0 && pnListErrors.find((pn) => pn.poList) && (
        <Typography variant="caption" color="error" sx={{ display: 'block', marginBottom: 1 }}>
          Errore nella modifica della Quantità:
          {pnListErrors.map((error, i) => (
            <Typography key={i} component="ul" variant="caption" marginTop={0}>
              {error.poList?.map((err) => (
                <li key={err.qty.message}>{`P/N ${rows[i].pn}, PO: ${rows[i].purchaseDoc}: ${err.qty.message}`}</li>
              ))}
            </Typography>
          ))}
        </Typography>
      )}
      {missingPoListChecked && missingPoListByPn.length > 0 && (
        <Typography variant="caption" color="error" sx={{ display: 'block', marginBottom: 1 }}>
          Selezionare almeno un PO per i seguenti P/N:
          {missingPoListByPn.map((pn) => (
            <Typography key={pn} component="ul" variant="caption" marginTop={0}>
              <li key={pn}>{pn}</li>
            </Typography>
          ))}
          Nel caso nessun PO fosse applicabile, selezionare N/A.
        </Typography>
      )}
      <EUSFormButtonsWrapper
        onSave={() => {
          onSubmit(handleSave);
          reset(data);
        }}
        isDirty={selectionHasChanged}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
        <Button type="submit" variant="outlined" color="primary" disabled={readPurchaseOrderList.isFetching}>
          Avanti
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog
        onSaveAndExit={() => onSubmit(handleSave)}
        validationError={formState.isSubmitted && Boolean(formState.errors)}
      />
    </form>
  );
}
