import { z } from 'zod';

export enum EndUserType {
  Intermediate = 'intermediate',
  Ultimate = 'ultimate',
}
export const endUserTypeLabel: Record<EndUserType, string> = {
  [EndUserType.Intermediate]: 'Intermediate consignee',
  [EndUserType.Ultimate]: 'Ultimate consignee',
};

export const EndUserSchema = z.object({
  id: z.number(),
  name: z.string().min(3),
  country: z.string().min(2).max(3),
  address: z.string(),
  programId: z.string(),
  emailMro: z.string(),
  emailOe: z.string(),
  type: z.nativeEnum(EndUserType).optional(),
  lastCheck: z.string().datetime({ offset: true }),
});

export type EndUser = z.infer<typeof EndUserSchema>;

export const EndUserFormSchema = EndUserSchema.omit({ id: true, emailMro: true, emailOe: true, type: true }).extend({
  emailMro: z.string().email().array(),
  emailOe: z.string().email().array(),
  type: z.nativeEnum(EndUserType).nullable(),
});

export type EndUserForm = z.infer<typeof EndUserFormSchema>;
