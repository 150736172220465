import { z } from 'zod';

export enum VendorSource {
  VendorBuyer = 'VENDOR_BUYER_FILE',
  Manual = 'VENDOR_BUYER_MANUAL',
}

export const VendorSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
  da: z.string(),
  lead: z.string(),
  source: z.nativeEnum(VendorSource),
});

export type Vendor = z.infer<typeof VendorSchema>;

export const EditVendorFormSchema = z.object({
  id: z.string().regex(/\d{6}/),
  name: z.string().min(3).max(512),
  country: z.string(),
  da: z.string().min(3).max(64),
  lead: z.string().min(3).max(64),
});

export type EditVendorForm = z.infer<typeof EditVendorFormSchema>;
