import { useMemo } from 'react';
import { useCountryGridColDef, useProgramGridColDef } from '@top-solution/microtecnica-mui';
import { GridActionsColDef, GridColDef } from '@mui/x-data-grid-premium';
import { SoldPn } from '../../entities/SoldPn';
import { makeBuyColumn } from '../DataGrid';

export function useRecipientListDataGridColumns() {
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const programColumn = useProgramGridColDef();

  return useMemo<(GridColDef<SoldPn> | GridActionsColDef<SoldPn>)[]>(
    () => [
      { field: 'pn', headerName: 'Pn', width: 110 },
      { ...makeBuyColumn },
      { ...programColumn, width: 130 },
      {
        field: 'customerId',
        headerName: 'Customer code',
        valueGetter: (value, row) => row.customer?.id,
        width: 110,
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        valueGetter: (value, row) => row.customer?.name,
        width: 300,
      },
      {
        ...countryColumn,
        field: 'customerCountry',
        headerName: 'Nazione Customer',
        valueGetter: (value, row) => row.customer?.country,
        width: 150,
      },
      {
        field: 'customerAddress',
        headerName: 'Indirizzo Customer',
        valueGetter: (value, row) => row.customer?.address,
        width: 300,
        sortable: false,
      },
      {
        field: 'shipToId',
        headerName: 'Ship to code',
        valueGetter: (value, row) => row.shipTo?.id,
        width: 110,
      },
      {
        field: 'shipToName',
        headerName: 'Ship to',
        valueGetter: (value, row) => row.shipTo?.name,
        width: 300,
      },
      {
        ...countryColumn,
        field: 'shipToCoutnry',
        headerName: 'Nazione Ship to',
        valueGetter: (value, row) => row.shipTo?.country,
        width: 150,
      },
      {
        field: 'shipToAddress',
        headerName: 'Indirizzo Ship to',
        valueGetter: (value, row) => row.shipTo?.address,
        width: 300,
        sortable: false,
      },
    ],
    [countryColumn, programColumn],
  );
}
