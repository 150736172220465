import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import { DownloadIcon } from '../../components/Icons';
import { EUS } from '../../entities/EUS';
import { useDownloadAttachmentA } from './useDownloadAttachmentA';

interface EUSDownloadAttachmentAProps {
  eus: EUS;
}

export function EUSDownloadAttachmentA(props: EUSDownloadAttachmentAProps): JSX.Element {
  const { eus } = props;

  const { download, isLoading, isReady } = useDownloadAttachmentA(eus);

  return (
    <Tooltip title="Scarica Allegato A">
      <LoadingButton
        size="small"
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        onClick={download ?? undefined}
        loading={!isReady || isLoading}
      >
        Allegato A
      </LoadingButton>
    </Tooltip>
  );
}
