import { useCallback, useMemo, useState } from 'react';
import {
  ErrorAlert,
  DataGrid,
  DataGridWrapper,
  useCountryGridColDef,
  usePagination,
  muiFiltersToPagedRequestFilters,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, ApiError } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import Dialog from '@mui/material/Dialog';
import {
  GridActionsColDef,
  GridColDef,
  GridSortModel,
  GridFilterModel,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';
import { isMilitaryColumn } from '../../../components/DataGrid';
import { useECCNGridColDef } from '../../../components/DataGrid/useECCN';
import { Layout } from '../../../components/Layout';
import { UserRole } from '../../../entities/User';
import { useReadTechDataClassificationListQuery } from '../../../services/techDataClassificationApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, techDataClassificationSection } from '../../sections';

const breadcrumbs = [
  { title: adminSection.title, url: adminSection.url },
  { title: techDataClassificationSection.title },
];

export function TechDataClassificationListPage(): JSX.Element {
  const eccnColumn = useECCNGridColDef();
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const searchParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readTechDataClassificationList = useReadTechDataClassificationListQuery(searchParams);
  const [updateError, setUpdateError] = useState<ZodError | ApiError | null>(null);

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 150 },
      {
        field: 'filename',
        headerName: 'File Name',
        width: 350,
      },
      {
        field: 'has3dModel',
        headerName: '3D Model',
        type: 'boolean',
        width: 80,
      },
      {
        field: 'spec',
        headerName: 'Specification',
        width: 150,
      },
      { ...eccnColumn, valueGetter: (value, row) => row.eccn?.id, width: 120 },
      {
        ...isMilitaryColumn,
        valueGetter: (value, row) => row.eccn?.isMilitary,
        width: 150,
      },
      {
        ...countryColumn,
        valueGetter: (value, row) => row.eccn?.country,
        width: 180,
      },
      {
        field: 'htsn',
        headerName: 'HTSN',
        width: 200,
      },
    ],
    [countryColumn, eccnColumn],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readTechDataClassificationList.isLoading}
        error={readTechDataClassificationList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        {updateError && (
          <Dialog open={true} onClose={() => setUpdateError(null)}>
            <ErrorAlert error={updateError} />
          </Dialog>
        )}
        <DataGridWrapper>
          <DataGrid
            rows={readTechDataClassificationList.data?.data ?? []}
            rowCount={readTechDataClassificationList.data?.total ?? 0}
            getRowId={(data) => data.pn}
            columns={columns}
            sessionStorageId="TechDataListPageDataGrid"
            loading={readTechDataClassificationList.isFetching}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
