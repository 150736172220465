import { z } from 'zod';

export const HistoryEntitySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type HistoryEntity = z.infer<typeof HistoryEntitySchema>;

export enum HistoryAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const HistoryActionLabel = {
  [HistoryAction.Create]: 'Aggiunta',
  [HistoryAction.Update]: 'Modifica',
  [HistoryAction.Delete]: 'Eliminazione',
};

export const HistoryRecordSchema = z.object({
  id: z.number(),
  entity: HistoryEntitySchema,
  timestamp: z.string().datetime({ offset: true }),
  user: z.string(),
  objectId: z.number(),
  action: z.nativeEnum(HistoryAction),
  newValue: z.string().optional(),
  oldValue: z.string().optional(),
});

export type HistoryRecord = z.infer<typeof HistoryRecordSchema>;
