import { useMemo } from 'react';
import { CustomTableFooter, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useEndUserListGridColumns } from '../../components/EndUser';
import { EUS, EUSEnduserItem, EUSFieldDescriptionlMap } from '../../entities/EUS';
import { EUSDetailItem } from './EUSDetailPage';

interface EUSEndUserDetailsDataGridProps {
  eus: EUS;
}

export function EUSEndUserDetailsDataGrid(props: EUSEndUserDetailsDataGridProps): JSX.Element {
  const { eus } = props;

  const endUserColumns = useEndUserListGridColumns();
  const countryColumn = useCountryGridColDef({ hideFlag: true });

  const columns = useMemo<GridColDef<EUSEnduserItem>[]>(
    () => [
      {
        ...endUserColumns[1],
        valueGetter: (value, row) => row.enduser.programId,
      },
      {
        ...endUserColumns[2],
        valueGetter: (value, row) => row.enduser.name,
      },
      {
        ...endUserColumns[3],
        field: 'enduserType',
        renderCell: ({ value, formattedValue }) => (value ? formattedValue : '⚠️ Non specificato'),
      },
      {
        ...countryColumn,
        valueGetter: (value, row) => row.enduser.country,
      },
      {
        ...endUserColumns[5],
        valueGetter: (value, row) => row.enduser.address,
      },
    ],
    [countryColumn, endUserColumns],
  );

  return (
    <Box>
      {eus.enduserList.length > 0 ? (
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
            {EUSFieldDescriptionlMap.enduserList}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 300,
            }}
          >
            <DataGridPremium
              density="compact"
              getRowId={(row: EUSEnduserItem) => row.enduser.id}
              rows={eus.enduserList}
              columns={columns}
              slots={{
                footer: CustomTableFooter,
              }}
              disableColumnSelector
              disableColumnFilter
              disableDensitySelector
              disableAggregation
              disableRowGrouping
            />
          </Box>
        </Box>
      ) : (
        <EUSDetailItem label={EUSFieldDescriptionlMap.enduserList} value="⚠️ Non specificati" />
      )}
    </Box>
  );
}
