import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { CustomerListDataGrid } from '../../../components/Customer';
import { Layout } from '../../../components/Layout';
import { UserRole } from '../../../entities/User';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, customerSection } from '../../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: customerSection.title }];

export function CustomerListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper>
          <CustomerListDataGrid />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
