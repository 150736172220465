import { useMemo } from 'react';

import { CustomTableFooter, useCountryGridColDef, useProgramGridColDef } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { rowIdSeparator } from '../../components/DataGrid';
import { ItemDescription } from '../../components/Item';
import { EUS, EUSCustomerItem, EUSFieldDescriptionlMap } from '../../entities/EUS';
import { EUSDetailItem } from './EUSDetailPage';

interface EUSEndItemCustomerDetailsDataGridProps {
  eus: EUS;
}

type EnditemDetailsRow = EUSCustomerItem & {
  item: string;
};

export function EUSCustomerDetailsDataGrid(props: EUSEndItemCustomerDetailsDataGridProps): JSX.Element {
  const { eus } = props;

  const countryColumn = useCountryGridColDef({ hideFlag: true });
  const programColumn = useProgramGridColDef();

  const rows = useMemo(() => {
    if (eus && eus.pnList) {
      const rows: EnditemDetailsRow[] = [];
      eus.pnList.forEach((item) => {
        item.customerList?.forEach((customer) => {
          if (customer.enditem) {
            rows.push({ ...customer, item: item.pn });
          }
        });
      });
      return rows;
    }
    return [];
  }, [eus]);

  const columns = useMemo<GridColDef<EnditemDetailsRow>[]>(
    () => [
      { field: 'item', headerName: 'P/N', width: 150 },
      { field: 'enditem', headerName: 'End Item', width: 150 },
      {
        field: 'enditemDescription',
        headerName: 'Descrizione End Item',
        width: 200,
        renderCell: ({ row }) => row.enditem && <ItemDescription pn={row.enditem} variant="body2" />,
      },
      {
        ...programColumn,
        width: 150,
        valueFormatter: (value) => value ?? '⚠️ Non specificato',
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        width: 150,
        valueGetter: (value, row) => row.customer?.name ?? '⚠️ Non specificato',
      },
      {
        ...countryColumn,
        field: 'customerCountry',
        headerName: 'Customer Country',
        width: 120,
        valueGetter: (value, row) => row.customer?.country,
      },
      {
        field: 'customerAddress',
        headerName: 'Customer Address',
        flex: 1,
        minWidth: 100,
        valueGetter: (value, row) => row.customer?.address,
      },
      {
        field: 'shipToName',
        headerName: 'Ship to',
        width: 150,
        valueGetter: (value, row) => row.shipTo?.name ?? '⚠️ Non specificato',
      },
      {
        ...countryColumn,
        field: 'shipToCountry',
        headerName: 'Ship to Country',
        width: 120,
        valueGetter: (value, row) => row.shipTo?.country,
      },
      {
        field: 'shipToAddress',
        headerName: 'Ship to Address',
        flex: 1,
        minWidth: 100,
        valueGetter: (value, row) => row.shipTo?.address,
      },
    ],
    [countryColumn, programColumn],
  );

  return (
    <Box>
      {rows.length > 0 ? (
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 700 }} mt={2.5} mb={1.5}>
            {EUSFieldDescriptionlMap.customer}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: 300,
            }}
          >
            <DataGridPremium
              density="compact"
              getRowId={(row: EnditemDetailsRow) =>
                [row.item, row.enditem, row.programId, row.customer?.id, row.shipTo?.id].join(rowIdSeparator)
              }
              rows={rows}
              columns={columns}
              slots={{
                footer: CustomTableFooter,
              }}
              disableColumnSelector
              disableColumnFilter
              disableDensitySelector
              disableAggregation
              disableRowGrouping
            />
          </Box>
        </Box>
      ) : (
        <EUSDetailItem label={EUSFieldDescriptionlMap.customer} value="⚠️ Non specificati" />
      )}
    </Box>
  );
}
