import { ReactNode, useCallback, useContext } from 'react';
import equal from 'fast-deep-equal';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { FlexSpacer } from '../../FlexSpacer';
import { EUSFormContext } from './EUSFormContext';

export const EUSFormFieldsWrapper = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 208px)',
  overflowY: 'auto',
  padding: theme.spacing(1, 2),
}));

const StyledFormButtonsWrapper = styled('div')(({ theme }) => ({
  marginY: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}));

interface EUSFormButtonsWrapperProps {
  children: ReactNode | ReactNode[];
  isDirty: boolean;
  onSave: () => void;
}

export function EUSFormButtonsWrapper(props: EUSFormButtonsWrapperProps): JSX.Element {
  const { children, isDirty, onSave } = props;
  const { saveStatus, handleCancel, initialData, data, cancelConfirmDialog } = useContext(EUSFormContext);

  const onCancel = useCallback(() => {
    if (!isDirty && equal(initialData, data)) {
      handleCancel();
    } else {
      cancelConfirmDialog.setOpen(true);
    }
  }, [cancelConfirmDialog, data, handleCancel, initialData, isDirty]);

  return (
    <StyledFormButtonsWrapper>
      <Button color="secondary" variant="contained" onClick={onCancel}>
        {initialData.id ? 'Chiudi' : 'Annulla'}
      </Button>
      <LoadingButton color="primary" variant="contained" onClick={onSave} loading={saveStatus.isLoading}>
        Salva
      </LoadingButton>
      <FlexSpacer />
      {children}
    </StyledFormButtonsWrapper>
  );
}
