import { useCallback, useContext, useMemo, useState } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { bottomUpSearchHasThirdPartyWarning, BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { EUSEditModel, EUSEditSchema } from '../../../entities/EUS';
import { useECCNMap } from '../../../hooks/useECCNMap';
import { useBottomUpSearchQuery } from '../../../services/searchApi';
import { selectionModelsEquals } from '../../DataGrid';
import { FullPageProgress } from '../../Progress';
import { ThirdPartyDataGrid } from '../../ThirdParty';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

export const EUSEditECCNSchema = EUSEditSchema.pick({
  pnList: true,
});

export function EUSEditThirdPartyForm(): JSX.Element {
  const { data, handleBack, handleNext, handleSave } = useContext(EUSFormContext);
  const [isValid, setIsValid] = useState(true);
  const eccn = useECCNMap();

  const bottomUpSearchParams = useMemo(
    () => ({
      fields: {
        enditem: false,
        pgr: false,
        vendor: true,
        program: false,
        customer: false,
        enduser: false,
        foreignClassification: true,
        italianClassification: true,
        techDataClassification: false,
        serni: false,
      },
      filters: {
        pn: data.pnList ? data.pnList?.map((pn) => pn.pn) : [],
        vendor: data.vendor?.id ? [data.vendor?.id] : [],
        pgr: [],
        eccn: [],
      },
    }),
    [data.pnList, data.vendor?.id],
  );
  const bottomUpSearch = useBottomUpSearchQuery(bottomUpSearchParams);

  const needsThirdParties = useMemo(() => {
    if (bottomUpSearch.data && eccn.map) {
      let needed = bottomUpSearch.data.findIndex((row) => bottomUpSearchHasThirdPartyWarning(row)) > -1;
      if (!needed) {
        /* Check if the user classified the P/N as military in EUSEditECCNForm */
        const virtualRows: BottomUpSearchResult[] =
          data.pnList?.map((item) => ({
            item: item.pn,
            vendorCountry: data.vendor?.country,
            foreignClassificationECCNIsMilitary: item.eccn ? eccn.map?.get(item.eccn)?.isMilitary : false,
          })) ?? [];
        needed = virtualRows.findIndex((row) => bottomUpSearchHasThirdPartyWarning(row)) > -1;
      }
      return needed;
    }
    /* we don't know yet, better return true to block submit */
    return true;
  }, [bottomUpSearch.data, data.pnList, data.vendor?.country, eccn.map]);

  const [initialSelectionModel, setInitialSelectionModel] = useState<GridRowSelectionModel>(data.thirdPartyList ?? []);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(initialSelectionModel);

  const onSubmit = useCallback(
    (callback: (data: EUSEditModel) => void, event?: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      if (needsThirdParties && !selectionModel.length) {
        setIsValid(false);
      } else {
        setIsValid(true);
        callback({ ...data, thirdPartyList: selectionModel as number[] });
      }
    },
    [data, needsThirdParties, selectionModel],
  );

  const selectionHasChanged = useMemo(
    () => !selectionModelsEquals(initialSelectionModel, selectionModel),
    [initialSelectionModel, selectionModel],
  );

  return (
    <form onSubmit={(event) => onSubmit(handleNext, event)}>
      <EUSFormFieldsWrapper>
        {bottomUpSearch.error || eccn.error ? (
          <ErrorAlert error={(bottomUpSearch.error || eccn.error) as Error} />
        ) : bottomUpSearch.isFetching || eccn.isFetching ? (
          <FullPageProgress />
        ) : needsThirdParties ? (
          <ThirdPartyDataGrid
            hideFooter
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(rowSelectionModel) => {
              if (!bottomUpSearch.isFetching) {
                setSelectionModel(rowSelectionModel);
              }
            }}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
          />
        ) : (
          <Alert severity="info">
            <AlertTitle>Terze parti non richieste per questo EUS</AlertTitle>
            Nessun P/N militare risulta prodotto o fornito da aziende USA
          </Alert>
        )}
      </EUSFormFieldsWrapper>
      {!isValid && (
        <Typography variant="caption" color="error" sx={{ display: 'block', marginBottom: 1 }}>
          Selezionare almeno una terza parte.
        </Typography>
      )}
      <EUSFormButtonsWrapper
        onSave={() => {
          onSubmit(handleSave);
          setInitialSelectionModel(selectionModel);
        }}
        isDirty={selectionHasChanged}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
        <Button type="submit" variant="outlined" color="primary" disabled={bottomUpSearch.isFetching}>
          Avanti
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog onSaveAndExit={() => onSubmit(handleSave)} />
    </form>
  );
}
