import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CountryAutocomplete, ErrorAlert } from '@top-solution/microtecnica-mui';
import { ServerError } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormRow } from '../../../components/Form';
import { SaveIcon } from '../../../components/Icons';
import { ECCN, ECCNSchema } from '../../../entities/ECCN';
import {
  useCreateECCNMutation,
  useReadECCNLegislationListQuery,
  useUpdateECCNMutation,
} from '../../../services/eccnApi';

type ECCNEditDialog = DialogProps & {
  initialValues: ECCN;
  onClose: (data: ECCN | null) => void;
};

export function ECCNEditDialog(props: ECCNEditDialog): JSX.Element {
  const { initialValues, onClose, ...dialogProps } = props;
  const [create, createStatus] = useCreateECCNMutation();
  const [update, updateStatus] = useUpdateECCNMutation();
  const legislationList = useReadECCNLegislationListQuery();
  const form = useForm<ECCN>({
    defaultValues: initialValues,
    resolver: zodResolver(ECCNSchema),
  });
  const { handleSubmit, control } = form;

  const editMode = Boolean(initialValues.id);

  const onSubmit = useCallback(
    async (data: ECCN) => {
      if (editMode) {
        await update(data).unwrap();
      } else {
        await create(data).unwrap();
      }
      onClose(data);
    },
    [create, editMode, onClose, update],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          createStatus.reset();
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>{editMode ? 'Modifica ECCN' : 'Nuovo ECCN'}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
        <FormRow>
          <Controller
            control={control}
            name="id"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Codice classificazione"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                disabled={editMode}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="isMilitary"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <FormControl error={Boolean(error)}>
                <Stack direction="row" spacing={1} alignItems="center" height={56} minWidth="20ch">
                  <Typography>Civile</Typography>
                  <FormControlLabel control={<Switch checked={value} {...field} />} label="" />
                  <Typography>Militare</Typography>
                </Stack>
              </FormControl>
            )}
          />
        </FormRow>
        <FormRow>
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <CountryAutocomplete
                label="Nazione"
                onChange={(_, value) => onChange(value)}
                disableClearable
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="legislation"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                select
                label="Normativa"
                error={Boolean(error) || legislationList.isError}
                disabled={legislationList.isFetching || legislationList.isError}
                helperText={error?.message || (legislationList.error as ServerError)?.data?.message || ' '}
                sx={{ minWidth: '20ch' }}
                {...field}
              >
                {legislationList.data?.map((legislation) => (
                  <MenuItem key={legislation} value={legislation}>
                    {legislation}
                  </MenuItem>
                )) || <MenuItem value="">&nbsp;</MenuItem>}
              </TextField>
            )}
          />
        </FormRow>
        <FormRow>
          <Controller
            control={control}
            name="availableToManufacturers"
            render={({ field: { value, ...field }, fieldState: { error } }) => (
              <FormControl error={Boolean(error)}>
                <FormControlLabel control={<Switch checked={value} {...field} />} label="Disponibile ai manufacturer" />
              </FormControl>
            )}
          />
        </FormRow>
        {createStatus.error && <ErrorAlert error={createStatus.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose(null)}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={createStatus.isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
