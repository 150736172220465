import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert, ProgramAutocomplete } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { RecipientAddForm, RecipientAddFormSchema } from '../../entities/Recipient';
import { SoldPn } from '../../entities/SoldPn';
import { useCreateSoldPnMutation } from '../../services/soldPnApi';
import { CustomerAutocomplete } from '../Form';
import { EmailNotificationList } from './EmailNotificationList';
import { useCanManageSoldPn } from './useCanManageSoldPn';

type RecipientAddDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: (recipient?: RecipientAddForm) => void;
  soldPn: SoldPn;
};

export function RecipientAddDialog(props: RecipientAddDialogProps): JSX.Element {
  const { soldPn, onClose, TransitionProps, ...dialogProps } = props;
  const [create, createStatus] = useCreateSoldPnMutation();
  const { control, handleSubmit, reset } = useForm<RecipientAddForm>({
    defaultValues: {
      pn: soldPn.pn,
      programId: soldPn.programId,
      customer: null as unknown as RecipientAddForm['customer'],
      shipTo: null as unknown as RecipientAddForm['shipTo'],
    },
    resolver: zodResolver(RecipientAddFormSchema),
  });

  const canAdd = useCanManageSoldPn(soldPn.programId);

  const onSubmit = useCallback(
    async (data: RecipientAddForm) => {
      await create(data).unwrap();
      onClose(data);
    },
    [create, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...dialogProps}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Nuova associazione Customer per End Item {soldPn.pn}</DialogTitle>
        <DialogContent>
          <Stack gap={1} marginTop={1}>
            <ProgramAutocomplete label="Programma" value={soldPn.programId} helperText=" " disabled />
            <Controller
              control={control}
              name="customer"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <CustomerAutocomplete
                  label="Customer"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  disabled={!canAdd}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="shipTo"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <CustomerAutocomplete
                  label="Ship to"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  disabled={!canAdd}
                  {...field}
                />
              )}
            />
            <EmailNotificationList programId={soldPn.programId}>
              {!canAdd && (
                <Alert severity="warning">
                  Devi essere un PM di questo programma per poter aggiungere l&apos;associazione
                </Alert>
              )}
            </EmailNotificationList>
            {createStatus.error && <ErrorAlert error={createStatus.error} />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton type="submit" variant="contained" color="primary" loading={createStatus.isLoading}>
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
