import { memo, useMemo } from 'react';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EUSFieldLabelMap } from '../../entities/EUS';
import { EUSStatus } from '../../entities/EUSAttributes';
import { useEUSStatus } from '../../hooks/useEUS';

const EUSStatusChip = memo(function EUSStatusChip({ id }: { id: EUSStatus['id'] }): JSX.Element {
  const { map, isLoading } = useEUSStatus();
  const status = map?.get(id);
  if (isLoading) {
    return <Skeleton variant="rounded" height="24px" width="12ch" />;
  }
  const draft = Boolean(status?.draft);
  const released = Boolean(status?.name.toLowerCase().startsWith('license released'));
  const sentToSupplier = Boolean(status?.name.toLowerCase().endsWith('sent to supplier'));
  return (
    <Chip
      sx={{
        bgcolor: draft ? 'warning.light' : released ? 'success.dark' : sentToSupplier ? 'success.light' : undefined,
        color: draft ? 'warning.contrastText' : released || sentToSupplier ? 'success.contrastText' : undefined,
      }}
      size="small"
      label={status?.name ?? id}
    />
  );
});

export function useEUSStatusColDef(): GridColDef {
  const { data } = useEUSStatus();
  const valueOptions = useMemo(() => data?.map(({ id, name }) => ({ value: id, label: name })), [data]);

  return useMemo(
    () => ({
      field: 'statusId',
      headerName: EUSFieldLabelMap.statusId,
      type: 'singleSelect',
      width: 280,
      valueOptions,
      renderCell: ({ value }) => <EUSStatusChip id={value} />,
    }),
    [valueOptions],
  );
}
