import { useCallback, useMemo, useState } from 'react';
import {
  ErrorAlert,
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  useCountryGridColDef,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, ApiError, useAuth } from '@top-solution/microtecnica-utils';
import { format, isValid } from 'date-fns';
import { it } from 'date-fns/locale';
import { ZodError } from 'zod';
import Dialog from '@mui/material/Dialog';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid-premium';
import { isMilitaryColumn } from '../../../components/DataGrid';
import { useECCNGridColDef } from '../../../components/DataGrid/useECCN';
import { DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { ForeignClassification } from '../../../entities/ForeignClassification';
import { UserRole } from '../../../entities/User';
import {
  useReadForeignClassificationListQuery,
  useRemoveForeignClassificationMutation,
} from '../../../services/foreignClassificationApi';
import { useReadPurchaseGroupListQuery } from '../../../services/purchaseGroupApi';
import { useReadVendorListQuery } from '../../../services/vendorApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, foreignClassificationSection } from '../../sections';
import { ForeignClassificationAddButton } from './ForeignClassificationAddButton';
import { ForeignClassificationEditDialog } from './ForeignClassificationEditDialog';

const breadcrumbs = [
  { title: adminSection.title, url: adminSection.url },
  { title: foreignClassificationSection.title },
];
const pinnedColumns = { right: ['actions'] };

export function ForeignClassificationListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const countryColumn = useCountryGridColDef({ format: 'png' });
  const eccnColumn = useECCNGridColDef();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const [updateError, setUpdateError] = useState<ZodError | ApiError | null>(null);
  const [rowToRemove, setRowToRemove] = useState<ForeignClassification | null>(null);
  const [rowToEdit, setRowToEdit] = useState<ForeignClassification | null>(null);
  const readForeignClassificationListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const foreignClassificationList = useReadForeignClassificationListQuery(readForeignClassificationListParams);
  const [removeForeignClassification, removeForeignClassificationStatus] = useRemoveForeignClassificationMutation();

  const vendorList = useReadVendorListQuery();
  const pgrList = useReadPurchaseGroupListQuery();

  const handleEditClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setRowToEdit(foreignClassificationList.data?.data.find(({ id }) => id === row.id) || null);
      },
    [foreignClassificationList.data?.data],
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setRowToRemove(foreignClassificationList.data?.data.find(({ id }) => id === row.id) || null);
      },
    [foreignClassificationList.data?.data],
  );

  const handleDeleteConfirm = useCallback(async () => {
    if (rowToRemove) {
      await removeForeignClassification(rowToRemove).unwrap();
      setRowToRemove(null);
    }
  }, [removeForeignClassification, rowToRemove]);

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const columns: (GridColDef<ForeignClassification> | GridActionsColDef<ForeignClassification>)[] = [
      { field: 'pn', headerName: 'P/N', minWidth: 150, sortable: true, filterable: true },
      {
        field: 'buyer',
        headerName: 'Buyer',
        valueGetter: (value, row) => row.pgr?.buyer,
        valueOptions: pgrList.data?.map((pgr) => pgr.buyer),
        minWidth: 150,
        sortable: true,
        filterable: true,
      },
      {
        field: 'vendorCode',
        headerName: 'Vendor Code',
        valueGetter: (value, row) => row.vendor?.id,
        valueOptions: vendorList.data?.map((vendor) => vendor.id),
        minWidth: 200,
        sortable: true,
        filterable: true,
      },
      {
        field: 'vendor',
        headerName: 'Vendor Name',
        valueGetter: (value, row) => row.vendor?.name,
        valueOptions: vendorList.data?.map((vendor) => vendor.name),
        minWidth: 200,
        sortable: true,
        filterable: true,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: 'Vendor country',
        valueGetter: (value, row) => row.vendor.country,
        minWidth: 150,
        sortable: false,
        filterable: false,
      },
      { field: 'vendorPn', headerName: 'Vendor P/N', minWidth: 150, sortable: true, filterable: true },
      { field: 'htsn', headerName: 'Vendor HTSN', minWidth: 150, sortable: true, filterable: true },
      { field: 'manufacturer', headerName: 'Manufacturer', minWidth: 250, sortable: true, filterable: true },
      { ...countryColumn, headerName: 'Country of Origin', minWidth: 150, sortable: true, filterable: true },
      {
        ...eccnColumn,
        valueGetter: (value, row) => row.eccn.id,
        minWidth: 200,
        sortable: true,
        filterable: true,
      },
      {
        ...isMilitaryColumn,
        valueGetter: (value, row) => row.eccn.isMilitary,
        filterable: true,
        sortable: true,
      },
      { field: 'notes', headerName: 'Note', minWidth: 300, flex: 1, filterable: false, sortable: false },
      {
        field: 'lastUpdate',
        headerName: 'Ultima modifica',
        valueFormatter: ({ value }) => {
          const date = new Date(value as string);
          if (isValid(date)) {
            return format(date, 'Pp', { locale: it });
          }
          return value;
        },
        minWidth: 180,
        filterable: false,
        sortable: true,
      },
    ];
    if (isAdmin || !foreignClassificationSection.role || hasRole(foreignClassificationSection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Azioni',
        renderHeader: () => <ForeignClassificationAddButton />,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={handleDeleteClick(params)}
          />,
        ],
      });
    }
    return columns;
  }, [pgrList.data, vendorList.data, countryColumn, eccnColumn, isAdmin, hasRole, handleEditClick, handleDeleteClick]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={foreignClassificationList.isLoading}
        error={foreignClassificationList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        {updateError && (
          <Dialog open={true} onClose={() => setUpdateError(null)}>
            <ErrorAlert error={updateError} />
          </Dialog>
        )}
        <DataGridWrapper>
          <DataGrid
            rows={foreignClassificationList.data?.data ?? []}
            columns={columns}
            sessionStorageId="ForeignClassificationListPageDataGrid"
            loading={foreignClassificationList.isFetching}
            pagination
            paginationMode="server"
            rowCount={foreignClassificationList.data?.total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            pinnedColumns={pinnedColumns}
          />
        </DataGridWrapper>
        {rowToRemove && (
          <DeleteConfirmDialog
            title="Vuoi davvero eliminare la classificazione estera selezionata?"
            confirmText="confermo"
            open={Boolean(rowToRemove)}
            inProgress={removeForeignClassificationStatus.isLoading}
            error={removeForeignClassificationStatus.error}
            onConfirm={() => handleDeleteConfirm()}
            onClose={() => setRowToRemove(null)}
          />
        )}
        {rowToEdit && (
          <ForeignClassificationEditDialog
            open={Boolean(rowToEdit)}
            value={rowToEdit}
            onClose={() => setRowToEdit(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
