import { DataGrid, DataGridProps, ErrorAlert, usePagination } from '@top-solution/microtecnica-mui';
import { useReadPurchaseGroupListQuery } from '../../services/purchaseGroupApi';

const columns = [
  { field: 'id', headerName: 'Code', width: 100 },
  { field: 'buyer', headerName: 'Buyer', width: 150 },
  { field: 'email', headerName: 'Email', width: 300 },
];

type PurchaseGroupDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
>;

export function PurchaseGroupDataGrid(props: PurchaseGroupDataGridProps): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const { data, error, isFetching } = useReadPurchaseGroupListQuery();

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <DataGrid
      sessionStorageId="PurchaseGroupListPageDataGrid"
      rows={data ?? []}
      columns={columns}
      loading={isFetching}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      {...props}
    />
  );
}
