import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { AddIcon } from '../../../components/Icons';
import { VendorEditDialog } from './VendorEditDialog';

type VendorAddButtonProps = Omit<IconButtonProps, 'children' | 'onClick'> & {
  title?: TooltipProps['title'];
};

export function VendorAddButton(props: VendorAddButtonProps): JSX.Element {
  const { title, ...buttonProps } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={title ?? 'Nuovo Vendor'}>
        <IconButton onClick={() => setOpen(true)} {...buttonProps}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <VendorEditDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}
