import { useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { useThirdPartyTypeColDef } from '../../../components/DataGrid/useThirdPartyTypeColDef';
import { DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { ThirdParty } from '../../../entities/ThirdParty';
import { UserRole } from '../../../entities/User';
import { useReadThirdPartyListQuery, useRemoveThirdPartyMutation } from '../../../services/thirdPartyApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, thirdPartySection } from '../../sections';
import { ThirdPartyAddButton } from './ThirdPartyAddButton';
import { ThirdPartyEditDialog } from './ThirdPartyEditDialog';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: thirdPartySection.title }];
const pinnedColumns = { right: ['actions'] };

export function ThirdPartyListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const thirdPartyTypeColumn = useThirdPartyTypeColDef();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const searchParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readThirdPartyList = useReadThirdPartyListQuery(searchParams);
  const [thirdPartyToRemove, setThirdPartyToRemove] = useState<ThirdParty | null>(null);
  const [thirdPartyToEdit, setThirdPartyToEdit] = useState<ThirdParty | null>(null);
  const [removeThirdParty, removeThirdPartyStatus] = useRemoveThirdPartyMutation();

  async function handleDeleteVendorConfirm() {
    if (thirdPartyToRemove) {
      await removeThirdParty(thirdPartyToRemove).unwrap();
      setThirdPartyToRemove(null);
    }
  }

  const columns = useMemo<(GridColDef<ThirdParty> | GridActionsColDef<ThirdParty>)[]>(() => {
    const columns: (GridColDef | GridActionsColDef)[] = [
      { field: 'id', headerName: 'Code', width: 80 },
      { field: 'name', headerName: 'Nome', width: 250 },
      {
        ...thirdPartyTypeColumn,
        field: 'typeName',
        width: 200,
        valueGetter: (value, row) => row.type.id,
      },
      { field: 'address', headerName: 'Indirizzo', width: 450, filterable: false, sortable: false },
      { field: 'notes', headerName: 'Note', width: 400, filterable: false, sortable: false },
    ];
    if (isAdmin || !thirdPartySection.role || hasRole(thirdPartySection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Azioni',
        renderHeader: () => <ThirdPartyAddButton />,
        getActions: ({ row }: GridRowParams<ThirdParty>) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            key="edit"
            label="Modifica"
            onClick={() => setThirdPartyToEdit(row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={() => setThirdPartyToRemove(row)}
          />,
        ],
      });
    }
    return columns;
  }, [hasRole, isAdmin, thirdPartyTypeColumn]);

  function handleSortModelChange(sortModel: GridSortModel) {
    setSortModel(sortModel);
    setPaginationModel({ page: 0 });
  }
  function handleFilterModelChange(filterModel: GridFilterModel) {
    setFilterModel(filterModel);
    setPaginationModel({ page: 0 });
  }

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readThirdPartyList.isLoading}
        error={readThirdPartyList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={readThirdPartyList.data?.data ?? []}
            columns={columns}
            sessionStorageId="ThirdPartyListPageDataGrid"
            loading={readThirdPartyList.isFetching}
            pagination
            paginationMode="server"
            rowCount={readThirdPartyList.data?.total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            pinnedColumns={pinnedColumns}
          />
        </DataGridWrapper>
        {thirdPartyToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare la Terza Parte "${thirdPartyToRemove.name}"?`}
            confirmText="confermo"
            open={Boolean(thirdPartyToRemove)}
            inProgress={removeThirdPartyStatus.isLoading}
            error={removeThirdPartyStatus.error}
            onConfirm={() => handleDeleteVendorConfirm()}
            onClose={() => {
              setThirdPartyToRemove(null);
              removeThirdPartyStatus.reset();
            }}
          />
        )}
        {thirdPartyToEdit && (
          <ThirdPartyEditDialog
            open={Boolean(thirdPartyToEdit)}
            initialValues={{ ...thirdPartyToEdit, type: thirdPartyToEdit.type.id }}
            onClose={() => setThirdPartyToEdit(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
