import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  muiFiltersToPagedRequestFilters,
  useCountryGridColDef,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import {
  GridColDef,
  GridSortModel,
  GridFilterModel,
  GridLogicOperator,
  GridActionsCellItem,
  MuiEvent,
  GridRowParams,
  GridActionsColDef,
} from '@mui/x-data-grid-premium';
import { DeleteIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { Supplier, SupplierSource } from '../../../entities/Supplier';
import { UserRole } from '../../../entities/User';
import { useReadPurchaseGroupListQuery } from '../../../services/purchaseGroupApi';
import { useReadSupplierListQuery, useRemoveSupplierMutation } from '../../../services/suppliersApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, supplierSection } from '../../sections';
import { SupplierAddButton } from './SupplierAddButton';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: supplierSection.title }];
const pinnedColumns = { right: ['actions'] };

export function SupplierListPage(): JSX.Element {
  const { hasRole, isAdmin } = useAuth();
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const searchParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readSupplierList = useReadSupplierListQuery(searchParams);

  const pgrList = useReadPurchaseGroupListQuery();

  const [supplierToRemove, setSupplierToRemove] = useState<Supplier | null>(null);
  const [removeSupplier, removeSupplierStatus] = useRemoveSupplierMutation();

  const handleDeleteClick = useCallback(
    (supplier: Supplier) => (event: MuiEvent<React.MouseEvent>) => {
      event.stopPropagation();
      setSupplierToRemove(supplier);
    },
    [],
  );

  const handleDeleteConfirm = useCallback(async () => {
    if (supplierToRemove) {
      await removeSupplier(supplierToRemove).unwrap();
      setSupplierToRemove(null);
    }
  }, [removeSupplier, supplierToRemove]);

  const columns = useMemo(() => {
    const columns: (GridColDef<Supplier> | GridActionsColDef<Supplier>)[] = [
      { field: 'pn', headerName: 'P/N', width: 120, filterable: true, sortable: true },
      {
        field: 'vendorCode',
        headerName: 'Vendor Code',
        valueGetter: (value, row) => row.vendor.id,
        width: 110,
        filterable: true,
        sortable: true,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor Name',
        valueGetter: (value, row) => row.vendor.name,
        width: 280,
        filterable: true,
        sortable: true,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: 'Vendor Country',
        valueGetter: (value, row) => row.vendor.country,
        width: 150,
        filterable: true,
        sortable: true,
      },
      {
        field: 'vendorSource',
        headerName: 'Vendor Source',
        type: 'singleSelect',
        valueOptions: Object.entries(SupplierSource).map((entry) => entry[1]),
        width: 120,
        filterable: true,
        sortable: true,
      },
      {
        field: 'pgr',
        headerName: 'Purchase Group',
        type: 'singleSelect',
        valueGetter: (value, row) => row.pgr.id,
        valueOptions: pgrList.data?.map((pgr) => pgr.id),
        width: 130,
        filterable: true,
        sortable: true,
      },
      {
        field: 'pgrBuyer',
        headerName: 'Buyer',
        valueGetter: (value, row) => row.pgr.buyer,
        width: 120,
        filterable: true,
        sortable: true,
      },
      {
        field: 'vendorDa',
        headerName: 'DA',
        valueGetter: (value, row) => row.vendor.da,
        width: 120,
        filterable: true,
        sortable: true,
      },
      {
        field: 'vendorLead',
        headerName: 'Lead',
        valueGetter: (value, row) => row.vendor.lead,
        width: 120,
        filterable: true,
        sortable: true,
      },
      {
        field: 'pgrSource',
        headerName: 'PGr Source',
        type: 'singleSelect',
        valueOptions: Object.entries(SupplierSource).map((entry) => entry[1]),
        width: 120,
        filterable: true,
        sortable: true,
      },
    ];
    if (isAdmin || !supplierSection.role || hasRole(supplierSection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        width: 60,
        renderHeader: () => <SupplierAddButton />,
        getActions: ({ row }: GridRowParams<Supplier>) => {
          if (row.pgrSource === SupplierSource.Manual || row.vendorSource === SupplierSource.Manual) {
            return [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                key="delete"
                label="Elimina"
                onClick={handleDeleteClick(row)}
              />,
            ];
          }
          return [];
        },
      });
    }
    return columns;
  }, [countryColumn, handleDeleteClick, hasRole, isAdmin, pgrList.data]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readSupplierList.isLoading}
        error={readSupplierList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={readSupplierList.data?.data ?? []}
            rowCount={readSupplierList.data?.total ?? 0}
            getRowId={(supplier) => `${supplier.pn}:${supplier.vendor.id}:${supplier.pgr.id}`}
            columns={columns}
            pinnedColumns={pinnedColumns}
            sessionStorageId="SupplierListPageDataGrid"
            loading={readSupplierList.isFetching}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
          />
        </DataGridWrapper>
        {supplierToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare l'associazione selezionata?`}
            description={
              <dl>
                <dt>P/N</dt>
                <dd>{supplierToRemove.pn}</dd>
                <dt>Vendor</dt>
                <dd>
                  {supplierToRemove.vendor.name} ({supplierToRemove.vendor.id})
                </dd>
                <dt>Buyer</dt>
                <dd>
                  {supplierToRemove.pgr.buyer} ({supplierToRemove.pgr.id})
                </dd>
              </dl>
            }
            confirmText="confermo"
            open={Boolean(supplierToRemove)}
            inProgress={removeSupplierStatus.isLoading}
            error={removeSupplierStatus.error}
            onConfirm={() => handleDeleteConfirm()}
            onClose={() => setSupplierToRemove(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
