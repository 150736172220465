import { useMemo } from 'react';
import { PagedRequestParams } from '@top-solution/microtecnica-utils';
import { DataGridPremium, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { ThirdParty } from '../../entities/ThirdParty';
import { useReadThirdPartyListQuery } from '../../services/thirdPartyApi';
import { useThirdPartyTypeColDef } from '../DataGrid/useThirdPartyTypeColDef';

const readThirdPartyListQueryParams: PagedRequestParams = {
  offset: 0,
  limit: 10 ** 7,
  sort: ['name'],
};

type ThirdPartyDataGridProps = Omit<DataGridPremiumProps<ThirdParty>, 'rows' | 'columns'>;

export function ThirdPartyDataGrid(props: ThirdPartyDataGridProps): JSX.Element {
  const { loading, onRowSelectionModelChange, ...dataGridProps } = props;
  const thirdPartyList = useReadThirdPartyListQuery(readThirdPartyListQueryParams);
  const thirdPartyTypeColumn = useThirdPartyTypeColDef();

  const columns = useMemo<GridColDef<ThirdParty>[]>(
    () => [
      { field: 'name', headerName: 'Nome', width: 250 },
      {
        ...thirdPartyTypeColumn,
        field: 'typeName',
        width: 200,
        valueGetter: (value, row) => row.type.id,
      },
      { field: 'address', headerName: 'Indirizzo', width: 450, filterable: false, sortable: false },
      { field: 'notes', headerName: 'Note', width: 400, filterable: false, sortable: false },
    ],
    [thirdPartyTypeColumn],
  );

  return (
    <DataGridPremium
      columns={columns}
      rows={thirdPartyList.data?.data ?? []}
      loading={thirdPartyList.isLoading || loading}
      onRowSelectionModelChange={thirdPartyList.isLoading || loading ? undefined : onRowSelectionModelChange}
      density="compact"
      {...dataGridProps}
    />
  );
}
