import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import {
  ForeignClassification,
  ForeignClassificationAddForm,
  ForeignClassificationSchema,
} from '../entities/ForeignClassification';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/foreign-classifications';

const itemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readForeignClassificationList: builder.query<PagedResponse<ForeignClassification>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ForeignClassificationSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.FOREIGN_CLASSIFICATION, id: 'LIST' }],
    }),
    createForeignClassification: builder.mutation<void, ForeignClassificationAddForm>({
      query: ({ vendor, pgr, eccn, ...foreignClassification }) => ({
        url,
        method: 'POST',
        body: { eccnId: eccn.id, vendorId: vendor.id, pgrId: pgr.id, ...foreignClassification },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.FOREIGN_CLASSIFICATION, id: 'LIST' }],
    }),
    updateForeignClassification: builder.mutation<void, ForeignClassification>({
      query: ({ id, vendor, pgr, eccn, ...foreignClassification }) => ({
        url: `${url}/${id}`,
        method: 'PUT',
        body: { eccnId: eccn.id, vendorId: vendor.id, pgrId: pgr.id, ...foreignClassification },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.FOREIGN_CLASSIFICATION, id: 'LIST' }],
    }),
    removeForeignClassification: builder.mutation<void, ForeignClassification>({
      query: ({ id }) => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.FOREIGN_CLASSIFICATION, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadForeignClassificationListQuery,
  useCreateForeignClassificationMutation,
  useUpdateForeignClassificationMutation,
  useRemoveForeignClassificationMutation,
} = itemApi;
