import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { Customer, CustomerSchema } from '../entities/Customer';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/customers';

const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCustomerList: builder.query<PagedResponse<Customer>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(CustomerSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.CUSTOMER, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadCustomerListQuery, useLazyReadCustomerListQuery } = customerApi;
