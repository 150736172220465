import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  muiFiltersToPagedRequestFilters,
  useCountryGridColDef,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { GridColDef, GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-premium';
import { rowIdSeparator, useUnitOfMeasureColDef, qtyColumn, stringDateColumn } from '../../../components/DataGrid';
import { Layout } from '../../../components/Layout';
import { PurchaseOrder } from '../../../entities/PurchaseOrder';
import { useReadPurchaseOrderListQuery } from '../../../services/purchaseOrderApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, purchaseOrderSection } from '../../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: purchaseOrderSection.title }];

export function PurchaseOrderListPage(): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const uomColumn = useUnitOfMeasureColDef();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'createDate', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const readPurchaseOrderListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: (paginationModel.pageSize as number) * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel],
  );
  const purchaseOrderList = useReadPurchaseOrderListQuery(readPurchaseOrderListParams, {
    skip: paginationModel.pageSize === undefined,
  });

  const columns = useMemo<GridColDef<PurchaseOrder>[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 300 },
      { field: 'purchaseDoc', headerName: 'PO', width: 150 },
      {
        ...stringDateColumn,
        field: 'createDate',
        headerName: 'Data creazione',
        width: 150,
      },
      {
        ...stringDateColumn,
        field: 'deliveryDate',
        headerName: 'Data consegna',
        width: 150,
      },
      qtyColumn,
      uomColumn,
      {
        field: 'vendorId',
        headerName: 'Vendor code',
        valueGetter: (value, row) => row.vendor?.id,
        width: 100,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor',
        valueGetter: (value, row) => row.vendor?.name,
        width: 300,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: 'Nazione Vendor',
        valueGetter: (value, row) => row.vendor?.country,
        width: 200,
      },
    ],
    [countryColumn, uomColumn],
  );

  const rows = useMemo(
    () =>
      purchaseOrderList.data?.data.map((purchaseOrder) => ({
        id: `${purchaseOrder.pn}${rowIdSeparator}${purchaseOrder.purchaseDoc}`,
        ...purchaseOrder,
      })) || [],
    [purchaseOrderList.data?.data],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters error={purchaseOrderList.error} sx={{ p: 1 }}>
        <DataGridWrapper>
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            sessionStorageId="EndItemListPageDataGrid"
            loading={purchaseOrderList.isFetching}
            pagination
            paginationMode="server"
            rowCount={purchaseOrderList.data?.total || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
