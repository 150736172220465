import { forwardRef, useCallback } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { PurchaseGroup } from '../../entities/PurchaseGroup';
import { useReadPurchaseGroupListQuery } from '../../services/purchaseGroupApi';

type PurchaseGroupAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<PurchaseGroup, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required'>;

function PurchaseGroupAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: PurchaseGroupAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { value, label, error, helperText, variant, required, ...autocompleteProps } = props;
  const { data, isLoading, error: loadingError } = useReadPurchaseGroupListQuery();

  const getOptionLabel = useCallback(
    (option: PurchaseGroup | string) => {
      if (typeof option === 'string') {
        return option;
      }
      if (autocompleteProps.getOptionLabel) {
        return autocompleteProps.getOptionLabel(option);
      }
      return `${option.id} – ${option.buyer}`;
    },
    [autocompleteProps],
  );

  return (
    <Autocomplete
      value={value}
      options={data ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputRef={ref}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
        />
      )}
      clearOnEscape
      loading={isLoading}
      {...autocompleteProps}
    />
  );
}

export default forwardRef(PurchaseGroupAutocomplete) as typeof PurchaseGroupAutocomplete;
