import { useContext, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import equal from 'fast-deep-equal';
import { z } from 'zod';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { EUSEditModel, EUSEditSchema, EUSFieldDescriptionlMap, EUSFieldLabelMap } from '../../../entities/EUS';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

export const EUSNotesSchema = EUSEditSchema.pick({
  notes: true,
});

export type EUSNotes = z.infer<typeof EUSNotesSchema>;

export function EUSEditNotesForm(): JSX.Element {
  const { data, defaultValues, handleBack, handleNext, handleSave } = useContext(EUSFormContext);
  const {
    control,
    handleSubmit,

    reset,
    formState: { isDirty },
  } = useForm<EUSNotes>({
    defaultValues: {
      notes: data.notes ?? '',
    },
    resolver: zodResolver(EUSNotesSchema),
  });

  const onSubmit = useMemo(
    () =>
      ({ notes }: EUSNotes, callback: (editModel: EUSEditModel) => void) => {
        callback({
          ...data,
          notes: notes || null,
        });
      },
    [data],
  );

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, handleNext))}>
      <EUSFormFieldsWrapper sx={{ display: 'flex', paddingY: 2 }}>
        <Controller
          name="notes"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              multiline
              label={EUSFieldLabelMap[field.name]}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message ?? EUSFieldDescriptionlMap[field.name]}
              sx={{
                display: 'flex',
                '.MuiInputBase-multiline': { flex: '1 1 0%', alignItems: 'flex-start' },
              }}
              {...field}
            />
          )}
        />
      </EUSFormFieldsWrapper>
      <EUSFormButtonsWrapper
        onSave={handleSubmit((data) => {
          onSubmit(data, handleSave);
          reset(data);
        })}
        isDirty={!equal(defaultValues, data) || isDirty}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog onSaveAndExit={handleSubmit((data) => onSubmit(data, handleSave))} />
    </form>
  );
}
