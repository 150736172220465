import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestFilter,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { RecipientAddForm } from '../entities/Recipient';
import { SoldPn, SoldPnIdentifier, SoldPnSchema, SoldPnSource } from '../entities/SoldPn';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/sold-pn';

const soldPnApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSoldPnList: builder.query<PagedResponse<SoldPn>, PagedRequestParams>({
      query: (params) => {
        const filters = new Array<PagedRequestFilter>();
        if (params.filters) {
          params.filters.forEach((filter) => {
            if (filter.field === 'source') {
              switch (filter.value) {
                case SoldPnSource.SOS:
                  filters.push({ ...filter, value: 'true', field: 'fromSos' });
                  break;
                case SoldPnSource.MRO:
                  filters.push({ ...filter, value: 'true', field: 'fromMro' });
                  break;
                case SoldPnSource.PIR:
                  filters.push({ ...filter, value: 'true', field: 'fromPir' });
                  break;
                default:
                  filters.push({ ...filter, value: 'false', field: 'fromSos' });
                  filters.push({ ...filter, value: 'false', field: 'fromMro' });
                  filters.push({ ...filter, value: 'false', field: 'fromPir' });
                  break;
              }
            } else {
              filters.push(filter);
            }
          });
        }
        return { url, params: formatQueryParams({ ...params, filters }) };
      },
      transformResponse: (data) => createPagedResponseSchema(SoldPnSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.SOLDPN, id: 'LIST' }],
    }),
    createSoldPn: builder.mutation<void, RecipientAddForm>({
      query: ({ pn, programId, customer, shipTo }) => ({
        url,
        method: 'POST',
        body: { pn, programId, customerId: customer.id, shipToId: shipTo.id },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SOLDPN, id: 'LIST' }],
    }),
    removeSoldPn: builder.mutation<void, SoldPnIdentifier>({
      query: (params) => ({
        url,
        method: 'DELETE',
        params: formatQueryParams(params),
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SOLDPN, id: 'LIST' }],
    }),
    sendSoldPnEmail: builder.mutation<void, { pn: string; enditem: string; programId: string }>({
      query: (body) => ({ url: `${url}/email`, method: 'POST', body }),
    }),
  }),
  overrideExisting: false,
});

export const { useReadSoldPnListQuery, useCreateSoldPnMutation, useRemoveSoldPnMutation, useSendSoldPnEmailMutation } =
  soldPnApi;
