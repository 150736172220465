import {
  createPagedResponseSchema,
  formatQueryParams,
  Item,
  ItemDetails,
  ItemDetailsSchema,
  ItemSchema,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { api } from './baseApi';

const url = 'v1/items';

const itemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchItem: builder.query<PagedResponse<Item>, PagedRequestParams & { isEnditem?: boolean }>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ItemSchema).parse(data),
    }),
    readItemDetails: builder.query<ItemDetails, string>({
      query: (pn) => `${url}/${encodeURIComponent(pn)}`,
      transformResponse: (data) => ItemDetailsSchema.parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useSearchItemQuery, useLazySearchItemQuery, useReadItemDetailsQuery } = itemApi;
