import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { Program, FilterOperator } from '@top-solution/microtecnica-utils';
import equal from 'fast-deep-equal';
import Button from '@mui/material/Button';
import { DataGridPremium, DataGridPremiumProps, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { EndUser, EndUserType } from '../../../entities/EndUser';
import { EUSEditModel } from '../../../entities/EUS';
import { useReadEndUserListQuery } from '../../../services/endUserApi';
import { isAutoGeneratedRow, selectionModelsEquals } from '../../DataGrid';
import { useEndUserListGridColumns } from '../../EndUser';
import { EUSCancelConfirmDialog } from './EUSCancelConfirmDialog';
import { EUSFormButtonsWrapper, EUSFormFieldsWrapper } from './EUSFormComponents';
import { EUSFormContext } from './EUSFormContext';

const initialState: DataGridPremiumProps['initialState'] = {
  rowGrouping: {
    model: ['programId'],
  },
  columns: {
    columnVisibilityModel: {
      programId: false,
    },
  },
};

export function EUSEditEnduserForm(): JSX.Element {
  const { data, handleBack, handleNext, handleSave } = useContext(EUSFormContext);

  const readEndUserListParams = useMemo(() => {
    if (data.pnList) {
      const dedupedPrograms = new Set<Program['id']>();

      data.pnList.forEach((item) =>
        item.customerList.forEach((customer) => {
          if (customer.programId) {
            dedupedPrograms.add(customer.programId);
          }
        }),
      );
      return {
        offset: 0,
        limit: 10 ** 7,
        filters: [{ field: 'programId', value: Array.from(dedupedPrograms).join(','), operator: FilterOperator.in }],
      };
    }
    return skipToken;
  }, [data.pnList]);

  const readEndUserList = useReadEndUserListQuery(readEndUserListParams);

  const initialSelectionModel = useRef<GridRowSelectionModel>(data.enduserList.map((item) => item.enduserId) ?? []);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(initialSelectionModel.current);
  const [updatedRows, setUpdatedRows] = useState(new Map<EndUser['id'], EndUser>());

  const eusColumns = useEndUserListGridColumns();
  const columns = useMemo(
    () => [
      eusColumns[0],
      eusColumns[1],
      eusColumns[2],
      {
        ...eusColumns[3],
        editable: true,
        valueGetter: (value) => value ?? null,
        cellClassName: ({ value }) => (value === null ? 'empty' : ''),
      },
      eusColumns[4],
      eusColumns[5],
    ],
    [eusColumns],
  );

  const rows = useMemo(() => {
    if (readEndUserList.data?.data) {
      const previousTypeByEnduserId = data.enduserList.reduce(
        (map, { enduserId, enduserType }) => map.set(enduserId, enduserType),
        new Map<EndUser['id'], EndUserType | null>(),
      );
      return readEndUserList.data.data.map((enduser) => ({
        ...enduser,
        type: previousTypeByEnduserId.get(enduser.id) ?? enduser.type ?? undefined,
      }));
    }
    return [];
  }, [data.enduserList, readEndUserList.data]);

  const processRowUpdate = useCallback(
    async (newRow: EndUser) => {
      // new Map() needed for change detection
      setUpdatedRows(new Map(updatedRows.set(newRow.id, newRow)));
      return newRow;
    },
    [updatedRows],
  );

  const onSubmit = useCallback(
    (callback: (data: EUSEditModel) => void, event?: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      return callback({
        ...data,
        enduserList: selectionModel.map((id) => ({
          enduserId: id as EndUser['id'],
          enduserType: updatedRows.get(id as EndUser['id'])?.type ?? rows.find((row) => row.id === id)?.type ?? null,
        })),
      });
    },
    [data, rows, selectionModel, updatedRows],
  );

  const selectionHasChanged = useMemo(() => {
    if (selectionModelsEquals(initialSelectionModel.current, selectionModel)) {
      const changedRow = rows.find((row) => updatedRows.has(row.id) && !equal(row, updatedRows.get(row.id)));
      return changedRow !== undefined;
    }
    return true;
  }, [rows, selectionModel, updatedRows]);

  return (
    <form onSubmit={(event) => onSubmit(handleNext, event)}>
      <EUSFormFieldsWrapper>
        {readEndUserList.error ? (
          <ErrorAlert error={readEndUserList.error} />
        ) : (
          <DataGridPremium
            columns={columns}
            rows={rows}
            loading={readEndUserList.isFetching}
            density="compact"
            hideFooter
            processRowUpdate={processRowUpdate}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(rowSelectionModel) => {
              if (!readEndUserList.isFetching) {
                setSelectionModel(rowSelectionModel);
              }
            }}
            isRowSelectable={({ id }) => !isAutoGeneratedRow(id)}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnPinning
            defaultGroupingExpansionDepth={-1}
            groupingColDef={{
              headerName: '',
              width: 200,
              hideDescendantCount: true,
            }}
            initialState={initialState}
            disableColumnMenu
          />
        )}
      </EUSFormFieldsWrapper>
      <EUSFormButtonsWrapper
        onSave={() => {
          onSubmit(handleSave);
          initialSelectionModel.current = selectionModel;
        }}
        isDirty={selectionHasChanged}
      >
        <Button color="secondary" variant="outlined" onClick={handleBack}>
          Indietro
        </Button>
        <Button type="submit" variant="outlined" color="primary" disabled={readEndUserList.isFetching}>
          Avanti
        </Button>
      </EUSFormButtonsWrapper>
      <EUSCancelConfirmDialog onSaveAndExit={() => onSubmit(handleSave)} />
    </form>
  );
}
