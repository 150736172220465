import { ExportLicence, ExportLicenceSchema } from '../entities/ExportLicence';
import { api } from './baseApi';

const url = 'v1/export-licences';

const exportLicenceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readExportLicenceList: builder.query<ExportLicence[], void>({
      query: () => url,
      transformResponse: (data) => ExportLicenceSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadExportLicenceListQuery } = exportLicenceApi;
