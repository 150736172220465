import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DataGrid,
  DataGridWrapper,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { userColumn } from '../../../components/DataGrid';
import { EyeIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { HistoryActionLabel, HistoryRecord } from '../../../entities/History';
import { UserRole } from '../../../entities/User';
import { useReadAuditEntityListQuery, useReadAuditHistoryQuery } from '../../../services/historyApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, historySection } from '../../sections';
import { HistoryDiffDialog } from './HistoryDiffDialog';

const pinnedColumns = { right: ['_actions'] };

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: historySection.title }];

export function HistoryListPage(): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [selectedRow, setSelectedRow] = useState<null | HistoryRecord>(null);
  const [searchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'timestamp', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
    const items = new Array<GridFilterItem>();
    const id = searchParams.get('id');
    if (id) {
      items.push({ field: 'objectId', value: id, operator: 'equals' });
    }
    const entity = searchParams.get('entity');
    if (entity) {
      items.push({ field: 'entityId', value: entity, operator: 'is' });
    }
    const timestamp = searchParams.get('timestamp');
    if (timestamp) {
      items.push({ field: 'timestamp', value: timestamp, operator: '>=' });
      items.push({ field: 'timestamp', value: timestamp, operator: '<=' });
    }
    return { items, logicOperator: GridLogicOperator.And };
  });
  const readHistoryListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readHistoryList = useReadAuditHistoryQuery(readHistoryListParams);

  const readAuditEntityList = useReadAuditEntityListQuery();

  const columns = useMemo<GridColDef<HistoryRecord>[]>(
    () => [
      {
        field: 'timestamp',
        headerName: 'Data',
        type: 'date',
        valueGetter: (value) => (value ? new Date(value) : null),
        valueFormatter: (value: Date) =>
          value.toLocaleDateString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
        width: 160,
      },
      { ...userColumn, field: 'user', width: 150 },
      {
        field: 'entityId',
        headerName: 'Entità',
        type: 'singleSelect',
        width: 200,
        valueOptions: readAuditEntityList.data?.map(({ id, name }) => ({ value: id, label: name })),
        valueGetter: (value, row) => row.entity.id,
      },
      {
        field: 'objectId',
        headerName: 'Code',
        width: 80,
      },
      {
        field: 'action',
        headerName: 'Azione',
        type: 'singleSelect',
        valueOptions: Object.entries(HistoryActionLabel).map(([value, label]) => ({ value, label })),
        width: 150,
      },
      {
        field: '_actions',
        type: 'actions',
        headerName: 'Dettaglio',
        getActions: ({ row }) => [
          <GridActionsCellItem
            key="details"
            label="Mostra valori"
            icon={<EyeIcon />}
            onClick={() => setSelectedRow(row)}
          />,
        ],
      },
    ],
    [readAuditEntityList.data],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readHistoryList.isLoading}
        error={readHistoryList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            pinnedColumns={pinnedColumns}
            columns={columns}
            rows={readHistoryList.data?.data ?? []}
            rowCount={readHistoryList.data?.total ?? 0}
            loading={readHistoryList.isFetching}
            sessionStorageId="HistoryListPageDataGrid"
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
          />
        </DataGridWrapper>
        {selectedRow && (
          <HistoryDiffDialog open={Boolean(selectedRow)} onClose={() => setSelectedRow(null)} data={selectedRow} />
        )}
      </Layout>
    </AuthGuard>
  );
}
