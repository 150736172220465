import { useCallback, useMemo, forwardRef } from 'react';
import { useReadProgramsQuery, FilterOperator, ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { SerniCategory } from '../../entities/SerniCategory';
import { useReadSerniCategoryListQuery } from '../../services/serniCategoryApi';

export type SerniProgramSelectProps = Omit<TextFieldProps, 'select'> & {
  pn: string | null;
  value: SerniCategory | null;
  onChange: (value: SerniCategory | null, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function SerniProgramSelect(props: SerniProgramSelectProps, ref: React.Ref<unknown>) {
  const { pn, onChange, value, disabled, error, helperText, ...textFieldProps } = props;
  const readSerniCategoryListParams = useMemo(
    () => ({
      limit: 10,
      offset: 0,
      sort: ['pn'],
      filters: [{ field: 'pn', operator: FilterOperator.equals, value: pn || '' }],
    }),
    [pn],
  );
  const serniCategoryList = useReadSerniCategoryListQuery(readSerniCategoryListParams, { skip: !pn });
  const programs = useReadProgramsQuery();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const serniCode =
        serniCategoryList.data?.data?.find(
          (serniCategory) => serniCategory.pn === pn && serniCategory.programId === event.target.value,
        ) || null;
      onChange(serniCode, event);
    },
    [serniCategoryList.data, onChange, pn],
  );

  const apiError = serniCategoryList.error as ServerError | undefined;

  return (
    <>
      <TextField
        select
        SelectProps={{ ref }}
        value={value?.programId || ''}
        onChange={handleChange}
        disabled={
          disabled ||
          !pn ||
          serniCategoryList?.data?.data.length === 0 ||
          serniCategoryList.isError ||
          serniCategoryList.isFetching
        }
        error={
          error || Boolean(apiError) || (serniCategoryList?.data?.data && serniCategoryList.data.data.length === 0)
        }
        helperText={
          helperText ||
          apiError?.data.message ||
          (serniCategoryList?.data?.data.length === 0 && `Nessun SERNI trovato per ${pn}`) ||
          ' '
        }
        {...textFieldProps}
      >
        {serniCategoryList.data?.data?.map(({ pn, programId }) => (
          <MenuItem key={pn} value={programId}>
            {programs.data?.map[programId]?.name || programId}
          </MenuItem>
        )) || (
          <MenuItem value="" disabled>
            {' '}
          </MenuItem>
        )}
      </TextField>
    </>
  );
}

export default forwardRef(SerniProgramSelect);
