import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { italianExportLicense } from '../sections';
import { ExportLicenceListDataGrid } from './ExportLicenceListDataGrid';

const breadcrumbs = [{ title: italianExportLicense.title }];

export function ExportLicenceListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper sx={{ '.AlertExpired': { bgcolor: 'error.light' } }}>
          <ExportLicenceListDataGrid />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
