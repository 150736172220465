import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import { adminSection, userSection } from '../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: userSection.title }];

export function AppUserListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === userSection.role}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <AppUserListGrid appId={import.meta.env.VITE_APP_ID as string} />
      </Layout>
    </AuthGuard>
  );
}
