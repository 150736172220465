import { EUSTurnback, EUSTurnbackSchema } from '../entities/EUS';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/eus-attributes/turnback-types';

const eusTurnbackApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEUSTurnbackList: builder.query<EUSTurnback[], void>({
      query: () => url,
      transformResponse: (data) => EUSTurnbackSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.EUS_TURNBACK, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadEUSTurnbackListQuery } = eusTurnbackApi;
