import { memo, useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AlertIcon } from '../Icons';
import { ThirdPartyWarningDialog } from './ThirdPartyWarningDialog';

type ThirdPartyWarningButtonProps = Omit<IconButtonProps, 'onClick'>;

function ThirdPartyWarningButtonComponent(props: ThirdPartyWarningButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Mostra Terze Parti">
        <IconButton {...props} onClick={() => setDialogOpen(true)}>
          <AlertIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && <ThirdPartyWarningDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />}
    </>
  );
}

export const ThirdPartyWarningButton = memo(ThirdPartyWarningButtonComponent);
