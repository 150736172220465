import { EditVendorForm, Vendor, VendorSchema } from '../entities/Vendor';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/vendors';

const vendorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readVendorList: builder.query<Vendor[], void>({
      query: () => ({ url }),
      transformResponse: (data) => VendorSchema.array().parse(data),
      providesTags: [TAG_TYPES.VENDOR],
    }),
    createVendor: builder.mutation<void, EditVendorForm>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: [TAG_TYPES.VENDOR],
    }),
    updateVendor: builder.mutation<void, EditVendorForm>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}`, method: 'PUT', body }),
      invalidatesTags: [TAG_TYPES.VENDOR],
    }),
    removeVendor: builder.mutation<void, Vendor>({
      query: ({ id }) => ({ url: `${url}/${id}`, method: 'DELETE' }),
      invalidatesTags: [TAG_TYPES.VENDOR],
    }),
  }),
  overrideExisting: false,
});

export const { useReadVendorListQuery, useCreateVendorMutation, useUpdateVendorMutation, useRemoveVendorMutation } =
  vendorApi;
