import { z } from 'zod';

export const ItemMakeBuySchema = z.object({
  pn: z.string(),
  programId: z.string(),
  procType: z.string().optional(),
  isMake: z.boolean().optional(),
});

export type ItemMakeBuy = z.infer<typeof ItemMakeBuySchema>;
