import { z } from 'zod';
import { ECCNSchema } from './ECCN';
import { PurchaseGroupSchema } from './PurchaseGroup';
import { VendorSchema } from './Vendor';

export const ForeignClassificationSchema = z.object({
  id: z.string(),
  pn: z.string(),
  vendor: VendorSchema,
  pgr: PurchaseGroupSchema,
  vendorPn: z.string().min(2).max(64).or(z.literal('')),
  manufacturer: z.string().min(3).max(64).or(z.literal('')),
  country: z.string().min(2).max(3),
  eccn: ECCNSchema,
  htsn: z
    .string()
    .min(8)
    .max(10)
    .regex(/^[0-9]{8,10}$/)
    .or(z.literal('')),
  notes: z.string(),
  lastUpdate: z.string().datetime({ offset: true }),
});

export type ForeignClassification = z.infer<typeof ForeignClassificationSchema>;

export const ForeignClassificationAddFormSchema = ForeignClassificationSchema.omit({ id: true, lastUpdate: true });
export type ForeignClassificationAddForm = z.infer<typeof ForeignClassificationAddFormSchema>;
