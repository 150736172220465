import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCountryGridColDef } from '@top-solution/microtecnica-mui';
import Button from '@mui/material/Button';
import { GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { EUS, EUSFieldLabelMap, getEUSExpireDate, isEUSExpiring, maxAllowedTat } from '../../entities/EUS';
import { useReadSupplierLicenseTypeListQuery } from '../../services/eusAttributesApi';
import { stringDateColumn, useEUSTurnbackColDef } from '../DataGrid';
import { useEUSStatusColDef } from '../DataGrid/useEUSStatusColDef';

export function useEUSListDataGridColumns(): GridColDef[] {
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const statusColumn = useEUSStatusColDef();
  const turnbackColumn = useEUSTurnbackColDef();
  const readSupplierLicenseTypeList = useReadSupplierLicenseTypeListQuery();

  return useMemo<GridColDef<EUS>[]>(
    () => [
      {
        field: 'id',
        headerName: EUSFieldLabelMap.id,
        type: 'number',
        width: 60,
        renderCell: ({ value }) => (
          <Button size="small" color="primary" component={Link} to={`./${value}`}>
            {value}
          </Button>
        ),
      },
      statusColumn,
      {
        field: 'buyer',
        headerName: EUSFieldLabelMap.pgr,
        valueGetter: (value, row) => row.pgr.buyer,
        width: 90,
      },
      {
        field: 'protocolNum',
        headerName: EUSFieldLabelMap.id,
        width: 150,
      },
      {
        ...stringDateColumn,
        field: 'eusReleaseDate',
        headerName: EUSFieldLabelMap.eusReleaseDate,
        width: 150,
      },
      {
        field: 'supplierLicenseTypeId',
        headerName: EUSFieldLabelMap.supplierLicenseTypeId,
        type: 'singleSelect',
        valueOptions: readSupplierLicenseTypeList.data?.map(({ id, name }) => ({ value: id, label: name })),
        width: 120,
      },
      {
        ...stringDateColumn,
        field: 'supplierLicenseTypeValidity',
        headerName: EUSFieldLabelMap.supplierLicenseTypeValidity,
        valueGetter: (value, row) => getEUSExpireDate(row),
        cellClassName: ({ row }) => (isEUSExpiring(row) ? 'ExpirationWarning' : ''),
        width: 150,
      },
      {
        field: 'vendorCode',
        headerName: EUSFieldLabelMap.vendorCode,
        valueGetter: (value, row) => row.vendor.id,
        width: 120,
      },
      {
        field: 'vendorName',
        headerName: EUSFieldLabelMap.vendorName,
        valueGetter: (value, row) => row.vendor.name,
        width: 250,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: EUSFieldLabelMap.vendorCountry,
        valueGetter: (value, row) => row.vendor.country,
        width: 150,
      },
      {
        ...stringDateColumn,
        field: 'vendorRequestDate',
        headerName: EUSFieldLabelMap.vendorRequestDate,
        width: 200,
      },
      {
        field: 'tat',
        headerName: EUSFieldLabelMap.tat,
        type: 'number',
        width: 60,
        cellClassName: ({ value }: GridCellParams<GridRowModel, number>) =>
          typeof value === 'number' && value > maxAllowedTat ? 'ExpirationWarning' : '',
      },
      {
        ...turnbackColumn,
        width: 250,
      },
    ],
    [countryColumn, readSupplierLicenseTypeList.data, statusColumn, turnbackColumn],
  );
}
