import { createIcon, createMdiIcon } from '@top-solution/microtecnica-mui';
import {
  mdiAccount,
  mdiAlert,
  mdiCheck,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiContentDuplicate,
  mdiContentSave,
  mdiDatabaseImport,
  mdiEye,
  mdiFileExcel,
  mdiUpload,
  mdiFile,
  mdiInformationOutline,
  mdiMagnify,
  mdiOpenInNew,
  mdiPencil,
  mdiPlusCircle,
  mdiTrashCan,
  mdiDownload,
} from '@mdi/js';
import Logo from './logo.svg?react';

export const AddIcon = createMdiIcon(mdiPlusCircle);
export const AlertIcon = createMdiIcon(mdiAlert);
export const CancelIcon = createMdiIcon(mdiClose);
export const CheckIcon = createMdiIcon(mdiCheck);
export const ChevronDownIcon = createMdiIcon(mdiChevronDown);
export const ChevronLeftIcon = createMdiIcon(mdiChevronLeft);
export const ChevronRightIcon = createMdiIcon(mdiChevronRight);
export const ChevronUpIcon = createMdiIcon(mdiChevronUp);
export const DeleteIcon = createMdiIcon(mdiTrashCan);
export const DownloadIcon = createMdiIcon(mdiDownload);
export const DuplicateIcon = createMdiIcon(mdiContentDuplicate);
export const EditIcon = createMdiIcon(mdiPencil);
export const ExcelFileIcon = createMdiIcon(mdiFileExcel);
export const ExternalUrlIcon = createMdiIcon(mdiOpenInNew);
export const EyeIcon = createMdiIcon(mdiEye);
export const FileIcon = createMdiIcon(mdiFile);
export const ImportDataIcon = createMdiIcon(mdiDatabaseImport);
export const InfoIcon = createMdiIcon(mdiInformationOutline);
export const LogoIcon = createIcon(<Logo />);
export const SaveIcon = createMdiIcon(mdiContentSave);
export const SearchIcon = createMdiIcon(mdiMagnify);
export const UploadIcon = createMdiIcon(mdiUpload);
export const UserIcon = createMdiIcon(mdiAccount);
