import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../../components/Icons';
import { ThirdPartyAddForm } from '../../../entities/ThirdParty';
import { ThirdPartyEditDialog } from './ThirdPartyEditDialog';

const defaultValues = {
  type: null,
  name: '',
  address: '',
  notes: '',
} as unknown as ThirdPartyAddForm;

export function ThirdPartyAddButton(props: IconButtonProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<ThirdPartyAddForm | null>(null);

  return (
    <>
      <Tooltip title="Aggiungi Terza Parte">
        <IconButton color="primary" onClick={() => setInitialValues(defaultValues)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {initialValues && (
        <ThirdPartyEditDialog
          open={Boolean(initialValues)}
          initialValues={initialValues}
          onClose={() => setInitialValues(null)}
        />
      )}
    </>
  );
}
