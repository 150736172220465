import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EUSFieldLabelMap } from '../../entities/EUS';
import { useReadEUSTurnbackListQuery } from '../../services/turnbackApi';

export function useEUSTurnbackColDef(): GridColDef {
  const { data } = useReadEUSTurnbackListQuery();
  const valueOptions = useMemo(() => data?.map(({ id, name }) => ({ value: id, label: name })), [data]);

  return useMemo(
    () => ({
      field: 'turnbackTypeId',
      headerName: EUSFieldLabelMap.turnbackTypeId,
      type: 'singleSelect',
      valueOptions,
    }),
    [valueOptions],
  );
}
