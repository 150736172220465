import { useContext, useMemo } from 'react';
import { ZodIssueCode } from 'zod';
import Card from '@mui/material/Card';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { EUSEditSchema } from '../../../entities/EUS';
import { EUSEditCustomerForm } from './EUSEditCustomerForm';
import { EUSEditECCNForm } from './EUSEditECCNForm';
import { EUSEditEndItemForm } from './EUSEditEndItemForm';
import { EUSEditEnduserForm } from './EUSEditEnduserForm';
import { EUSEditNotesForm } from './EUSEditNotesForm';
import { EUSEditPOForm } from './EUSEditPOForm';
import { EUSEditProgramForm } from './EUSEditProgramForm';
import { EUSEditShipToForm } from './EUSEditShipToForm';
import { EUSEditThirdPartyForm } from './EUSEditThirdPartyForm';
import { EUSFormContext } from './EUSFormContext';
import { EUSInitialDataForm } from './EUSInitialDataForm';

export const EUSRecapSchema = EUSEditSchema.pick({
  turnbackTypeId: true,
  statusId: true,
  turnbackNotes: true,
}).superRefine((data, ctx) => {
  if (data.turnbackTypeId !== null && (data.turnbackNotes?.length ?? 0) < 5) {
    ctx.addIssue({
      code: ZodIssueCode.too_small,
      minimum: 5,
      inclusive: true,
      type: 'string',
      path: ['turnbackNotes'],
      message: 'Note turnback obbligatorie',
    });
  }
});

export function EUSEditForm(): JSX.Element {
  const { step } = useContext(EUSFormContext);

  const steps = useMemo(
    () => [
      {
        title: 'Dati pratica',
        render: () => <EUSInitialDataForm />,
      },
      {
        title: 'Classificazione',
        render: () => <EUSEditECCNForm />,
      },
      {
        title: 'PO',
        render: () => <EUSEditPOForm />,
      },
      {
        title: 'End Item',
        render: () => <EUSEditEndItemForm />,
      },
      {
        title: 'Programmi',
        render: () => <EUSEditProgramForm />,
      },
      {
        title: 'Customer',
        render: () => <EUSEditCustomerForm />,
      },
      {
        title: 'Ship To',
        render: () => <EUSEditShipToForm />,
      },
      {
        title: 'End user',
        render: () => <EUSEditEnduserForm />,
      },
      {
        title: 'Terze parti',
        render: () => <EUSEditThirdPartyForm />,
      },
      {
        title: 'Note',
        render: () => <EUSEditNotesForm />,
      },
    ],
    [],
  );

  return (
    <Card
      sx={{
        padding: 1,
        paddingTop: 2,
        '.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--editable.empty': {
          bgcolor: 'warning.light',
        },
      }}
    >
      <Stepper activeStep={step} alternativeLabel sx={{ margin: 0, paddingBlockEnd: 1 }}>
        {steps.map((step) => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[step]?.render()}
    </Card>
  );
}
