import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FormRow, VendorAutocomplete } from '../../../components/Form';
import { PartNumberAutocomplete } from '../../../components/Form/PartNumberAutocomplete';
import PurchaseGroupAutocomplete from '../../../components/Form/PurchaseGroupAutocomplete';
import { AddIcon, SaveIcon } from '../../../components/Icons';
import { Supplier, SupplierSchema } from '../../../entities/Supplier';
import { useCreateSupplierMutation } from '../../../services/suppliersApi';

const defaultValues: Supplier = {
  pn: '',
  vendor: null,
  pgr: null,
} as unknown as Supplier;

export function SupplierAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [create, createStatus] = useCreateSupplierMutation();
  const { handleSubmit, control, reset } = useForm<Supplier>({
    defaultValues: defaultValues,
    resolver: zodResolver(SupplierSchema),
  });

  const close = useCallback(() => {
    setDialogOpen(false);
    reset();
    createStatus.reset();
  }, [createStatus, reset]);

  const onSubmit = useCallback(
    async (data: Supplier) => {
      await create(data).unwrap();
      close();
    },
    [close, create],
  );

  return (
    <>
      <Tooltip title="Aggiungi associazione P/N, Vendor, Buyer">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle>Nuova associazione P/N, Vendor, Buyer</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <FormRow>
            <Controller
              control={control}
              name="pn"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <PartNumberAutocomplete
                  label="P/N"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="vendor"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <VendorAutocomplete
                  label="Vendor"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="pgr"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <PurchaseGroupAutocomplete
                  label="Purchase Group"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  {...field}
                />
              )}
            />
          </FormRow>
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => close()}>
            Annulla
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={createStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
