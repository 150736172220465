import { useMemo } from 'react';
import { DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { format, isValid } from 'date-fns';
import { it } from 'date-fns/locale';
import { GridColDef } from '@mui/x-data-grid-premium';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import { FileImportStatus } from '../../entities/ImportStatus';
import { UserRole } from '../../entities/User';
import { useReadImportStatusListQuery } from '../../services/importStatus';
import { adminSection, importStatusSection } from '../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: importStatusSection.title }];

const dateFormatter = (value: Date | null) => (isValid(value) ? format(value as Date, 'Pp', { locale: it }) : '–');

const columns: GridColDef[] = [
  { field: 'description', headerName: 'File', minWidth: 180 },
  { field: 'id', headerName: 'Percorso', minWidth: 300 },
  { field: 'status', headerName: 'Stato', minWidth: 150 },
  {
    field: 'lastRun',
    headerName: 'Ultima esecuzione',
    type: 'date',
    valueFormatter: dateFormatter,
    minWidth: 160,
  },
  {
    field: 'lastSuccess',
    headerName: 'Ultimo successo',
    type: 'date',
    valueFormatter: dateFormatter,
    minWidth: 160,
  },
  {
    field: 'error',
    headerName: 'Errore',
    minWidth: 300,
    flex: 1,
  },
];

export function ImportStatusListPage(): JSX.Element {
  const readImportStatusList = useReadImportStatusListQuery();

  const rows = useMemo(
    () =>
      readImportStatusList.data?.map((record) => ({
        id: record.path,
        description: record.description,
        status:
          record.lastRun === null
            ? FileImportStatus.NEVER_PARSED
            : record.lastRun === record.lastSuccess
              ? FileImportStatus.SUCCESS
              : FileImportStatus.ERROR,
        lastSuccess: record.lastSuccess && new Date(record.lastSuccess),
        lastRun: record.lastRun && new Date(record.lastRun),
        error: record.error || '–',
      })) ?? [],
    [readImportStatusList.data],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readImportStatusList.isLoading}
        error={readImportStatusList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper autoHeight>
          <DataGrid
            rows={rows}
            columns={columns}
            sessionStorageId="ImportStatusListPageDataGrid"
            loading={readImportStatusList.isFetching}
            autoHeight
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
