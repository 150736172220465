import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '..';
import { ForeignClassificationListPage } from '../Admin/ForeignClassification/ForeignClassificationListPage';
import { AdminPage } from './AdminPage';
import { AppUserListPage } from './AppUserListPage';
import { CustomerListPage } from './Customer/CustomerListPage';
import { ECCNListPage } from './ECCN/ECCNListPage';
import { EndUserListPage } from './EndUser/EndUserListPage';
import { HistoryListPage } from './History/HistoryListPage';
import { ImportStatusListPage } from './ImportStatusListPage';
import { ItalianClassificationPage } from './ItalianClassification/ItalianClassificationListPage';
import { ItemMakeBuyListPage } from './ItemMakeBuy/ItemMakeBuyListPage';
import { ProgramListPage } from './ProgramListPage';
import { PurchaseGroupListPage } from './PurchaseGroup/PurchaseGroupListPage';
import { PurchaseOrderListPage } from './PurchaseOrder/PurchaseOrderListPage';
import { RecipientListPage } from './Recipient/RecipientListPage';
import { SerniCategoryListPage } from './SerniCategory/SerniCategoryListPage';
import { SupplierListPage } from './Supplier/SupplierListPage';
import { TechDataClassificationListPage } from './TechDataClassification/TechDataClassificationListPage';
import { ThirdPartyListPage } from './ThirdParty/ThirdPartyListPage';
import { VendorListPage } from './Vendor';

export function AdminRouter(): JSX.Element {
  return (
    <Routes>
      <Route index element={<AdminPage />} />
      <Route path="suppliers" element={<SupplierListPage />} />
      <Route path="vendors" element={<VendorListPage />} />
      <Route path="customers" element={<CustomerListPage />} />
      <Route path="end-users" element={<EndUserListPage />} />
      <Route path="recipients" element={<RecipientListPage />} />
      <Route path="users" element={<AppUserListPage />} />
      <Route path="items-make-buy" element={<ItemMakeBuyListPage />} />
      <Route path="programs" element={<ProgramListPage />} />
      <Route path="purchase-groups" element={<PurchaseGroupListPage />} />
      <Route path="purchase-orders" element={<PurchaseOrderListPage />} />
      <Route path="eccn" element={<ECCNListPage />} />
      <Route path="italian-classifications" element={<ItalianClassificationPage />} />
      <Route path="foreign-classifications" element={<ForeignClassificationListPage />} />
      <Route path="tech-data-classifications" element={<TechDataClassificationListPage />} />
      <Route path="third-parties" element={<ThirdPartyListPage />} />
      <Route path="serni-categories" element={<SerniCategoryListPage />} />
      <Route path="import-status" element={<ImportStatusListPage />} />
      <Route path="history" element={<HistoryListPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
