import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ServerErrorBox, Header, Breadcrumb, MenuItem } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Container, { ContainerProps } from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { ExternalUrlIcon } from '../Icons';
import { FullPageProgress } from '../Progress';

interface AppLayoutProps extends ContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
  // maxWidth?: false | Breakpoint | undefined;
}

const menuItems: MenuItem[] = [
  {
    icon: <ExternalUrlIcon />,
    label: 'XCLASS',
    onClick: () =>
      window.open(
        'https://smauthin.utc.com/forms/UTC_UserLogin.fcc?TYPE=33554433&REALMOID=06-b1b2dfee-5d1a-46b1-a183-143c62d73dfc&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-utasdotnet%2eutc%2ecom&TARGET=-SM-HTTPS%3a%2f%2futasdotnet%2eutc%2ecom%2fxClass%2fSearch%2fxClassSearch%2fxClassSearch',
        '_blank',
      ),
  },
  {
    icon: <ExternalUrlIcon />,
    label: 'TEXPORT',
    onClick: () =>
      window.open(
        'https://smauthin.utc.com/forms/hUTC-hRTN_UserLogin.fcc?TYPE=33554433&REALMOID=06-cb00d34c-b06d-1091-978c-83b026590000&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-gush1am5%2eutcapp%2ecom&TARGET=-SM-HTTP%3a%2f%2fhsdotnet%2eutc%2ecom%2fWorkflow%2fViewDocument%2easpx%3fInitiate%3d11987',
        '_blank',
      ),
  },
];

const APP_NAME = import.meta.env.VITE_NAME as string;

export default function Layout(props: AppLayoutProps): JSX.Element {
  const { children, title, breadcrumbs, inProgress, error, progressIndicator, sx, ...containerProps } = props;

  const theme = useTheme();

  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header
        logo={{ title: APP_NAME }}
        position="fixed"
        breadcrumbs={breadcrumbs}
        smallScreenBreakpoint={theme.breakpoints.values.sm}
        menuItems={menuItems}
      />
      <Toolbar />
      <Container
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          py: 4,
          ...sx,
        }}
        {...containerProps}
      >
        {inProgress ? (
          progressIndicator || <FullPageProgress />
        ) : error ? (
          <ServerErrorBox error={error} sx={{ marginX: 'auto' }} />
        ) : (
          children
        )}
      </Container>
    </>
  );
}
