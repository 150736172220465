import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DataGrid,
  DataGridProps,
  ErrorAlert,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { GridFilterItem, GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-premium';
import { SoldPn } from '../../entities/SoldPn';
import { useReadSoldPnListQuery } from '../../services/soldPnApi';
import { useRecipientListDataGridColumns } from './useRecipientListDataGridColumns';

type RecipientListDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
};

export function RecipientListDataGrid(props: RecipientListDataGridProps): JSX.Element {
  const { columns, ...gridProps } = props;
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [searchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
    const items = new Array<GridFilterItem>();
    const pn = searchParams.get('pn');
    if (searchParams.has('pn')) {
      items.push({ value: pn, field: 'pn', operator: '=' });
    }
    return { items, logicOperator: GridLogicOperator.And };
  });
  const readSoldPnListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readSoldPnList = useReadSoldPnListQuery(readSoldPnListParams);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const defaultColumns = useRecipientListDataGridColumns();

  if (readSoldPnList.error) {
    return <ErrorAlert error={readSoldPnList.error} />;
  }

  return (
    <DataGrid
      rows={readSoldPnList.data?.data ?? []}
      columns={columns ?? defaultColumns}
      getRowId={(row: SoldPn) => `${row.pn}:${row.programId}:${row.customer?.id ?? 'ø'}:${row.shipTo?.id ?? 'ø'}`}
      sessionStorageId="SoldPnListDataGrid"
      loading={readSoldPnList.isFetching}
      pagination
      paginationMode="server"
      rowCount={readSoldPnList.data?.total ?? 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
      filterModel={filterModel}
      {...gridProps}
    />
  );
}
