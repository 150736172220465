import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '..';
import { EUSAddPage } from './EUSAddPage';
import { EUSDetailPage } from './EUSDetailPage';
import { EUSEditPage } from './EUSEditPage';
import { EUSListPage } from './EUSListPage';

export function EUSRouter(): JSX.Element {
  return (
    <Routes>
      <Route index element={<EUSListPage />} />
      <Route path="add" element={<EUSAddPage />} />
      <Route path=":eusId" element={<EUSDetailPage />} />
      <Route path=":eusId/edit" element={<EUSEditPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
