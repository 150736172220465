import { useMemo } from 'react';
import { useCountryGridColDef, useProgramGridColDef } from '@top-solution/microtecnica-mui';
import { differenceInCalendarDays } from 'date-fns';
import Chip from '@mui/material/Chip';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { EndUser, endUserTypeLabel } from '../../entities/EndUser';
import { emailListColumn, stringDateColumn } from '../DataGrid';
import { AlertIcon, CheckIcon } from '../Icons';

export function useEndUserListGridColumns(): GridColDef[] {
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const programColumn = useProgramGridColDef();
  return useMemo(
    () => [
      { field: 'id', headerName: 'Code', width: 80, filterable: false, sortable: false },
      { ...programColumn, width: 150 },
      { field: 'name', headerName: 'Nome', width: 240 },
      {
        field: 'type',
        headerName: 'Ultimate/Intermediate',
        type: 'singleSelect',
        valueOptions: Object.entries(endUserTypeLabel).map(([value, label]) => ({ value, label })),
        width: 200,
      },
      { ...countryColumn, width: 180 },
      { field: 'address', headerName: 'Indirizzo', width: 300, sortable: false },
      {
        ...stringDateColumn,
        field: 'lastCheck',
        headerName: 'Ultima verifica',
        width: 150,
        renderCell: ({ value, formattedValue }: GridRenderCellParams<EndUser, Date, string>) => {
          if (value) {
            const expired = differenceInCalendarDays(new Date(), value) > 90;
            return (
              <Chip
                size="small"
                variant="outlined"
                color={expired ? 'warning' : 'success'}
                label={formattedValue}
                icon={expired ? <AlertIcon /> : <CheckIcon />}
              />
            );
          }
        },
      },
      { ...emailListColumn, field: 'emailOe', headerName: 'PM OE', width: 300 },
      { ...emailListColumn, field: 'emailMro', headerName: 'PM MRO', width: 300 },
    ],
    [countryColumn, programColumn],
  );
}
