import { z } from 'zod';
import { UnitOfMeasureSchema } from './EUSAttributes';
import { VendorSchema } from './Vendor';

export const PurchaseOrderSchema = z.object({
  pn: z.string(),
  purchaseDoc: z.string(),
  createDate: z.string().datetime({ offset: true }),
  deliveryDate: z.string().datetime({ offset: true }),
  qty: z.number(),
  unitOfMeasureId: UnitOfMeasureSchema.shape.id,
  vendor: VendorSchema,
});

export type PurchaseOrder = z.infer<typeof PurchaseOrderSchema>;
