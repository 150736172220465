import { useCallback, useMemo } from 'react';
import { useCountryGridColDef, useProgramGridColDef } from '@top-solution/microtecnica-mui';
import { GridColDef, GridRenderCellParams, GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium';
import { EUSFieldLabelMap } from '../../../entities/EUS';
import { isAutoGeneratedRow, rowAutoGeneratedPrefix } from '../../DataGrid';
import { NotifyMissingRecipientButton } from '../../Recipient/NotifyMissingRecipientButton';

type ShowColumns = Partial<{
  item: boolean;
  enditem: boolean;
  program: boolean;
  customer: boolean;
  shipTo: boolean;
  enduser: boolean;
}>;

function groupingValueGetter(value: string, row: GridValidRowModel, column: GridColDef<GridValidRowModel>): string {
  const title = column.headerName ?? column.field;
  if (value !== undefined) {
    return `${title} ${value}`;
  }
  return `⚠️ ${title} non presente`;
}

function renderPropertyCell({ formattedValue, id }: GridRenderCellParams) {
  // Nothing to show if value !== last substring of AutoGeneratedRow(id)
  if (isAutoGeneratedRow(id) && formattedValue !== id.toString().split('/').pop()) {
    return null;
  }
  return formattedValue ?? '⚠️ Nessun dato presente';
}

export const itemColumn: GridColDef = {
  field: 'item',
  headerName: EUSFieldLabelMap.item,
  width: 150,
  groupingValueGetter,
};

export const enditemColumn: GridColDef = {
  field: 'enditem',
  headerName: EUSFieldLabelMap.enditem,
  width: 150,
  groupingValueGetter,
};

export function useProgramColumn(): GridColDef {
  const programColumn = useProgramGridColDef();

  return useMemo(
    () => ({
      ...programColumn,
      headerName: EUSFieldLabelMap.programId,
      width: 150,
      renderCell: renderPropertyCell,
      groupingValueGetter,
    }),
    [programColumn],
  );
}

export const customerIdColumn: GridColDef = {
  field: 'customerId',
  headerName: EUSFieldLabelMap.customer,
  width: 180,
  renderCell: (params) => {
    if (!params.value && params.row.fromPir && params.row.enditem && params.row.programId) {
      return (
        <NotifyMissingRecipientButton
          pn={params.row.item}
          enditem={params.row.enditem}
          programId={params.row.programId}
        />
      );
    } else {
      return renderPropertyCell(params);
    }
  },
  groupingValueGetter,
};

export const shipToIdColumn: GridColDef = {
  field: 'shipToId',
  headerName: EUSFieldLabelMap.shipTo,
  width: 180,
  renderCell: renderPropertyCell,
  groupingValueGetter,
};

function useGroupingColumns(): GridColDef[] {
  const programColumn = useProgramColumn();
  return useMemo(() => [itemColumn, enditemColumn, programColumn, customerIdColumn, shipToIdColumn], [programColumn]);
}

export function useExtractGroupingRowComponents(): (id: GridRowId) => string[] | undefined {
  const groupingColumns = useGroupingColumns();

  const extractGroupingRowComponents = useCallback(
    (id: GridRowId) => {
      if (typeof id === 'string' && isAutoGeneratedRow(id)) {
        let valueEndIndex = id.length;
        const components = new Array<string>();
        for (let i = groupingColumns.length - 1; i >= 0; i--) {
          const column = groupingColumns[i];
          const prefix = `${column.field}/${column.headerName} `;
          const prefixStartIndex = id.indexOf(prefix);
          if (prefixStartIndex > -1) {
            const value = id.substring(prefixStartIndex + prefix.length, valueEndIndex);
            components.unshift(value);
            valueEndIndex = prefixStartIndex - 1;
          }
        }

        return components;
      }
    },
    [groupingColumns],
  );

  return extractGroupingRowComponents;
}

export function useCreateGroupingRowId(): (components: string[]) => GridRowId | undefined {
  const groupingColumns = useGroupingColumns();

  const createGroupingRowId = useCallback(
    (components: string[]) => {
      if (components.length > 0) {
        const ids = components.map(
          (component, index) =>
            `${groupingColumns[index]?.field}/${groupingColumns[index]?.groupingValueGetter && groupingValueGetter(component, {}, groupingColumns[index])}`,
        );
        ids.unshift(rowAutoGeneratedPrefix);
        return ids.join('-');
      }
    },
    [groupingColumns],
  );

  return createGroupingRowId;
}

export function useColumns(showColumns: ShowColumns, otherColumns?: GridColDef[]): GridColDef[] {
  const programColumn = useProgramColumn();
  const countryColumn = useCountryGridColDef({ format: 'png' });

  const columns = useMemo(() => {
    const columns = new Array<GridColDef>();
    if (showColumns.item) {
      columns.push(itemColumn);
    }
    if (showColumns.enditem) {
      columns.push(enditemColumn);
    }
    if (showColumns.program) {
      columns.push(programColumn);
    }
    if (showColumns.customer) {
      columns.push(
        customerIdColumn,
        { field: 'customerName', headerName: 'Nome', width: 250 },
        { ...countryColumn, field: 'customerCountry', headerName: 'Nazione', width: 150 },
        { field: 'customerAddress', headerName: 'Indirizzo', minWidth: 500 },
      );
    }
    if (showColumns.shipTo) {
      columns.push(
        shipToIdColumn,
        {
          field: 'shipToName',
          headerName: 'Nome',
          width: 250,
        },
        { ...countryColumn, field: 'shipToCountry', headerName: 'Nazione', width: 150 },
        { field: 'shipToAddress', headerName: 'Indirizzo', minWidth: 500 },
      );
    }
    if (showColumns.enduser) {
      columns.push(
        {
          field: 'enduserId',
          headerName: 'Codice',
          width: 180,
          renderCell: renderPropertyCell,
        },
        { field: 'enduserName', headerName: 'Nome', width: 250 },
        { ...countryColumn, field: 'enduserCountry', headerName: 'Nazione', width: 150 },
        { field: 'enduserAddress', headerName: 'Indirizzo', minWidth: 500 },
      );
    }
    if (otherColumns) {
      columns.push(...otherColumns);
    }
    return columns;
  }, [countryColumn, otherColumns, programColumn, showColumns]);

  return columns;
}
