import { useCallback, useMemo, useState } from 'react';
import {
  ErrorAlert,
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  useProgramGridColDef,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, ApiError, useAuth } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import Dialog from '@mui/material/Dialog';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRowModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { UnauthorizedPage } from '../..';
import { DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { SerniCategory } from '../../../entities/SerniCategory';
import { UserRole } from '../../../entities/User';
import { useReadSerniCategoryListQuery, useRemoveSerniCategoryMutation } from '../../../services/serniCategoryApi';
import { adminSection, serniCategorySection } from '../../sections';
import { SerniCategoryAddButton } from './SerniCategoryAddButton';
import { SerniCategoryEditDialog } from './SerniCategoryEditDialog';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: serniCategorySection.title }];
const pinnedColumns = { right: ['actions'] };

export function SerniCategoryListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const { paginationModel, setPaginationModel } = usePagination(0);
  const programColumn = useProgramGridColDef();
  const [updateError, setUpdateError] = useState<ZodError | ApiError | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const [serniCategoryToRemove, setSerniCategoryToRemove] = useState<SerniCategory | null>(null);
  const [serniCategoryToEdit, setSerniCategoryToEdit] = useState<SerniCategory | null>(null);
  const readSerniCategoryListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const serniCategoryList = useReadSerniCategoryListQuery(readSerniCategoryListParams);

  const [removeSerniCategory, removeSerniCategoryStatus] = useRemoveSerniCategoryMutation();

  const handleDeleteVendorConfirm = useCallback(async () => {
    if (serniCategoryToRemove) {
      await removeSerniCategory(serniCategoryToRemove).unwrap();
      setSerniCategoryToRemove(null);
    }
  }, [removeSerniCategory, serniCategoryToRemove]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const columns: (GridColDef | GridActionsColDef)[] = [
      { field: 'pn', headerName: 'P/N', minWidth: 150, editable: false, filterable: true, sortable: true },
      {
        ...programColumn,
        minWidth: 180,
        editable: false,
        filterable: true,
        sortable: true,
      },
      { field: 'code', headerName: 'SERNI', minWidth: 150, editable: true, filterable: true, sortable: true },
      { field: 'description', headerName: 'Descrizione', flex: 1, editable: true, filterable: false, sortable: false },
    ];
    if (isAdmin || !serniCategorySection.role || hasRole(serniCategorySection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Azioni',
        renderHeader: () => <SerniCategoryAddButton />,
        getActions: ({ row }: GridRowParams<SerniCategory>) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            key="edit"
            label="Modifica"
            onClick={() => setSerniCategoryToEdit(row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={() => setSerniCategoryToRemove(row)}
          />,
        ],
      });
    }
    return columns;
  }, [hasRole, isAdmin, programColumn]);

  const rows = useMemo<GridRowModel[]>(
    () => serniCategoryList.data?.data.map((item) => ({ ...item, id: `${item.pn}-${item.programId}` })) ?? [],
    [serniCategoryList.data],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={serniCategoryList.isLoading}
        error={serniCategoryList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        {updateError && (
          <Dialog open={true} onClose={() => setUpdateError(null)}>
            <ErrorAlert error={updateError} />
          </Dialog>
        )}
        <DataGridWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            sessionStorageId="SerniListPageDataGrid"
            loading={serniCategoryList.isFetching}
            pagination
            paginationMode="server"
            rowCount={serniCategoryList.data?.total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            pinnedColumns={pinnedColumns}
          />
        </DataGridWrapper>
        {serniCategoryToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare il codice SERNI "${serniCategoryToRemove.code}"?`}
            confirmText="confermo"
            open={Boolean(serniCategoryToRemove)}
            inProgress={removeSerniCategoryStatus.isLoading}
            error={removeSerniCategoryStatus.error}
            onConfirm={() => handleDeleteVendorConfirm()}
            onClose={() => setSerniCategoryToRemove(null)}
          />
        )}
        {serniCategoryToEdit && (
          <SerniCategoryEditDialog
            open={Boolean(serniCategoryToEdit)}
            initialValues={serniCategoryToEdit}
            onClose={() => setSerniCategoryToEdit(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
