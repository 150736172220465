import { useContext, useMemo } from 'react';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { EUSEditCustomerItem } from '../../../entities/EUS';
import { createId, rowEmptyId } from '../../DataGrid';
import { useColumns } from './columns';
import { EUSFormContext } from './EUSFormContext';
import { EUSRecipientPropertySelectionStep } from './EUSRecipientPropertySelectionStep';
import { useBottomUpSearchParams } from './useBottomUpSearchParams';

export const getRowId = (row: BottomUpSearchResult) => createId([row.item, row.enditem]);

export const getRecipientId = (pn: string, recipient: EUSEditCustomerItem) => createId([pn, recipient.enditem]);

const getGroupId = () => rowEmptyId;

const createRecipient = (_groupId: string, propertyId?: string): EUSEditCustomerItem => {
  if (!propertyId) {
    // This shouldn't ever happen thanks to the `skipPhantomCreation` prop
    throw Error('Trying to create an empty recipient');
  }
  return {
    enditem: propertyId,
    programId: null,
    customerId: null,
    shipToId: null,
  };
};

const getRecipientProperty = (recipient: EUSEditCustomerItem) => recipient.enditem;

const getErrorMessage = (pn: string) => `P/N ${pn}`;

const initialState: DataGridPremiumProps['initialState'] = {
  rowGrouping: {
    model: ['item'],
  },
  columns: {
    columnVisibilityModel: { item: false },
  },
};

export function EUSEditEndItemForm(): JSX.Element {
  const { data } = useContext(EUSFormContext);
  const bottomUpSearchParams = useBottomUpSearchParams(data, { enditem: true });

  const defaultColumns = useColumns({ item: true, enditem: true });

  const columns = useMemo(
    () => [
      ...defaultColumns,
      { field: 'enditemDescription', headerName: 'Descrizione End Item', flex: 1, minWidth: 200 },
    ],
    [defaultColumns],
  );

  return (
    <EUSRecipientPropertySelectionStep
      bottomUpSearchParams={bottomUpSearchParams}
      createRecipient={createRecipient}
      getRecipientProperty={getRecipientProperty}
      getRowId={getRowId}
      getRecipientId={getRecipientId}
      getGroupId={getGroupId}
      columns={columns}
      initialState={initialState}
      getErrorMessage={getErrorMessage}
      groupColWidth={200}
    />
  );
}
