import { z } from 'zod';

export const SerniCategorySchema = z.object({
  pn: z.string(),
  programId: z.string(),
  code: z.string().min(3).max(64),
  description: z.string(),
});

export type SerniCategory = z.infer<typeof SerniCategorySchema>;
