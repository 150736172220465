import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useReadThirdPartyTypeListQuery } from '../../services/thirdPartyTypeApi';

export function useThirdPartyTypeColDef(): GridColDef {
  const { data } = useReadThirdPartyTypeListQuery();
  const valueOptions = useMemo(() => data?.map(({ id, name }) => ({ value: id, label: name })), [data]);

  return useMemo(
    () => ({
      field: 'type',
      headerName: 'Tipo',
      type: 'singleSelect',
      valueOptions,
    }),
    [valueOptions],
  );
}
