import { z } from 'zod';

export const ECCNSchema = z.object({
  id: z.string().min(3),
  country: z.string().min(2).max(3),
  legislation: z.string().min(2),
  isMilitary: z.boolean(),
  availableToManufacturers: z.boolean(),
});

export type ECCN = z.infer<typeof ECCNSchema>;
