import Button from '@mui/material/Button';
import { GridToolbarContainer, GridToolbarContainerProps } from '@mui/x-data-grid-premium';
import { ChevronLeftIcon } from '../Icons';

type SearchToolbarProps = GridToolbarContainerProps & {
  onBack?: () => void;
};

export function SearchToolbar(props: SearchToolbarProps): JSX.Element {
  const { onBack, ...toolbarProps } = props;

  return (
    <GridToolbarContainer {...toolbarProps}>
      <Button
        size="small"
        color="primary"
        onClick={onBack ? () => onBack() : undefined}
        startIcon={<ChevronLeftIcon />}
      >
        Modifica Ricerca
      </Button>
    </GridToolbarContainer>
  );
}
