import { useCallback, useMemo, useState } from 'react';
import { ErrorAlert, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { DataGridPremium, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useReadVendorListQuery } from '../../services/vendorApi';
import { SelectItemListDialogProps } from './SelectItemListDialogProps';

export function SelectVendorListDialog(props: SelectItemListDialogProps<string>): JSX.Element {
  const { onClose, ...dialogProps } = props;
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const readVendorList = useReadVendorListQuery();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [showError, setShowError] = useState(false);

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Code', width: 70 },
      { field: 'name', headerName: 'Vendor', flex: 1 },
      { ...countryColumn, width: 250 },
    ],
    [countryColumn],
  );

  const onSubmit = useCallback(() => {
    if (Array.isArray(selectionModel) && selectionModel.length > 0) {
      onClose(selectionModel.map((value) => value.toString()));
    } else {
      setShowError(true);
    }
  }, [onClose, selectionModel]);

  return (
    <Dialog fullWidth maxWidth="lg" {...dialogProps}>
      <DialogTitle>Seleziona vendor</DialogTitle>
      <DialogContent sx={{ height: 'calc(100vh - 200px)', padding: 0 }}>
        {readVendorList.error ? (
          <ErrorAlert error={readVendorList.error} />
        ) : (
          <DataGridPremium
            rows={readVendorList.data ?? []}
            columns={columns}
            loading={readVendorList.isFetching}
            checkboxSelection
            onRowSelectionModelChange={setSelectionModel}
            rowSelectionModel={selectionModel}
            hideFooter
          />
        )}
      </DialogContent>
      <DialogActions>
        {showError && (selectionModel as string[]).length === 0 && (
          <Typography variant="caption" color="error" sx={{ flexGrow: 1 }}>
            Seleziona almeno un elemento
          </Typography>
        )}
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
