import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth, useReadUserQuery } from '@top-solution/microtecnica-utils';
import { useReadProgramPMEmailList } from './useReadProgramPMEmailList';

export function useCanManageSoldPn(programId: string): boolean {
  const { username, isAdmin } = useAuth();
  const readUser = useReadUserQuery(username ? { username } : skipToken);
  const readEmailList = useReadProgramPMEmailList(programId);

  return useMemo<boolean>(() => {
    if (readUser.data && readEmailList.oe && readEmailList.mro) {
      return (
        isAdmin || Boolean([...readEmailList.oe, ...readEmailList.mro].find((email) => email === readUser.data?.email))
      );
    }
    return isAdmin;
  }, [isAdmin, readEmailList.mro, readEmailList.oe, readUser.data]);
}
