import { Controller, UseFormReturn } from 'react-hook-form';
import { CountryAutocomplete } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormRow, ECCNGroupSelect, ItemVendorSelect } from '../../../components/Form';
import ItemVendorPurchaseGroupSelect from '../../../components/Form/ItemVendorPurchaseGroupSelect';
import { PartNumberAutocomplete } from '../../../components/Form/PartNumberAutocomplete';
import { ECCN } from '../../../entities/ECCN';
import { ForeignClassificationAddForm } from '../../../entities/ForeignClassification';
import { PurchaseGroup } from '../../../entities/PurchaseGroup';
import { Vendor } from '../../../entities/Vendor';

export interface ForeignClassificationEditFormProps {
  form: UseFormReturn<ForeignClassificationAddForm>;
  editMode?: boolean;
}

export function ForeignClassificationEditForm(props: ForeignClassificationEditFormProps): JSX.Element {
  const { form, editMode } = props;

  const { control, setValue, watch } = form;
  const pn = watch('pn');
  const vendor = watch('vendor');

  return (
    <>
      <FormRow>
        <Controller
          control={control}
          name="pn"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <PartNumberAutocomplete
              required
              disabled={editMode}
              label="Microtecnica P/N"
              onChange={(_, value) => {
                onChange(value);
                setValue('vendor', null as unknown as Vendor);
              }}
              disableClearable
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="vendorPn"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Vendor P/N" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="vendor"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <ItemVendorSelect
              required
              label="Vendor"
              pn={pn ?? null}
              onChange={(value) => onChange(value as Vendor)}
              error={Boolean(error)}
              helperText={error?.message || (!pn && 'Selezionare un P/N') || undefined}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="pgr"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <ItemVendorPurchaseGroupSelect
              required
              label="Buyer"
              pn={pn ?? undefined}
              vendorCode={pn ? vendor?.id : undefined}
              onChange={(value) => onChange(value as PurchaseGroup)}
              error={Boolean(error)}
              helperText={error?.message || ((!pn || !vendor) && 'Selezionare P/N e Vendor') || undefined}
              fullWidth
              {...field}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="manufacturer"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Manufacturer" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <CountryAutocomplete
              required
              label="Country of origin (CoO)"
              onChange={(_, value) => onChange(value as string)}
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Controller
          control={control}
          name="htsn"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Vendor HTSN" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Box sx={{ flex: 1 }} />
      </FormRow>
      <Controller
        control={control}
        name="eccn"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <ECCNGroupSelect
            required
            onChange={(value) => onChange(value as ECCN)}
            error={Boolean(error)}
            helperText={error?.message || ' '}
            sx={{ '.ECCNGroupSelect-SelectCountry': { minWidth: 418 } }}
            {...field}
          />
        )}
      />
      <FormRow>
        <Controller
          control={control}
          name="notes"
          render={({ field, fieldState: { error } }) => (
            <TextField
              multiline
              rows={4}
              label="Note"
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
      </FormRow>
    </>
  );
}
