import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { EndUser, EndUserForm, EndUserSchema } from '../entities/EndUser';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/endusers';

const endUserApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEndUserList: builder.query<PagedResponse<EndUser>, PagedRequestParams & { pmEmail?: string }>({
      query: ({ pmEmail, ...params }) => {
        const searchParams = formatQueryParams(params);
        if (pmEmail) {
          searchParams.set('pmEmail', pmEmail);
        }
        return { url, params: searchParams };
      },
      transformResponse: (data) => createPagedResponseSchema(EndUserSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.END_USER, id: 'LIST' }],
    }),
    createEndUser: builder.mutation<void, EndUserForm>({
      query: (endUser) => ({
        url,
        method: 'POST',
        body: {
          ...endUser,
          emailMro: endUser.emailMro.join(';'),
          emailOe: endUser.emailOe.join(';'),
          type: endUser.type ?? undefined,
        },
      }),
      invalidatesTags: () => [
        { type: TAG_TYPES.END_USER, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
    updateEndUser: builder.mutation<void, EndUser>({
      query: (endUser) => ({ url: `${url}/${endUser.id}`, method: 'PUT', body: endUser }),
      invalidatesTags: () => [
        { type: TAG_TYPES.END_USER, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
    removeEndUser: builder.mutation<void, EndUser>({
      query: (endUser) => ({ url: `${url}/${endUser.id}`, method: 'DELETE' }),
      invalidatesTags: () => [
        { type: TAG_TYPES.END_USER, id: 'LIST' },
        { type: TAG_TYPES.HISTORY, id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadEndUserListQuery,
  useLazyReadEndUserListQuery,
  useRemoveEndUserMutation,
  useUpdateEndUserMutation,
  useCreateEndUserMutation,
} = endUserApi;
