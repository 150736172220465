import { z } from 'zod';

export const BottomUpSearchFieldsSchema = z
  .object({
    enditem: z.boolean(),
    program: z.boolean(),
    customer: z.boolean(),
    enduser: z.boolean(),
    foreignClassification: z.boolean(),
    italianClassification: z.boolean(),
    techDataClassification: z.boolean(),
    serni: z.boolean(),
  })
  .refine(
    (data) =>
      data.enditem ||
      data.program ||
      data.customer ||
      data.enduser ||
      data.foreignClassification ||
      data.italianClassification ||
      data.techDataClassification ||
      data.serni,
    { message: 'Selezionare almeno un campo', path: ['atLeastOne'] },
  );

export type BottomUpSearchFields = z.infer<typeof BottomUpSearchFieldsSchema>;

export const BottomUpSearchFiltersSchema = z
  .object({
    pn: z.string().array(),
    vendor: z.string().array(),
    pgr: z.string().array(),
    eccn: z.string().array(),
  })
  .refine((data) => data.pn.length || data.vendor.length || data.pgr.length || data.eccn.length, {
    message: 'Popolare almeno un filtro',
    path: ['atLeastOne'],
  });

export type BottomUpSearchFilters = z.infer<typeof BottomUpSearchFiltersSchema>;

export const BottomUpSearchParamsSchema = z.object({
  fields: BottomUpSearchFieldsSchema,
  filters: BottomUpSearchFiltersSchema,
});

export type BottomUpSearchParams = z.infer<typeof BottomUpSearchParamsSchema>;

export const BottomUpSearchResultSchema = z.object({
  item: z.string(),
  enditem: z.string().optional(),
  enditemDescription: z.string().optional(),
  isMake: z.boolean().optional(),
  vendorCode: z.string().optional(),
  vendorName: z.string().optional(),
  vendorSource: z.string().optional(),
  vendorCountry: z.string().optional(),
  vendorDa: z.string().optional(),
  vendorLead: z.string().optional(),
  pgrCode: z.string().optional(),
  pgrBuyer: z.string().optional(),
  italianClassificationECCN: z.string().optional(),
  italianClassificationECCNIsMilitary: z.boolean().optional(),
  italianClassificationECCNLegislation: z.string().optional(),
  italianClassificationECCNCountry: z.string().optional(),
  italianClassificationHTSN: z.string().optional(),
  foreignClassificationSupplierPN: z.string().optional(),
  foreignClassificationManufacturer: z.string().optional(),
  foreignClassificationManufacturerCountry: z.string().optional(),
  foreignClassificationECCN: z.string().optional(),
  foreignClassificationECCNIsMilitary: z.boolean().optional(),
  foreignClassificationECCNLegislation: z.string().optional(),
  foreignClassificationECCNCountry: z.string().optional(),
  foreignClassificationHTSN: z.string().optional(),
  techDataSpec: z.string().optional(),
  techDataFilename: z.string().optional(),
  techDataHas3dModel: z.boolean().optional(),
  techDataClassificationECCN: z.string().optional(),
  techDataClassificationECCNIsMilitary: z.boolean().optional(),
  techDataClassificationECCNLegislation: z.string().optional(),
  techDataClassificationECCNCountry: z.string().optional(),
  programId: z.string().optional(),
  customerId: z.string().optional(),
  customerName: z.string().optional(),
  customerCountry: z.string().optional(),
  customerAddress: z.string().optional(),
  shipToId: z.string().optional(),
  shipToName: z.string().optional(),
  shipToCountry: z.string().optional(),
  shipToAddress: z.string().optional(),
  enduserId: z.string().optional(),
  enduserName: z.string().optional(),
  enduserCountry: z.string().optional(),
  enduserAddress: z.string().optional(),
  serniCode: z.string().optional(),
  fromPir: z.number().optional(),
  fromSos: z.number().optional(),
  fromMro: z.number().optional(),
});

export type BottomUpSearchResult = z.infer<typeof BottomUpSearchResultSchema>;

export function bottomUpSearchHasThirdPartyWarning(result: BottomUpSearchResult): boolean {
  return (
    (result.vendorCountry === 'US' || result.foreignClassificationManufacturerCountry === 'US') &&
    (result.foreignClassificationECCNIsMilitary === true || result.italianClassificationECCNIsMilitary === true)
  );
}
