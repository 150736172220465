import { DataGridWrapper, ProgramListDataGrid } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '..';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { adminSection, programSection } from '../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: programSection.title }];

export function ProgramListPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper>
          <ProgramListDataGrid />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
