import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { EndUserForm, EndUserFormSchema } from '../../entities/EndUser';
import { useCreateEndUserMutation } from '../../services/endUserApi';
import { AddIcon, SaveIcon } from '../Icons';
import { EndUserEditForm } from './EndUserEditForm';

const defaultValues: EndUserForm = {
  programId: null,
  name: '',
  type: null,
  address: '',
  country: null,
  emailOe: [],
  emailMro: [],
  lastCheck: new Date().toISOString(),
} as unknown as EndUserForm;

export function EndUserAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [create, createStatus] = useCreateEndUserMutation();
  const form = useForm<EndUserForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(EndUserFormSchema),
  });

  const onSubmit = useCallback(
    async (data: EndUserForm) => {
      await create(data).unwrap();
      setDialogOpen(false);
    },
    [create],
  );

  return (
    <>
      <Tooltip title="Aggiungi end-user">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        TransitionProps={{
          onExit: () => {
            createStatus.reset();
            form.reset();
          },
        }}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>Nuovo end-user</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 }, gap: 1 }}>
            <EndUserEditForm form={form} />
            {createStatus.error && <ErrorAlert error={createStatus.error} />}
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setDialogOpen(false)}>
              Annulla
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={createStatus.isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Salva
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
