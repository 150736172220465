import { useState } from 'react';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UnauthorizedPage } from '../..';
import { Layout } from '../../../components/Layout';
import { BottomUpSearchResult, BottomUpSearchParams } from '../../../entities/BottomUpSearch';
import { bottomUpSearch } from '../../sections';
import { BottomUpSearchDataGrid } from './BottomUpSearchDataGrid';
import { BottomUpSearchForm } from './BottomUpSearchForm';

const breadcrumbs = [{ title: bottomUpSearch.title }];

export function BottomUpSearchPage(): JSX.Element {
  const [searchParams, setSearchParams] = useState<BottomUpSearchParams>({
    fields: {
      enditem: false,
      program: false,
      customer: false,
      enduser: false,
      foreignClassification: false,
      italianClassification: false,
      techDataClassification: false,
      serni: false,
    },
    filters: {
      pn: [],
      vendor: [],
      pgr: [],
      eccn: [],
    },
  });
  const [searchResult, setSearchResult] = useState<BottomUpSearchResult[] | null>(null);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        {searchResult ? (
          <BottomUpSearchDataGrid
            searchResult={searchResult}
            searchParams={searchParams}
            onBack={() => setSearchResult(null)}
          />
        ) : (
          <BottomUpSearchForm
            searchParams={searchParams}
            onResultLoaded={(results, params) => {
              setSearchParams(params);
              setSearchResult(results);
            }}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
