import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ErrorAlert, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Box, { BoxProps } from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Attachment } from '../../../entities/Attachment';
import { useUploadMutation } from '../../../services/attachmentApi';

import { FileIcon, UploadIcon } from '../../Icons';
import { FakeInput } from './FakeInput';

interface AttachmentsFieldProps extends Omit<BoxProps, 'value' | 'onChange'> {
  value: Attachment[] | undefined;
  onChange: (value: Attachment[]) => void;
  deleteDialogText?: (attachmentToDelete: Attachment | null) => React.ReactNode;
  label?: string;
  maxAttachmentsCount?: number;
  error: boolean;
  helperText?: string;
}

function AttachmentsField(props: AttachmentsFieldProps, ref: React.Ref<HTMLInputElement>) {
  const { value, onChange, deleteDialogText, label, maxAttachmentsCount, error, helperText, sx, ...boxProps } = props;
  const [attachmentToDelete, setAttachmentToDelete] = useState<null | Attachment>(null);
  const [upload, { isLoading: uploadIsLoading, error: uploadError }] = useUploadMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const safeValue = useMemo(() => value ?? [], [value]);

  const handleFileSelection = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const attachment = await upload(file).unwrap();
        if (attachment?.id) {
          onChange([...safeValue, attachment]);
        }
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [onChange, safeValue, upload],
  );

  const handleAttachmentDeleteConfirm = useCallback(() => {
    onChange(value ? value.filter(({ id }) => id !== attachmentToDelete?.id) : []);
    setAttachmentToDelete(null);
  }, [attachmentToDelete?.id, onChange, value]);

  const disableAddAttachment = Boolean(maxAttachmentsCount && value ? value.length >= maxAttachmentsCount : false);

  return (
    <FakeInput
      error={error}
      label={label ?? 'File'}
      helperText={helperText}
      sx={{
        '.FakeInput-root': {
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'row wrap',
          maxHeight: 480,
          pt: 1.5,
          pb: 1,
        },
        '.MuiChip-root': { maxWidth: '100%', mx: 0.25, my: 0.5 },
        ...sx,
      }}
      {...boxProps}
    >
      <Box
        sx={{
          pointerEvents: disableAddAttachment ? 'none' : undefined,
          input: { display: 'none' },
        }}
      >
        <input
          id="upload-attachment"
          ref={ref}
          type="file"
          accept="image/*,.pdf,.doc,.docx,.xlsx"
          onChange={handleFileSelection}
        />
        <label htmlFor="upload-attachment">
          <LoadingButton
            color="primary"
            variant="outlined"
            component="span"
            startIcon={<UploadIcon />}
            loading={uploadIsLoading}
            disabled={disableAddAttachment}
          >
            Aggiungi
          </LoadingButton>
        </label>
      </Box>

      {safeValue.map((attachment) => (
        <Chip
          key={attachment.id}
          label={attachment.name}
          icon={<FileIcon />}
          onDelete={() => setAttachmentToDelete(attachment)}
          disabled={uploadIsLoading}
        />
      ))}
      {uploadError && <ErrorAlert sx={{ mt: 2 }} error={uploadError} />}
      <DeleteConfirmDialog
        title="Vuoi davvero eliminare l'allegato?"
        open={Boolean(attachmentToDelete)}
        onConfirm={handleAttachmentDeleteConfirm}
        onClose={() => setAttachmentToDelete(null)}
      >
        {deleteDialogText ? deleteDialogText(attachmentToDelete) : null}
      </DeleteConfirmDialog>
    </FakeInput>
  );
}

export default React.memo(React.forwardRef(AttachmentsField));
