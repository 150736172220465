import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CountryAutocomplete, ErrorAlert } from '@top-solution/microtecnica-mui';
import { ZodIssueCode } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../../components/Icons';
import { EditVendorForm, EditVendorFormSchema, Vendor, VendorSource } from '../../../entities/Vendor';
import { useCreateVendorMutation, useUpdateVendorMutation } from '../../../services/vendorApi';

const emptyVendor = {
  id: null,
  name: '',
  da: '',
  lead: '',
  country: null,
} as unknown as Vendor;

type VendorEditDialogProps = Omit<DialogProps, 'children' | 'onClose'> & {
  initialValues?: Vendor;
  onClose: (data: Vendor | null) => void;
};

export function VendorEditDialog(props: VendorEditDialogProps): JSX.Element {
  const { initialValues, onClose, ...dialogProps } = props;

  const [create, createStatus] = useCreateVendorMutation();
  const [update, updateStatus] = useUpdateVendorMutation();

  const form = useForm<EditVendorForm>({
    defaultValues: initialValues ?? emptyVendor,
    resolver: zodResolver(EditVendorFormSchema),
  });

  const editMode = Boolean(initialValues?.id);

  const onSubmit = useCallback(
    async (data: EditVendorForm) => {
      if (editMode) {
        await update(data).unwrap();
      } else {
        await create(data).unwrap();
      }
      onClose({ ...data, source: VendorSource.Manual });
    },
    [create, editMode, onClose, update],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => onClose(null)}
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          createStatus.reset();
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{editMode ? 'Modifica Vendor' : 'Nuovo Vendor'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'visible' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
            <Controller
              control={form.control}
              name="id"
              render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
                <TextField
                  label="Codice"
                  value={value ?? ''}
                  error={invalid}
                  helperText={
                    error?.type === ZodIssueCode.invalid_string
                      ? 'Il codice deve essere composto da 6 cifre'
                      : (error?.message ?? ' ')
                  }
                  disabled={editMode}
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              control={form.control}
              name="country"
              render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => (
                <CountryAutocomplete
                  label="Nazione"
                  value={value}
                  onChange={(_, value) => onChange(value)}
                  error={invalid}
                  helperText={error?.message ?? ' '}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Stack>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField label="Nome" error={invalid} helperText={error?.message ?? ' '} {...field} />
            )}
          />
          <Controller
            control={form.control}
            name="da"
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField label="DA" error={invalid} helperText={error?.message ?? ' '} {...field} />
            )}
          />
          <Controller
            control={form.control}
            name="lead"
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField label="Lead" error={invalid} helperText={error?.message ?? ' '} {...field} />
            )}
          />
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose(null)}>
            Annulla
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            loading={createStatus.isLoading || updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
