import { ECCN, ECCNSchema } from '../entities/ECCN';
import { api, TAG_TYPES } from './baseApi';

const invalidatesTags = () => [{ type: TAG_TYPES.ECCN, id: 'LIST' }];

const url = 'v1/eccn';

const eccnApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readECCNLegislationList: builder.query<string[], void>({
      query: () => 'v1/eccn-legislations',
      keepUnusedDataFor: 3600,
    }),
    readECCNList: builder.query<ECCN[], void>({
      query: () => url,
      transformResponse: (data) => ECCNSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.ECCN, id: 'LIST' }],
    }),
    createECCN: builder.mutation<void, ECCN>({
      query: (eccn) => ({ url, method: 'POST', body: { ...eccn } }),
      invalidatesTags,
    }),
    updateECCN: builder.mutation<void, ECCN>({
      query: (eccn) => ({
        url: `${url}/${eccn.id}`,
        method: 'PUT',
        body: eccn,
      }),
      invalidatesTags,
    }),
    removeECCN: builder.mutation<void, ECCN>({
      query: (eccn) => ({ url: `${url}/${eccn.id}`, method: 'DELETE' }),
      invalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadECCNLegislationListQuery,
  useReadECCNListQuery,
  useLazyReadECCNListQuery,
  useRemoveECCNMutation,
  useUpdateECCNMutation,
  useCreateECCNMutation,
} = eccnApi;
