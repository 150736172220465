import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ThirdPartyTypeSelect } from '../../../components/Form/ThirdPartyTypeSelect';
import { SaveIcon } from '../../../components/Icons';
import { ThirdPartyAddForm, ThirdPartyAddFormSchema } from '../../../entities/ThirdParty';
import { useCreateThirdPartyMutation, useUpdateThirdPartyMutation } from '../../../services/thirdPartyApi';

type ThirdPartyEditDialogProps = Omit<DialogProps, 'onClose'> & {
  initialValues: ThirdPartyAddForm;
  onClose: (value?: ThirdPartyAddForm) => void;
};

export function ThirdPartyEditDialog(props: ThirdPartyEditDialogProps): JSX.Element {
  const { initialValues, onClose, ...dialogProps } = props;
  const [create, createStatus] = useCreateThirdPartyMutation();
  const [update, updateStatus] = useUpdateThirdPartyMutation();
  const { handleSubmit, control } = useForm<ThirdPartyAddForm>({
    defaultValues: initialValues,
    resolver: zodResolver(ThirdPartyAddFormSchema),
  });

  const onSubmit = useCallback(
    async (data: ThirdPartyAddForm) => {
      if ('id' in initialValues) {
        await update(data).unwrap();
      } else {
        await create(data).unwrap();
      }
      onClose(data);
    },
    [create, initialValues, onClose, update],
  );

  const isLoading = createStatus.isLoading || updateStatus.isLoading;
  const error = createStatus.error || updateStatus.error;

  return (
    <Dialog maxWidth="sm" fullWidth {...dialogProps}>
      <DialogTitle>{'id' in initialValues ? 'Modifica Terza Parte' : 'Nuova Terza Parte'}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Nome" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState: { error } }) => (
            <ThirdPartyTypeSelect label="Tipo" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Indirizzo" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Controller
          control={control}
          name="notes"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Note" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
