import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProgramAutocomplete, ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { PartNumberAutocomplete } from '../../../components/Form/PartNumberAutocomplete';
import { SaveIcon } from '../../../components/Icons';
import { SerniCategory, SerniCategorySchema } from '../../../entities/SerniCategory';
import { useCreateSerniCategoryMutation, useUpdateSerniCategoryMutation } from '../../../services/serniCategoryApi';

type SerniCategoryEditDialogProps = DialogProps & {
  initialValues: SerniCategory;
  onClose: (data: SerniCategory | null) => void;
};

export function SerniCategoryEditDialog(props: SerniCategoryEditDialogProps): JSX.Element {
  const { initialValues, onClose, ...dialogProps } = props;
  const [create, createStatus] = useCreateSerniCategoryMutation();
  const [update, updateStatus] = useUpdateSerniCategoryMutation();

  const editMode = initialValues.pn !== null && initialValues.programId !== null;

  const form = useForm<SerniCategory>({
    defaultValues: initialValues,
    resolver: zodResolver(SerniCategorySchema),
  });
  const { handleSubmit, control } = form;

  const onSubmit = useCallback(
    async (data: SerniCategory) => {
      if (editMode) {
        await update(data).unwrap();
      } else {
        await create(data).unwrap();
      }
      onClose(data);
    },
    [create, editMode, onClose, update],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          form.reset();
          createStatus.reset();
          updateStatus.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>{editMode ? 'Modifica categoria SERNI' : 'Nuova categoria SERNI'}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
        <Controller
          control={control}
          name="pn"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <PartNumberAutocomplete
              label="P/N"
              onChange={(_, value) => onChange(value)}
              disableClearable
              error={Boolean(error)}
              helperText={error?.message || ' '}
              disabled={editMode}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="programId"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <ProgramAutocomplete
              label="Programma"
              onChange={(_, value) => onChange(value)}
              disableClearable
              error={Boolean(error)}
              helperText={error?.message || ' '}
              disabled={editMode}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="code"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Codice SERNI" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <TextField label="Descrizione" error={Boolean(error)} helperText={error?.message || ' '} {...field} />
          )}
        />
        {createStatus.error && <ErrorAlert error={createStatus.error || updateStatus.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose(null)}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={createStatus.isLoading || updateStatus.isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
