import { z } from 'zod';
import { ThirdPartyTypeSchema } from './ThirdPartyType';

export const ThirdPartySchema = z.object({
  id: z.number().nonnegative(),
  type: ThirdPartyTypeSchema,
  name: z.string().min(3),
  address: z.string().min(3),
  notes: z.string().optional(),
});

export type ThirdParty = z.infer<typeof ThirdPartySchema>;

export const ThirdPartyAddFormSchema = ThirdPartySchema.extend({
  id: z.number().optional(),
  type: z.string(),
});
export type ThirdPartyAddForm = z.infer<typeof ThirdPartyAddFormSchema>;
