import { useContext } from 'react';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { EUSEditCustomerItem } from '../../../entities/EUS';
import { createId, rowIdSeparator } from '../../DataGrid';
import { useColumns } from './columns';
import { getRowId as getPreviousStepRowId, getRecipientId as getPreviousStepRecipientId } from './EUSEditEndItemForm';
import { EUSFormContext } from './EUSFormContext';
import { EUSRecipientPropertySelectionStep } from './EUSRecipientPropertySelectionStep';
import { useBottomUpSearchParams } from './useBottomUpSearchParams';

export const getRowId = (row: BottomUpSearchResult) => createId([row.item, row.enditem, row.programId]);

export const getRecipientId = (pn: string, recipient: EUSEditCustomerItem) =>
  createId([pn, recipient.enditem, recipient.programId]);

const getGroupId = (recipient: EUSEditCustomerItem) => createId([recipient.enditem]);

const createRecipient = (groupId: string, propertyId?: string): EUSEditCustomerItem => {
  const [enditem] = groupId.split(rowIdSeparator);
  return {
    enditem,
    programId: propertyId ?? null,
    customerId: null,
    shipToId: null,
  };
};

const getRecipientProperty = (recipient: EUSEditCustomerItem) => recipient.programId ?? null;

const getErrorMessage = (pn: string, recipient?: EUSEditCustomerItem) => `P/N ${pn} su ${recipient?.enditem}`;

const initialState: DataGridPremiumProps['initialState'] = {
  rowGrouping: {
    model: ['item', 'enditem'],
  },
  columns: {
    columnVisibilityModel: { item: false, enditem: false },
  },
};

export function EUSEditProgramForm(): JSX.Element {
  const { data } = useContext(EUSFormContext);

  const bottomUpSearchParams = useBottomUpSearchParams(data, { enditem: true, program: true });

  const columns = useColumns({ item: true, enditem: true, program: true });

  return (
    <EUSRecipientPropertySelectionStep
      bottomUpSearchParams={bottomUpSearchParams}
      createRecipient={createRecipient}
      getRecipientProperty={getRecipientProperty}
      getRowId={getRowId}
      getRecipientId={getRecipientId}
      getPreviousStepRowId={getPreviousStepRowId}
      getPreviousStepRecipientId={getPreviousStepRecipientId}
      getGroupId={getGroupId}
      columns={columns}
      initialState={initialState}
      getErrorMessage={getErrorMessage}
      groupColWidth={250}
    />
  );
}
