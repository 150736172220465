import { z } from 'zod';
import { CustomerSchema } from './Customer';

export const RecipientAddFormSchema = z.object({
  pn: z.string(),
  programId: z.string(),
  customer: CustomerSchema,
  shipTo: CustomerSchema,
});

export type RecipientAddForm = z.infer<typeof RecipientAddFormSchema>;
