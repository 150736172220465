import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { NotFoundBox } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { EUSEditForm } from '../../components/EUS/EUSEditForm';
import { EUSFormContextProvider } from '../../components/EUS/EUSEditForm/EUSFormContext';
import { Layout } from '../../components/Layout';
import { EUSEditModel, convertEUSToEUSEditModel } from '../../entities/EUS';
import { UserRole } from '../../entities/User';
import { useUpdateEUSMutation, useReadEUSByIdQuery } from '../../services/eusApi';
import { useReadStatusListQuery } from '../../services/eusAttributesApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { eusSection } from '../sections';

const breadcrumbs = [{ title: eusSection.title, url: eusSection.url }];

export function EUSEditPage(): JSX.Element {
  const params = useParams<'eusId'>();
  const eusId = useMemo(() => {
    const eusId = parseInt(params.eusId ?? '');
    if (!isNaN(eusId)) {
      return eusId;
    }
    return null;
  }, [params.eusId]);

  const [update, updateStatus] = useUpdateEUSMutation();

  const readEUSById = useReadEUSByIdQuery(eusId ?? skipToken);
  const readEUSStatusList = useReadStatusListQuery();

  const eusToEdit = useMemo<EUSEditModel | null>(() => {
    if (readEUSById.data && readEUSStatusList.data) {
      const eus = convertEUSToEUSEditModel(readEUSById.data);
      // If the current EUS has been released, the status must be reset to null
      // so that the user MUST chose a new one
      // https://github.com/top-solution/microtecnica.procurement-tool/issues/368
      if (readEUSStatusList.data.find(({ id }) => id === eus.statusId)?.release) {
        eus.statusId = null as unknown as number;
      }
      return eus;
    }
    return null;
  }, [readEUSById.data, readEUSStatusList.data]);

  return (
    <AuthGuard
      unauthorizedFallback={<UnauthorizedPage />}
      authorizeRole={(role) => role === UserRole.ADMIN || role === UserRole.FOREIGN_CLASSIFICATION_MANAGER}
    >
      <Layout
        maxWidth={false}
        breadcrumbs={[
          ...breadcrumbs,
          { title: readEUSById.data?.protocolNum ?? eusId, url: `${eusSection.url}/${eusId}` },
          { title: 'Modifica' },
        ]}
        inProgress={readEUSById.isLoading}
        error={readEUSById.error}
        disableGutters
        sx={{ padding: 1 }}
      >
        {eusToEdit ? (
          <EUSFormContextProvider
            initialData={eusToEdit}
            save={(data) => update(data).unwrap()}
            saveStatus={updateStatus}
          >
            <EUSEditForm />
          </EUSFormContextProvider>
        ) : (
          <NotFoundBox />
        )}
      </Layout>
    </AuthGuard>
  );
}
