import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useReadUnitOfMeasureListQuery } from '../../services/eusAttributesApi';

export function useUnitOfMeasureColDef(): GridColDef {
  const { data } = useReadUnitOfMeasureListQuery();
  const valueOptions = useMemo(() => data?.map(({ id, name }) => ({ value: id, label: name })), [data]);

  return useMemo(
    () => ({
      field: 'unitOfMeasureId',
      headerName: 'Unità di misura',
      type: 'singleSelect',
      valueOptions,
      width: 120,
    }),
    [valueOptions],
  );
}
