import { useNavigate } from 'react-router-dom';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../components/Icons';

type EUSAddButtonProps = Omit<IconButtonProps, 'onClick'>;

export function EUSAddButton(props: EUSAddButtonProps): JSX.Element {
  const { ...buttonProps } = props;
  const navigate = useNavigate();
  return (
    <>
      <Tooltip title="Nuovo End User Statement">
        <IconButton color="primary" onClick={() => navigate('/eus/add')} {...buttonProps}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
