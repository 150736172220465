import { useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  useCountryGridColDef,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, rowsPerPageOptions } from '@top-solution/microtecnica-utils';
import {
  GRID_ACTIONS_COLUMN_TYPE,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { UserRole } from '../../../entities/User';
import { Vendor, VendorSource } from '../../../entities/Vendor';
import { useReadVendorListQuery, useRemoveVendorMutation } from '../../../services/vendorApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, vendorSection } from '../../sections';
import { useCanEditVendor } from './useCanEditVendor';
import { VendorAddButton } from './VendorAddButton';
import { VendorEditDialog } from './VendorEditDialog';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: vendorSection.title }];

const initialState: GridInitialStatePremium = {
  pinnedColumns: { right: [GRID_ACTIONS_COLUMN_TYPE] },
};

export function useVendorColumns() {
  const countryColumn = useCountryGridColDef({ format: 'png' });
  return useMemo<GridColDef<Vendor>[]>(
    () => [
      { field: 'id', headerName: 'Code', width: 100 },
      { field: 'name', headerName: 'Vendor', width: 400 },
      { ...countryColumn, width: 400 },
      { field: 'da', headerName: 'DA', width: 150 },
      { field: 'lead', headerName: 'Lead', width: 150 },
    ],
    [countryColumn],
  );
}

export function VendorListPage(): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const readVendorList = useReadVendorListQuery();
  const [remove, removeStatus] = useRemoveVendorMutation();

  const canEdit = useCanEditVendor();

  const [vendorToUpdate, setVendorToUpdate] = useState<Vendor | null>(null);
  const [vendorToRemove, setVendorToRemove] = useState<Vendor | null>(null);

  const defaultColumns = useVendorColumns();
  const columns = useMemo<(GridColDef<Vendor> | GridActionsColDef<Vendor>)[]>(
    () =>
      canEdit
        ? [
            ...defaultColumns,
            {
              field: GRID_ACTIONS_COLUMN_TYPE,
              type: GRID_ACTIONS_COLUMN_TYPE,
              width: 80,
              renderHeader: () => <VendorAddButton color="primary" />,
              getActions: ({ row }) =>
                row.source === VendorSource.Manual
                  ? [
                      <GridActionsCellItem
                        icon={<EditIcon />}
                        key="edit"
                        label="Modifica"
                        onClick={() => setVendorToUpdate(row)}
                      />,
                      <GridActionsCellItem
                        icon={<DeleteIcon />}
                        key="delete"
                        label="Elimina"
                        onClick={() => setVendorToRemove(row)}
                      />,
                    ]
                  : [],
            },
          ]
        : defaultColumns,
    [canEdit, defaultColumns],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readVendorList.isLoading}
        error={readVendorList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={readVendorList.data ?? []}
            columns={columns}
            sessionStorageId="VendorListPageDataGrid"
            loading={readVendorList.isFetching}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            initialState={initialState}
            pageSizeOptions={rowsPerPageOptions}
          />
        </DataGridWrapper>
        {vendorToUpdate && (
          <VendorEditDialog initialValues={vendorToUpdate} onClose={() => setVendorToUpdate(null)} open />
        )}
        {vendorToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare il vendor "${vendorToRemove.name}"?`}
            confirmText={vendorToRemove.id}
            onConfirm={async () => {
              await remove(vendorToRemove).unwrap();
              setVendorToRemove(null);
            }}
            inProgress={removeStatus.isLoading}
            error={removeStatus.error}
            onClose={() => setVendorToRemove(null)}
            open
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
