import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { SelectCustomerListDialog } from '../../../components/Dialog/SelectCustomerListDialog';
import { SelectEndUserListDialog } from '../../../components/Dialog/SelectEndUserListDialog';
import { SelectItemListDialogProps } from '../../../components/Dialog/SelectItemListDialogProps';
import { SelectPNListDialog } from '../../../components/Dialog/SelectPNListDialog';
import { SelectProgramListDialog } from '../../../components/Dialog/SelectProgramListDialog';
import { AddIcon, SearchIcon } from '../../../components/Icons';
import { TopDownSearchParams, TopDownSearchParamsSchema, TopDownSearchResult } from '../../../entities/TopDownSearch';
import { useLazyTopDownSearchQuery } from '../../../services/searchApi';

const params = new Map<keyof TopDownSearchParams, string>([
  ['pn', 'P/N'],
  ['program', 'Program'],
  ['customer', 'Customer'],
  ['enduser', 'End User'],
]);

type AddFilterDialog = (props: SelectItemListDialogProps<string>) => JSX.Element;

const addFilterDialog = new Map<keyof TopDownSearchParams, AddFilterDialog>([
  ['pn', SelectPNListDialog],
  ['program', SelectProgramListDialog],
  ['customer', SelectCustomerListDialog],
  ['enduser', SelectEndUserListDialog],
]);

type TopDownSearchFormProps = PaperProps & {
  searchParams: TopDownSearchParams;
  onResultLoaded: (results: TopDownSearchResult, params: TopDownSearchParams) => void;
};

export function TopDownSearchForm(props: TopDownSearchFormProps): JSX.Element {
  const { searchParams, onResultLoaded, sx, ...paperProps } = props;
  const [search, status] = useLazyTopDownSearchQuery();
  const [addFilterDialogKey, setAddFilterDialogKey] = useState<keyof TopDownSearchParams | null>(null);
  const AddFilterDialog = useMemo(
    () => (addFilterDialogKey ? addFilterDialog.get(addFilterDialogKey) : undefined),
    [addFilterDialogKey],
  );

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TopDownSearchParams>({
    defaultValues: searchParams,
    resolver: zodResolver(TopDownSearchParamsSchema),
  });

  const onSubmit = useCallback(
    async (params: TopDownSearchParams) => {
      const result = await search(params).unwrap();
      if (result.hierarchy.length > 0) {
        onResultLoaded(result, params);
      }
    },
    [onResultLoaded, search],
  );

  const parseMultilineInputValue = useCallback((value: string): string[] => {
    if (value) {
      const set = new Set<string>();
      const list = new Array<string>();
      value.split('\n').forEach((item, index, array) => {
        if (index === array.length - 1) {
          list.push(item);
        } else {
          const trimmedValue = item.trim();
          if (trimmedValue && !set.has(trimmedValue)) {
            list.push(trimmedValue);
          }
          if (trimmedValue) {
            set.add(trimmedValue);
          }
        }
      });
      return list;
    }
    return [];
  }, []);

  return (
    <Paper sx={{ padding: 2, margin: 1, ...sx }} {...paperProps}>
      <Grid container spacing={2}>
        {[...params].map(([key, label]) => (
          <Grid item sm xs={6} key={key}>
            <Controller
              control={control}
              name={key}
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    multiline
                    label={`Filtro ${label}`}
                    minRows={15}
                    maxRows={25}
                    error={Boolean(error || (errors && 'atLeastOne' in errors))}
                    helperText={
                      error?.message || (errors as { atLeastOne?: { message?: string } })?.atLeastOne?.message
                    }
                    value={value?.join('\n') || ''}
                    {...field}
                    onChange={(event) => onChange(parseMultilineInputValue(event.target.value))}
                    disabled={status.isFetching}
                    fullWidth
                  />
                  <Tooltip title={`Aggiungi ${label}`}>
                    <IconButton
                      onClick={() => setAddFilterDialogKey(key)}
                      color="secondary"
                      sx={{ position: 'absolute', top: -4, right: -4 }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ marginY: 0 }}>
        <Grid item sm xs={12}>
          {status.error ? (
            <ErrorAlert error={status.error} />
          ) : status.data?.hierarchy.length === 0 ? (
            <Alert severity="info">La ricerca non ha prodotto risultati</Alert>
          ) : null}
        </Grid>
        <Grid item md={3} xs={12}>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SearchIcon />}
            loading={status.isFetching}
            fullWidth
          >
            Cerca
          </LoadingButton>
        </Grid>
      </Grid>
      {AddFilterDialog && addFilterDialogKey && (
        <AddFilterDialog
          open={true}
          onClose={(value) => {
            if (addFilterDialogKey && value) {
              const previousValue = getValues(addFilterDialogKey);
              const set = new Set<string>([...previousValue, ...value]);
              setValue(addFilterDialogKey, [...set]);
            }
            setAddFilterDialogKey(null);
          }}
        />
      )}
    </Paper>
  );
}
