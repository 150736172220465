import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { HistoryEntity, HistoryEntitySchema, HistoryRecord, HistoryRecordSchema } from '../entities/History';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/audit';

const historyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readAuditEntityList: builder.query<HistoryEntity[], void>({
      query: () => `${url}/entities`,
      transformResponse: (data) => HistoryEntitySchema.array().parse(data),
      keepUnusedDataFor: 3600,
    }),
    readAuditHistory: builder.query<PagedResponse<HistoryRecord>, PagedRequestParams>({
      query: (params) => ({ url: `${url}/history`, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(HistoryRecordSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.HISTORY, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadAuditEntityListQuery, useReadAuditHistoryQuery } = historyApi;
