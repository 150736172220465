import { GridColDef } from '@mui/x-data-grid-premium';

const valueOptions = [
  {
    label: 'Make',
    value: true,
  },
  {
    label: 'Buy',
    value: false,
  },
];

export const makeBuyColumn: GridColDef = {
  field: 'isMake',
  headerName: 'Make/Buy',
  type: 'singleSelect',
  valueOptions,
  width: 100,
  sortable: false,
};
