import { useReadItemInfoQuery } from '@top-solution/microtecnica-utils';
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';

type ItemDescriptionProps = Omit<TypographyProps, 'children'> & {
  pn: string;
};

export function ItemDescription(props: ItemDescriptionProps): JSX.Element {
  const { pn, ...typographyProps } = props;
  const readItemInfo = useReadItemInfoQuery(pn);

  if (readItemInfo.isLoading) {
    return <Skeleton variant="text" width={`${pn.length}ch`} />;
  }

  if (readItemInfo.isError) {
    return (
      <Typography component="span" variant="caption" {...typographyProps} color="error">
        Errore richiesta descrizione P/N {pn}
      </Typography>
    );
  }

  return (
    <Typography component="span" {...typographyProps}>
      {readItemInfo.data?.description}
    </Typography>
  );
}
