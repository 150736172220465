import { useCallback, useMemo, useState, forwardRef } from 'react';
import { FilterOperator } from '@top-solution/microtecnica-utils';
import debounce from 'lodash.debounce';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Customer } from '../../entities/Customer';
import { useLazyReadCustomerListQuery } from '../../services/customerApi';

type CustomerAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<Customer, Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'required'>;

function CustomerAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: CustomerAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { label, error, value, helperText, required, ...autocompleteProps } = props;
  const [needle, setNeedle] = useState('');
  const [searchCustomer, { data, error: requestError, isLoading }] = useLazyReadCustomerListQuery();

  const search = useMemo(
    () =>
      debounce(
        (needle: string) => {
          searchCustomer({
            offset: 0,
            limit: 10,
            sort: ['name'],
            filters: [{ field: 'name', operator: FilterOperator.like, value: `${needle}%` }],
          });
        },
        300,
        { leading: false, trailing: true },
      ),
    [searchCustomer],
  );

  const options = useMemo(() => {
    let options = new Array<Customer>();
    if (value) {
      options = [value as Customer];
    }
    if (needle.length >= 3 && data) {
      options = [...options, ...data.data];
    }
    return options;
  }, [data, needle, value]);

  const getOptionLabel = useCallback(
    (option: Customer | string) => {
      if (typeof option === 'string') {
        return option;
      }
      if (autocompleteProps.getOptionLabel) {
        return autocompleteProps.getOptionLabel(option);
      }
      return `${option.name} – ${option.id}`;
    },
    [autocompleteProps],
  );

  const handleInputChange = useCallback(
    (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
      setNeedle(value);
      if (reason === 'input' && value.length >= 3) {
        search(value);
      }
    },
    [search],
  );

  return (
    <Autocomplete
      autoComplete
      value={value}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputRef={ref}
          error={Boolean(requestError) || error}
          helperText={(requestError && 'message' in requestError && requestError.message) || helperText || undefined}
          required={required}
        />
      )}
      noOptionsText={needle.length < 3 ? 'Digita almeno 3 caratteri' : undefined}
      loading={isLoading}
      clearOnEscape
      {...autocompleteProps}
    />
  );
}

export default forwardRef(CustomerAutocomplete) as typeof CustomerAutocomplete;
