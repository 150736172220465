import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { Supplier, SupplierSchema } from '../entities/Supplier';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/suppliers';

const suppliersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSupplierList: builder.query<PagedResponse<Supplier>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(SupplierSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.SUPPLIER, id: 'LIST' }],
    }),
    createSupplier: builder.mutation<void, Supplier>({
      query: (params) => ({
        url,
        method: 'POST',
        body: {
          pn: params.pn,
          vendorId: params.vendor.id,
          pgrId: params.pgr.id,
        },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SUPPLIER, id: 'LIST' }],
    }),
    removeSupplier: builder.mutation<void, Supplier>({
      query: (params) => ({
        url: `${url}/${params.pn}/vendor/${params.vendor.id}/pgr/${params.pgr.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.SUPPLIER, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadSupplierListQuery, useCreateSupplierMutation, useRemoveSupplierMutation } = suppliersApi;
