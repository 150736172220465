import { getGridDateOperators, GridColDef } from '@mui/x-data-grid-premium';

export const stringDateColumn: GridColDef = {
  field: 'date',
  headerName: 'Data',
  type: 'date',
  filterOperators: getGridDateOperators().filter(({ value }) => {
    return value !== 'is' && value !== 'not' && value !== 'isEmpty' && value !== 'isNotEmpty' && value !== 'isAnyOf';
  }),
  valueGetter: (value) => value && new Date(value),
  valueFormatter: (value) =>
    (value as Date)?.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }),
};
