import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ECCN } from '../../entities/ECCN';
import { useReadECCNListQuery } from '../../services/eccnApi';

type ECCNMap = Record<string, ECCN>;

type UseECCN = ReturnType<typeof useReadECCNListQuery> & {
  list?: ECCN[];
  map?: ECCNMap;
};

export function useECCN(): UseECCN {
  const { data: list, ...rtkHookStatus } = useReadECCNListQuery();
  const map = useMemo(() => {
    if (list) {
      return list.reduce((map, eccn) => {
        map[eccn.id] = eccn;
        return map;
      }, {} as ECCNMap);
    }
  }, [list]);

  return {
    ...rtkHookStatus,
    list,
    map,
  };
}

export function useECCNGridColDef(): GridColDef {
  const { list } = useECCN();
  const valueOptions = useMemo(() => list?.map(({ id }) => id), [list]);
  return useMemo(
    () => ({
      field: 'eccn',
      headerName: 'ECCN',
      type: 'singleSelect',
      valueOptions,
    }),
    [valueOptions],
  );
}
