import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  DeleteConfirmDialog,
  useCountryGridColDef,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridFilterModel,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';
import { isMilitaryColumn } from '../../../components/DataGrid';
import { EditIcon, DeleteIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { ECCN } from '../../../entities/ECCN';
import { UserRole } from '../../../entities/User';
import {
  useReadECCNLegislationListQuery,
  useReadECCNListQuery,
  useRemoveECCNMutation,
} from '../../../services/eccnApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, eccnSection } from '../../sections';
import { ECCNAddButton } from './ECCNAddButton';
import { ECCNEditDialog } from './ECCNEditDialog';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: eccnSection.title }];
const pinnedColumns = { right: ['actions'] };

export function ECCNListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();

  const countryColumn = useCountryGridColDef({ format: 'png' });
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const [eccnToRemove, setEccnToRemove] = useState<ECCN | null>(null);
  const [eccnToEdit, setEccnToEdit] = useState<ECCN | null>(null);
  const legislationList = useReadECCNLegislationListQuery();
  const readECCNList = useReadECCNListQuery();
  const [removeECCN, removeECCNStatus] = useRemoveECCNMutation();

  const handleDeleteECCNConfirm = useCallback(async () => {
    if (eccnToRemove) {
      await removeECCN(eccnToRemove).unwrap();
      setEccnToRemove(null);
    }
  }, [removeECCN, eccnToRemove]);

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const columns: (GridColDef | GridActionsColDef)[] = [
      { field: 'id', headerName: 'ECCN', flex: 1 },
      {
        ...isMilitaryColumn,
        flex: 1,
        editable: true,
        sortable: true,
      },
      {
        ...countryColumn,
        flex: 1,
        editable: true,
        sortable: true,
      },
      {
        field: 'legislation',
        headerName: 'Normativa',
        type: 'singleSelect',
        valueOptions: legislationList.data ?? [],
        flex: 1,
        editable: true,
        sortable: true,
      },
      {
        field: 'availableToManufacturers',
        headerName: 'Disponibile manufacturer',
        type: 'boolean',
        flex: 1,
        editable: true,
        sortable: true,
      },
    ];
    if (isAdmin || !eccnSection.role || hasRole(eccnSection.role)) {
      columns.push({
        field: 'actions',
        type: 'actions',
        renderHeader: () => <ECCNAddButton />,
        getActions: ({ row }: GridRowParams<ECCN>) => [
          <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={() => setEccnToEdit(row)} />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={() => setEccnToRemove(row)}
          />,
        ],
      });
    }
    return columns;
  }, [countryColumn, hasRole, isAdmin, legislationList.data]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readECCNList.isLoading}
        error={readECCNList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={readECCNList.data ?? []}
            columns={columns}
            sessionStorageId="ECCNListPageDataGrid"
            loading={readECCNList.isFetching}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            pinnedColumns={pinnedColumns}
          />
        </DataGridWrapper>
        {eccnToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare l’ECCN "${eccnToRemove.id}"?`}
            confirmText="confermo"
            open={Boolean(eccnToRemove)}
            inProgress={removeECCNStatus.isLoading}
            error={removeECCNStatus.error}
            onConfirm={() => handleDeleteECCNConfirm()}
            onClose={() => setEccnToRemove(null)}
          />
        )}
        {eccnToEdit && (
          <ECCNEditDialog open={Boolean(eccnToEdit)} initialValues={eccnToEdit} onClose={() => setEccnToEdit(null)} />
        )}
      </Layout>
    </AuthGuard>
  );
}
