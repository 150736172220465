import { useMemo } from 'react';
import { DataGrid, DataGridProps, ErrorAlert, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { differenceInMonths, format } from 'date-fns';
import { it } from 'date-fns/locale';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { stringDateColumn } from '../../components/DataGrid';
import { AlertIcon } from '../../components/Icons';
import { ExportLicence } from '../../entities/ExportLicence';
import { useReadExportLicenceListQuery } from '../../services/ExportLicenceApi';
import { NOT_AVAILABLE } from '../../utils/utils';
import { ShowButtonVendorListDialog } from './ShowVendorListDialog';

interface ExportLicenceRow extends ExportLicence {
  showQuantityValueWarning: boolean;
  techData: string;
}

type ExportLicenceListDataGridProps = Omit<DataGridProps, 'rows' | 'columns' | 'loading'>;

function showQuantityValueWarning(row: ExportLicence) {
  if (row.authorizationType === 'Licenza di intermediazione' && row.movementType === 'Acquisto') return true;
  if (
    row.authorizationType === 'Licenza individuale' &&
    (row.movementType === 'Esportazione temporanea e successiva reimportazione' ||
      row.movementType === 'Esportazione definitiva' ||
      row.movementType === 'Importazione definitiva')
  )
    return true;
  if (
    row.authorizationType === 'Licenza Framework Agreement' &&
    row.movementType === 'Esportazione temporanea e successiva reimportazione'
  )
    return true;
  return false;
}

export function ExportLicenceListDataGrid(props: ExportLicenceListDataGridProps) {
  const { data: countriesData } = useReadCountriesQuery();
  const countryColumn = useCountryGridColDef({ format: 'png' });

  const list = useReadExportLicenceListQuery();

  const { rows, movementTypes, authorizationTypes } = useMemo(() => {
    const rows = [];
    const authorizationTypes = new Set<string>();
    const movementTypes = new Set<string>();

    for (const row of list.data ?? []) {
      rows.push({
        ...row,
        techData:
          row.authorizationType.toUpperCase() === 'AGT3'
            ? 'Trasferimento tangibile (cartaceo)'
            : row.authorizationType.toUpperCase().startsWith('LGT')
              ? 'Trasferimento tangibile (CD/DVD)'
              : row.authorizationType.toUpperCase() === 'Trasferimento tangibile'.toUpperCase()
                ? 'Trasferimento tangibile'
                : row.authorizationType.toUpperCase() === 'Licenza di trasferimento intangibile'.toUpperCase()
                  ? 'Trasferimento intangibile'
                  : 'No',
        showQuantityValueWarning: showQuantityValueWarning(row),
      });
      authorizationTypes.add(row.authorizationType);
      if (row.movementType) {
        movementTypes.add(row.movementType);
      }
    }

    return {
      rows,
      movementTypes: Array.from(movementTypes),
      authorizationTypes: Array.from(authorizationTypes),
    };
  }, [list.data]);

  const columns = useMemo<GridColDef<ExportLicenceRow>[]>(
    () => [
      {
        field: 'authorization',
        headerName: 'Autorizzazione (protocollo ministero)',
        width: 90,
      },
      {
        ...stringDateColumn,
        field: 'releaseDate',
        headerName: 'Data rilascio',
        valueFormatter: (value) => value && format(new Date(value), 'P', { locale: it }),
        width: 200,
      },
      {
        ...stringDateColumn,
        field: 'expiryDate',
        headerName: 'Data scadenza',
        valueFormatter: (value) => (value ? format(new Date(value), 'P', { locale: it }) : NOT_AVAILABLE),
        cellClassName: ({ value }: GridCellParams<GridRowModel, string>) => {
          const monthsDifference = differenceInMonths(new Date(value || ''), new Date());
          return monthsDifference <= 6 && monthsDifference > 0 ? 'AlertExpired' : '';
        },
        width: 200,
      },
      {
        field: 'requiredBy',
        headerName: 'Richiedente',
        width: 200,
      },
      {
        field: 'authorizationType',
        headerName: 'Tipo di autorizzazione',
        type: 'singleSelect',
        valueOptions: authorizationTypes,
        width: 200,
      },
      {
        field: 'movementType',
        headerName: 'Tipo di movimentazione',
        type: 'singleSelect',
        valueOptions: movementTypes,
        width: 200,
      },
      {
        field: 'destinationName',
        headerName: 'Nome destinatario/mittente',
        width: 300,
        renderCell: ({ value }) =>
          value && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <ShowButtonVendorListDialog vendorName={value} />
              <Typography>{value}</Typography>
            </Box>
          ),
      },
      {
        field: 'destinationAddress',
        headerName: 'Indirizzo destinatario/mittente',
        width: 200,
      },
      {
        ...countryColumn,
        field: 'destinationCountry',
        headerName: 'Paese destinatario',
      },
      {
        field: 'ueCountry',
        headerName: 'UE / NON UE',
        width: 120,
        type: 'boolean',
        valueGetter: (value, row) =>
          row.destinationCountry ? Boolean(countriesData?.byISO[row.destinationCountry]?.isEU) : false,
        valueFormatter: ({ value }) => (value ? 'UE' : 'NON UE'),
        renderCell: ({ formattedValue }) => formattedValue,
      },
      {
        field: 'orderNumber',
        headerName: "Numero d'ordine",
        width: 200,
      },
      {
        field: 'program',
        headerName: 'Programma',
        width: 200,
      },
      {
        field: 'equipment',
        headerName: 'Equipaggiamento',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'Descrizione materiale',
        width: 200,
      },
      {
        field: 'pn',
        headerName: 'Part Number',
        width: 200,
        renderCell: ({ value, row }) =>
          row.authorizationType.startsWith('LGP') || row.authorizationType.startsWith('LGT') ? (
            <Tooltip title="Attenzione!!! Verificare sui file GT dedicati alla licenza che il P/N sia coperto">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AlertIcon color="warning" />
                <Typography ml={1}>{value}</Typography>
              </Box>
            </Tooltip>
          ) : (
            <Typography>{value}</Typography>
          ),
      },
      {
        field: 'quantityValue',
        headerName: 'Quantità/Valori',
        renderCell: ({ value, row }) =>
          value && row.showQuantityValueWarning ? (
            <Tooltip title="Attenzione!!! La licenza prevede quantità e valori specifici - è necessario verificarne la capienza nei file dedicati GT">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AlertIcon color="warning" />
                <Typography ml={1}>{value}</Typography>
              </Box>
            </Tooltip>
          ) : (
            <Typography>{value}</Typography>
          ),
        width: 200,
      },
      {
        field: 'enduser',
        headerName: 'Utilizzatore Finale',
        width: 200,
      },
      {
        field: 'techData',
        headerName: 'Dati tecnici',
        width: 250,
      },
    ],
    [authorizationTypes, countriesData?.byISO, countryColumn, movementTypes],
  );

  if (list.error) {
    return <ErrorAlert error={list.error} />;
  }

  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columns}
      sessionStorageId="ExportLicenceListPageDataGrid"
      loading={list.isFetching}
      pagination
      {...props}
    />
  );
}
