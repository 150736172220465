import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridProps,
  ErrorAlert,
  muiFiltersToPagedRequestFilters,
  useCountryGridColDef,
  usePagination,
} from '@top-solution/microtecnica-mui';
import {
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { useReadCustomerListQuery } from '../../services/customerApi';

type CustomerListDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
>;

export function CustomerListDataGrid(props: CustomerListDataGridProps): JSX.Element {
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const readCustomerListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const customerList = useReadCustomerListQuery(readCustomerListParams);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const countryColumn = useCountryGridColDef({ format: 'png' });
  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      { field: 'id', headerName: 'Code', width: 100 },
      { field: 'name', headerName: 'Nome', width: 250 },
      { ...countryColumn, width: 150 },
      {
        field: 'address',
        headerName: 'Indirizzo',
        minWidth: 150,
        flex: 1,
        sortable: false,
      },
    ],
    [countryColumn],
  );

  if (customerList.error) {
    return <ErrorAlert error={customerList.error} />;
  }

  return (
    <DataGrid
      rows={customerList.data?.data ?? []}
      columns={columns}
      sessionStorageId="CustomerListDataGrid"
      loading={customerList.isFetching}
      pagination
      paginationMode="server"
      rowCount={customerList.data?.total ?? 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
      filterModel={filterModel}
      {...props}
    />
  );
}
