import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { TechDataClassification, TechDataClassificationSchema } from '../entities/TechDataClassification';
import { api } from './baseApi';

const url = 'v1/tech-data-classifications';

const italianClassificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readTechDataClassificationList: builder.query<PagedResponse<TechDataClassification>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(TechDataClassificationSchema).parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadTechDataClassificationListQuery } = italianClassificationApi;
