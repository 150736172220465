import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-premium';

export const CIVIL = 'Civile';
export const MILITARY = 'Militare';

function renderEditCell(props: GridRenderEditCellParams): JSX.Element {
  const { api, id, field, value } = props;
  const handleChange = (event: unknown, value: unknown) => {
    api.setEditCellValue({ id, field, value });
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>{CIVIL}</Typography>
      <FormControlLabel control={<Switch onChange={handleChange} checked={value as boolean} />} label="" />
      <Typography>{MILITARY}</Typography>
    </Stack>
  );
}

const valueOptions = [
  { value: false, label: CIVIL },
  { value: true, label: MILITARY },
];

export const isMilitaryColumn: GridColDef = {
  field: 'isMilitary',
  headerName: 'Classificazione',
  minWidth: 150,
  type: 'singleSelect',
  valueOptions,
  renderEditCell,
};
