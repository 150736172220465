import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon, SaveIcon } from '../../../components/Icons';
import {
  ForeignClassificationAddForm,
  ForeignClassificationAddFormSchema,
} from '../../../entities/ForeignClassification';
import { useCreateForeignClassificationMutation } from '../../../services/foreignClassificationApi';
import { ForeignClassificationEditForm } from './ForeignClassificationEditForm';

export type ForeignClassificationAddButtonProps = Omit<IconButtonProps, 'onClick'>;

const defaultValues = {
  pn: null,
  vendor: null,
  pgr: null,
  vendorPn: '',
  manufacturer: '',
  country: null,
  eccn: null,
  htsn: '',
  notes: '',
} as unknown as ForeignClassificationAddForm;

export function ForeignClassificationAddButton(props: ForeignClassificationAddButtonProps): JSX.Element {
  const { ...buttonProps } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [create, createStatus] = useCreateForeignClassificationMutation();
  const form = useForm<ForeignClassificationAddForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(ForeignClassificationAddFormSchema),
  });
  const { handleSubmit, reset } = form;

  const close = useCallback(() => {
    setDialogOpen(false);
    reset();
    createStatus.reset();
  }, [createStatus, reset]);

  const onSubmit = useCallback(
    async (values: ForeignClassificationAddForm) => {
      await create(values).unwrap();
      close();
    },
    [close, create],
  );

  return (
    <>
      <Tooltip title="Nuova classificazione estera">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...buttonProps}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Nuova classificazione estera</DialogTitle>
        <DialogContent sx={{ '.FormRow-root': { mt: 1, '& > *': { flex: 1 } } }}>
          <ForeignClassificationEditForm form={form} />
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => close()}>
            Annulla
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={createStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
