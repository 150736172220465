import { ImportStatus, ImportStatusSchema } from '../entities/ImportStatus';
import { api } from './baseApi';

const url = 'v1/import-status';

const importStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readImportStatusList: builder.query<ImportStatus[], void>({
      query: () => url,
      transformResponse: (data) => ImportStatusSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadImportStatusListQuery } = importStatusApi;
