import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { add } from 'date-fns';
import { ZodIssue, z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { EUS, EUSCompleteSchema, EUSFieldLabelMap } from '../../entities/EUS';
import { useReleaseEUSMutation } from '../../services/eusApi';

type EUSNotReleasableDialogProps = DialogProps & {
  eus: EUS;
  issues: ZodIssue[];
};

function EUSNotReleasableDialog(props: EUSNotReleasableDialogProps) {
  const { eus, issues, ...dialogProps } = props;

  const issueList = useMemo(
    () => (
      <ul>
        {issues.map((issue) => {
          const key = issue.path.join(', ');
          let label;

          if (issue.path[0] === 'pnList' && typeof issue.path[1] === 'number' && typeof issue.path[2] === 'string') {
            label = `P/N ${eus.pnList[issue.path[1]].pn}`;
            if (issue.path[2] === 'poList' && typeof issue.path[3] === 'number' && typeof issue.path[4] === 'string') {
              label = `${label} - ${EUSFieldLabelMap.po} ${eus.pnList[issue.path[1]].poList[issue.path[3]].po}: ${
                EUSFieldLabelMap[issue.path[4]]
              }`;
            } else if (
              issue.path[2] === 'customerList' &&
              typeof issue.path[3] === 'number' &&
              typeof issue.path[4] === 'string'
            ) {
              const item = eus.pnList[issue.path[1]].customerList[issue.path[3]];
              const components = [];
              if (item.enditem) {
                components.push(`${EUSFieldLabelMap.enditem} ${item.enditem}`);
              }
              if (item.programId) {
                components.push(`${EUSFieldLabelMap.programId} ${item.programId}`);
              }
              if (item.customer) {
                components.push(`${EUSFieldLabelMap.customer} ${item.customer.id}`);
              }
              if (item.shipTo) {
                components.push(`${EUSFieldLabelMap.shipTo} ${item.shipTo.id}`);
              }
              label = `${label} - ${components.join(' – ')}: ${EUSFieldLabelMap[issue.path[4] ?? issue.path[4]]}`;
            }
          } else if (
            issue.path[0] === 'enduserList' &&
            typeof issue.path[1] === 'number' &&
            typeof issue.path[2] === 'string'
          ) {
            label = `${EUSFieldLabelMap.enduserList} ${eus.enduserList[issue.path[1]].enduser.id}: ${
              EUSFieldLabelMap[issue.path[2] ?? issue.path[2]]
            }`;
          }

          if (!label) {
            const components = issue.path.slice(0, -1).map((property) => EUSFieldLabelMap[property] ?? property);
            const lastItem = issue.path[issue.path.length - 1];
            label = typeof lastItem === 'number' ? `${lastItem + 1}` : (EUSFieldLabelMap[lastItem] ?? lastItem);
            if (components.length > 0) {
              label = `${components.join(' - ')}: ${label}`;
            }
          }

          return <li key={key}>{label}</li>;
        })}
      </ul>
    ),
    [eus, issues],
  );

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Impossibile Emettere EUS</DialogTitle>
      <DialogContent>
        <Typography>
          La pratica {eus.protocolNum} non è completa, verificare la compilazione dei seguenti campi:
        </Typography>
        {issueList}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={(e) => dialogProps.onClose?.(e, 'escapeKeyDown')}>
          Chiudi
        </Button>
        <Button variant="contained" color="primary" component={Link} to="./edit">
          Modifica
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EUSReleaseDialogFormSchema = z.object({
  releaseDate: z.date(),
});

type EUSReleaseDialogForm = z.infer<typeof EUSReleaseDialogFormSchema>;

type EUSReleaseDialogProps = DialogProps & {
  eus: EUS;
};

function EUSReleaseDialog(props: EUSReleaseDialogProps) {
  const { eus, TransitionProps, ...dialogProps } = props;
  const { handleSubmit, control, reset } = useForm<EUSReleaseDialogForm>({
    defaultValues: {
      releaseDate: eus.updatedDate
        ? new Date(eus.updatedDate)
        : eus.eusReleaseDate
          ? new Date(eus.eusReleaseDate)
          : new Date(),
    },
    resolver: zodResolver(EUSReleaseDialogFormSchema),
  });
  const [release, releaseStatus] = useReleaseEUSMutation();

  const onSubmit = useCallback(
    async ({ releaseDate }: EUSReleaseDialogForm) => {
      await release({
        id: eus.id,
        // If there's a previous EUS release date, keep it, otherwise use the selected one
        eusReleaseDate: eus.eusReleaseDate ? eus.eusReleaseDate : releaseDate.toISOString(),
        // If there's a previous EUS release date, use the selected one as update date
        updatedDate: eus.eusReleaseDate ? releaseDate.toISOString() : undefined,
      }).unwrap();
      props.onClose?.({}, 'escapeKeyDown');
    },
    [eus, props, release],
  );

  const hasExpire = eus.supplierLicenseTypeDuration !== undefined && eus.supplierLicenseTypeDuration > -1;

  return (
    <Dialog
      {...dialogProps}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          reset();
          releaseStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Emissione EUS {eus.protocolNum}</DialogTitle>
        <DialogContent>
          {hasExpire && (
            <Typography>
              {`L’emissione del documento EUS assegnerà una data di scadenza ${eus.supplierLicenseTypeDuration} giorni dopo la data di emissione.`}
            </Typography>
          )}
          <Controller
            control={control}
            name="releaseDate"
            render={({ field, fieldState: { error } }) => (
              <Stack
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 1,
                  marginY: 2,
                }}
              >
                <DatePicker
                  label={`Data ${eus.eusReleaseDate ? 'riemissione' : 'emissione'} EUS`}
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: Boolean(error),
                      helperText: error?.message ?? ' ',
                    },
                  }}
                />

                {hasExpire && (
                  <DatePicker
                    label="Data scadenza EUS"
                    value={field.value ? add(field.value, { days: eus.supplierLicenseTypeDuration }) : null}
                    disabled
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                  />
                )}
              </Stack>
            )}
          />
          {releaseStatus.error && <ErrorAlert error={releaseStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={(e) => dialogProps.onClose?.(e, 'escapeKeyDown')}>
            Annulla
          </Button>
          <LoadingButton type="submit" variant="contained" color="primary" loading={releaseStatus.isLoading}>
            Emissione EUS
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface EUSRealeaseProps {
  eus: EUS;
}

export function EUSRealease(props: EUSRealeaseProps): JSX.Element {
  const { eus } = props;
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);

  const parseResult = useMemo(() => EUSCompleteSchema.safeParse(eus), [eus]);

  return (
    <>
      <Tooltip title="Emetti Documento EUS">
        <Button variant="outlined" color="primary" onClick={() => setReleaseDialogOpen(true)}>
          Emetti
        </Button>
      </Tooltip>
      {parseResult.success ? (
        <EUSReleaseDialog eus={eus} open={releaseDialogOpen} onClose={() => setReleaseDialogOpen(false)} />
      ) : (
        <EUSNotReleasableDialog
          eus={eus}
          issues={parseResult.error.issues}
          open={releaseDialogOpen}
          onClose={() => setReleaseDialogOpen(false)}
        />
      )}
    </>
  );
}
