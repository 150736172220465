import { EUS, EUSEditModel, EUSSchema } from '../entities/EUS';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/eus-statement';

const eusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEUSList: builder.query<EUS[], void>({
      query: () => url,
      transformResponse: (data) => EUSSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.EUS, id: 'LIST' }],
    }),
    readEUSById: builder.query<EUS, EUS['id']>({
      query: (id) => `${url}/${id}`,
      transformResponse: (data) => EUSSchema.parse(data),
      providesTags: (response, error, id) => [{ type: TAG_TYPES.EUS, id }],
    }),
    createEUS: builder.mutation<EUS, EUSEditModel>({
      query: ({ pgr, vendor, ...eus }) => ({
        url,
        method: 'POST',
        body: { ...eus, pgrId: pgr?.id, vendorId: vendor?.id },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.EUS, id: 'LIST' }],
    }),
    updateEUS: builder.mutation<EUS, EUSEditModel>({
      query: ({ pgr, vendor, ...eus }) => ({
        url: `${url}/${eus.id}`,
        method: 'PUT',
        body: { ...eus, pgrId: pgr?.id, vendorId: vendor?.id },
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: TAG_TYPES.EUS, id: 'LIST' },
        { type: TAG_TYPES.EUS, id: id!.toString() },
      ],
    }),
    removeEUS: builder.mutation<void, EUS>({
      query: (eus) => ({ url: `${url}/${eus.id}`, method: 'DELETE' }),
      invalidatesTags: (response, error, { id }) => [
        { type: TAG_TYPES.EUS, id: 'LIST' },
        { type: TAG_TYPES.EUS, id },
      ],
    }),
    releaseEUS: builder.mutation<void, Pick<EUS, 'id' | 'eusReleaseDate' | 'updatedDate'>>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}/release`, method: 'PUT', body }),
      invalidatesTags: (response, error, { id }) => [
        { type: TAG_TYPES.EUS, id: 'LIST' },
        { type: TAG_TYPES.EUS, id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadEUSListQuery,
  useLazyReadEUSListQuery,
  useReadEUSByIdQuery,
  useCreateEUSMutation,
  useUpdateEUSMutation,
  useRemoveEUSMutation,
  useReleaseEUSMutation,
} = eusApi;
