import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import blueGrey from '@mui/material/colors/blueGrey';
import indigo from '@mui/material/colors/indigo';
import teal from '@mui/material/colors/teal';
import Stack from '@mui/material/Stack';
import { UnauthorizedPage } from '..';
import { AppSectionListRow } from '../../components/AppSectionListRow';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { bottomUpSearch, topDownSearch, adminSection, eusSection, italianExportLicense } from '../sections';

export default function HomePage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters>
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'column',
            '.MuiStack-root': {
              flexDirection: 'row',
            },
          }}
        >
          <AppSectionListRow list={[bottomUpSearch, topDownSearch]} color={indigo[500]} />
          {(isAdmin || !hasRole(UserRole.USER)) && (
            <AppSectionListRow list={[eusSection, italianExportLicense]} color={teal[500]} />
          )}
          {(isAdmin || !hasRole(UserRole.USER)) && <AppSectionListRow list={[adminSection]} color={blueGrey[500]} />}
        </Stack>
      </Layout>
    </AuthGuard>
  );
}
