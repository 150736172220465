import { useContext } from 'react';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { EUSEditCustomerItem } from '../../../entities/EUS';
import { createId, isAutoGeneratedRow, rowIdSeparator } from '../../DataGrid';
import { customerIdColumn, useColumns } from './columns';
import { getRowId as getPreviousStepRowId, getRecipientId as getPreviousStepRecipientId } from './EUSEditCustomerForm';
import { EUSFormContext } from './EUSFormContext';
import { EUSRecipientPropertySelectionStep } from './EUSRecipientPropertySelectionStep';
import { useBottomUpSearchParams } from './useBottomUpSearchParams';

export const getRowId = (row: BottomUpSearchResult) =>
  createId([row.item, row.enditem, row.programId, row.customerId, row.shipToId]);

export const getRecipientId = (pn: string, recipient: EUSEditCustomerItem) =>
  createId([pn, recipient.enditem, recipient.programId, recipient.customerId, recipient.shipToId]);

const getGroupId = (recipient: EUSEditCustomerItem) =>
  createId([recipient.enditem, recipient.programId, recipient.customerId]);

const createRecipient = (groupId: string, propertyId?: string): EUSEditCustomerItem => {
  const [enditem, programId, customerId] = groupId.split(rowIdSeparator);
  return {
    enditem,
    programId,
    customerId,
    shipToId: propertyId ?? null,
  };
};

const getRecipientProperty = (recipient: EUSEditCustomerItem) => recipient.shipToId ?? '';

const initialState: DataGridPremiumProps['initialState'] = {
  rowGrouping: {
    model: ['item', 'enditem', 'programId', 'customerId'],
  },
  columns: {
    columnVisibilityModel: { item: false, enditem: false, programId: false, customerId: false, customerName: false },
  },
};

export function EUSEditShipToForm(): JSX.Element {
  const { data } = useContext(EUSFormContext);

  const bottomUpSearchParams = useBottomUpSearchParams(data, { enditem: true, program: true, customer: true });

  const columns = useColumns({ item: true, enditem: true, program: true, customer: false, shipTo: true }, [
    customerIdColumn,
  ]);

  return (
    <EUSRecipientPropertySelectionStep
      bottomUpSearchParams={bottomUpSearchParams}
      createRecipient={createRecipient}
      getRecipientProperty={getRecipientProperty}
      getRowId={getRowId}
      getRecipientId={getRecipientId}
      getPreviousStepRowId={getPreviousStepRowId}
      getPreviousStepRecipientId={getPreviousStepRecipientId}
      getGroupId={getGroupId}
      columns={columns}
      initialState={initialState}
      groupColWidth={300}
      isRowSelectable={({ row, id }) => isAutoGeneratedRow(id) || row.shipToId !== undefined}
    />
  );
}
