import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  useProgramGridColDef,
  usePagination,
  muiFiltersToPagedRequestFilters,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { GridColDef, GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-premium';
import { makeBuyColumn } from '../../../components/DataGrid';
import { Layout } from '../../../components/Layout';
import { UserRole } from '../../../entities/User';
import { useReadItemMakeBuyListQuery } from '../../../services/itemMakeBuyApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, itemMakeBuySection } from '../../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: itemMakeBuySection.title }];

export function ItemMakeBuyListPage(): JSX.Element {
  const programColumn = useProgramGridColDef();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const readItemMakeBuyListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const itemMakeBuyList = useReadItemMakeBuyListQuery(readItemMakeBuyListParams);

  const columns = useMemo<GridColDef[]>(
    () => [{ field: 'pn', headerName: 'P/N', width: 300 }, { ...programColumn, width: 300 }, { ...makeBuyColumn }],
    [programColumn],
  );

  const rows = useMemo(
    () =>
      itemMakeBuyList.data?.data.map((endItemProgram) => ({
        id: `${endItemProgram.pn}+${endItemProgram.programId}`,
        ...endItemProgram,
      })) ?? [],
    [itemMakeBuyList.data?.data],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={itemMakeBuyList.isLoading}
        error={itemMakeBuyList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            sessionStorageId="EndItemListPageDataGrid"
            loading={itemMakeBuyList.isFetching}
            pagination
            paginationMode="server"
            rowCount={itemMakeBuyList.data?.total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
