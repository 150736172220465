import { useCallback, useMemo, useState } from 'react';
import {
  ErrorAlert,
  DataGrid,
  DataGridWrapper,
  useCountryGridColDef,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { AuthGuard, ApiError } from '@top-solution/microtecnica-utils';
import { ZodError } from 'zod';
import Dialog from '@mui/material/Dialog';
import {
  GridActionsColDef,
  GridColDef,
  GridSortModel,
  GridFilterModel,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';
import { isMilitaryColumn } from '../../../components/DataGrid';
import { useECCNGridColDef } from '../../../components/DataGrid/useECCN';
import { Layout } from '../../../components/Layout';
import { ItalianClassification } from '../../../entities/ItalianClassification';
import { UserRole } from '../../../entities/User';
import { useReadItalianClassificationListQuery } from '../../../services/italianClassificationApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, italianClassificationSection } from '../../sections';

const breadcrumbs = [
  { title: adminSection.title, url: adminSection.url },
  { title: italianClassificationSection.title },
];

export function ItalianClassificationPage(): JSX.Element {
  const eccnColumn = useECCNGridColDef();
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const searchParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const readItalianClassificationList = useReadItalianClassificationListQuery(searchParams);
  const [updateError, setUpdateError] = useState<ZodError | ApiError | null>(null);

  const columns = useMemo<(GridColDef<ItalianClassification> | GridActionsColDef<ItalianClassification>)[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 250, filterable: true, sortable: true },
      { ...eccnColumn, valueGetter: (value, row) => row.eccn.id, width: 120, filterable: true, sortable: true },
      {
        ...isMilitaryColumn,
        valueGetter: (value, row) => row.eccn.isMilitary,
        width: 150,
        filterable: true,
        sortable: true,
      },
      {
        ...countryColumn,
        valueGetter: (value, row) => row.eccn.country,
        width: 180,
        filterable: true,
        sortable: true,
      },
      {
        field: 'htsn',
        headerName: 'HTSN',
        width: 200,
        filterable: true,
        sortable: true,
      },
    ],
    [countryColumn, eccnColumn],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={readItalianClassificationList.isLoading}
        error={readItalianClassificationList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        {updateError && (
          <Dialog open={true} onClose={() => setUpdateError(null)}>
            <ErrorAlert error={updateError} />
          </Dialog>
        )}
        <DataGridWrapper>
          <DataGrid
            rows={readItalianClassificationList.data?.data ?? []}
            rowCount={readItalianClassificationList.data?.total ?? 0}
            getRowId={(data) => data.pn}
            columns={columns}
            sessionStorageId="ItalianClassificationListPageDataGrid"
            loading={readItalianClassificationList.isFetching}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
