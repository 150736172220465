import { forwardRef, useMemo } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadECCNListQuery } from '../../services/eccnApi';

type ECCNAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required'>;

function ECCNAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: ECCNAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<unknown>): JSX.Element {
  const { value, label, error, helperText, variant, required, ...autocompleteProps } = props;
  const readECCNList = useReadECCNListQuery();
  const options = useMemo(() => readECCNList.data?.map((eccn) => eccn.id) ?? [], [readECCNList.data]);
  return (
    <Autocomplete
      value={value}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(readECCNList.error) || error}
          helperText={
            (readECCNList.error && 'message' in readECCNList.error && readECCNList.error.message) || helperText
          }
          required={required}
          inputRef={ref}
        />
      )}
      loading={readECCNList.isFetching}
      clearOnEscape
      {...autocompleteProps}
    />
  );
}

export default forwardRef(ECCNAutocomplete) as typeof ECCNAutocomplete;
