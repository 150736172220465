import { Fragment, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { SystemCssProperties } from '@mui/system/styleFunctionSx';

type DefinitionListProps = Omit<BoxProps, 'children'> & {
  data: {
    term: ReactNode | ReactNode[];
    definition: ReactNode | ReactNode[];
  }[];
  termMinWidth?: SystemCssProperties['minWidth'];
};

export function DefinitionList(props: DefinitionListProps): JSX.Element {
  const { data, termMinWidth, sx, ...boxProps } = props;
  return (
    <Box
      sx={{
        margin: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'baseline',
        dt: {
          flex: '1 0 20%',
          minWidth: termMinWidth ?? '10ch',
          paddingInlineEnd: 1,
        },
        dd: {
          flex: '3 1 80%',
        },
        ...sx,
      }}
      component="dl"
      {...boxProps}
    >
      {data.map(({ term, definition }, index) => (
        <Fragment key={index}>
          <dt>{term}</dt>
          <dd>{definition}</dd>
        </Fragment>
      ))}
    </Box>
  );
}
