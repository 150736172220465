import { useMemo, useState } from 'react';
import { DataGrid, DataGridWrapper, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { CountryFlag } from '@top-solution/microtecnica-utils';
import IconButton from '@mui/material/IconButton';
import { DataGridPremiumProps, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import {
  isMilitaryColumn,
  makeBuyColumn,
  SearchToolbar,
  rowEmptyId,
  rowIdSeparator,
} from '../../../components/DataGrid';
import { useProgramColumn } from '../../../components/EUS/EUSEditForm/columns';
import { EyeIcon } from '../../../components/Icons';
import { RecipientManualInfoDialog } from '../../../components/Recipient';
import { NotifyMissingRecipientButton } from '../../../components/Recipient/NotifyMissingRecipientButton';
import { ThirdPartyWarningButton } from '../../../components/ThirdParty/ThirdPartyWarningButton';
import { BottomUpSearchParams, BottomUpSearchResult } from '../../../entities/BottomUpSearch';
import { NOT_AVAILABLE } from '../../../utils/utils';

interface BottomUpSearchDataGridProps {
  searchResult: BottomUpSearchResult[];
  searchParams: BottomUpSearchParams;
  onBack: () => void;
}

const initialState: DataGridPremiumProps['initialState'] = {
  columns: {
    columnVisibilityModel: {
      customerAddress: false,
      enduserAddress: false,
      shipToAddress: false,
    },
  },
};

export function BottomUpSearchDataGrid(props: BottomUpSearchDataGridProps): JSX.Element {
  const { searchResult, searchParams, onBack } = props;
  const [rowForRecipientCheck, setRowForRecipientCheck] = useState<BottomUpSearchResult | null>(null);
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const programColumn = useProgramColumn();

  const gridDataModel = useMemo(() => {
    const pnList = new Set<string>();
    const enditemList = new Set<string>();
    const vendorCodeList = new Set<string>();
    const vendorNameList = new Set<string>();
    const vendorCountryList = new Set<string>();
    const vendorSourceList = new Set<string>();
    const pgrCodeList = new Set<string>();
    const pgrBuyerList = new Set<string>();
    const vendorDAList = new Set<string>();
    const vendorLeadList = new Set<string>();
    const customerIdList = new Set<string>();
    const customerNameList = new Set<string>();
    const customerCountryList = new Set<string>();
    const shipToIdList = new Set<string>();
    const shipToNameList = new Set<string>();
    const shipToCountryList = new Set<string>();
    const enduserNameList = new Set<string>();
    const enduserCountryList = new Set<string>();
    const foreignClassificationManufacturerCountryList = new Set<string>();
    const foreignClassificationECCNLegislationList = new Set<string>();
    const foreignClassificationECNNList = new Set<string>();
    const foreignClassificationHTSNList = new Set<string>();
    const italianClassificationECCNCountryList = new Set<string>();
    const italianClassificationECCNLegislationList = new Set<string>();
    const italianClassificationECCNList = new Set<string>();
    const italianClassificationHTSNList = new Set<string>();
    const techDataClassificationECCNCountryList = new Set<string>();
    const techDataClassificationECCNLegislationList = new Set<string>();
    const techDataClassificationECCNList = new Set<string>();
    const serniCodeList = new Set<string>();

    searchResult.forEach((result) => {
      pnList.add(result.item);
      if (result.enditem && result.enditem.indexOf(NOT_AVAILABLE) !== 0) {
        enditemList.add(result.enditem);
      }
      if (result.vendorCode) {
        vendorCodeList.add(result.vendorCode);
      }
      if (result.vendorName) {
        vendorNameList.add(result.vendorName);
      }
      if (result.vendorCountry) {
        vendorCountryList.add(result.vendorCountry);
      }
      if (result.vendorSource) {
        vendorSourceList.add(result.vendorSource);
      }
      if (result.pgrCode) {
        pgrCodeList.add(result.pgrCode);
      }
      if (result.pgrBuyer) {
        pgrBuyerList.add(result.pgrBuyer);
      }
      if (result.vendorDa) {
        vendorDAList.add(result.vendorDa);
      }
      if (result.vendorLead) {
        vendorLeadList.add(result.vendorLead);
      }
      if (result.customerId) {
        customerIdList.add(result.customerId);
      }
      if (result.customerName) {
        customerNameList.add(result.customerName);
      }
      if (result.customerCountry) {
        customerCountryList.add(result.customerCountry);
      }
      if (result.shipToId) {
        shipToIdList.add(result.shipToId);
      }
      if (result.shipToName) {
        shipToNameList.add(result.shipToName);
      }
      if (result.shipToCountry) {
        shipToCountryList.add(result.shipToCountry);
      }
      if (result.enduserName) {
        enduserNameList.add(result.enduserName);
      }
      if (result.enduserCountry) {
        enduserCountryList.add(result.enduserCountry);
      }
      if (result.foreignClassificationManufacturerCountry) {
        foreignClassificationManufacturerCountryList.add(result.foreignClassificationManufacturerCountry);
      }
      if (result.foreignClassificationECCNLegislation) {
        foreignClassificationECCNLegislationList.add(result.foreignClassificationECCNLegislation);
      }
      if (result.foreignClassificationECCN) {
        foreignClassificationECNNList.add(result.foreignClassificationECCN);
      }
      if (result.foreignClassificationHTSN) {
        foreignClassificationHTSNList.add(result.foreignClassificationHTSN);
      }
      if (result.italianClassificationECCNCountry) {
        italianClassificationECCNCountryList.add(result.italianClassificationECCNCountry);
      }
      if (result.italianClassificationECCNLegislation) {
        italianClassificationECCNLegislationList.add(result.italianClassificationECCNLegislation);
      }
      if (result.italianClassificationECCN) {
        italianClassificationECCNList.add(result.italianClassificationECCN);
      }
      if (result.italianClassificationHTSN) {
        italianClassificationHTSNList.add(result.italianClassificationHTSN);
      }
      if (result.techDataClassificationECCNCountry) {
        techDataClassificationECCNCountryList.add(result.techDataClassificationECCNCountry);
      }
      if (result.techDataClassificationECCNLegislation) {
        techDataClassificationECCNLegislationList.add(result.techDataClassificationECCNLegislation);
      }
      if (result.serniCode) {
        serniCodeList.add(result.serniCode);
      }
    });

    const countryOptions = countryColumn.valueOptions as { value: string; label: string }[];

    return {
      rows: searchResult
        .map((result) => ({
          ...result,
          id:
            result.item +
            rowIdSeparator +
            (result.enditem ?? rowEmptyId) +
            rowIdSeparator +
            (result.programId ?? rowEmptyId) +
            rowIdSeparator +
            (result.vendorCode ?? rowEmptyId) +
            rowIdSeparator +
            (result.pgrCode ?? rowEmptyId) +
            rowIdSeparator +
            (result.customerId ?? rowEmptyId) +
            rowIdSeparator +
            (result.shipToId ?? rowEmptyId) +
            rowIdSeparator +
            (result.enduserId ?? rowEmptyId) +
            rowIdSeparator +
            (result.serniCode ?? rowEmptyId) +
            rowIdSeparator +
            (result.foreignClassificationECCN ?? rowEmptyId) +
            rowIdSeparator +
            (result.italianClassificationECCN ?? rowEmptyId),
        }))
        .sort((a, b) => (a.item < b.item ? -1 : a.item > b.item ? 1 : 0)),
      pnList: [...pnList].sort(),
      enditemList: [...enditemList].sort(),
      vendorCodeList: [...vendorCodeList].sort(),
      vendorNameList: [...vendorNameList].sort(),
      vendorCountryList: countryOptions?.filter(({ value }) => vendorCountryList.has(value)) ?? [],
      vendorSourceList: [...vendorSourceList].sort(),
      pgrCodeList: [...pgrCodeList].sort(),
      pgrBuyerList: [...pgrBuyerList].sort(),
      vendorDAList: [...vendorDAList].sort(),
      vendorLeadList: [...vendorLeadList].sort(),
      customerIdList: [...customerIdList].sort(),
      customerNameList: [...customerNameList].sort(),
      customerCountryList: countryOptions?.filter(({ value }) => customerCountryList.has(value)) ?? [],
      shipToIdList: [...shipToIdList].sort(),
      shipToNameList: [...shipToNameList].sort(),
      shipToCountryList: countryOptions?.filter(({ value }) => shipToCountryList.has(value)) ?? [],
      enduserNameList: [...enduserNameList].sort(),
      enduserCountryList: countryOptions?.filter(({ value }) => enduserCountryList.has(value)) ?? [],
      italianClassificationECCNCountryList:
        countryOptions?.filter(({ value }) => italianClassificationECCNCountryList.has(value)) ?? [],
      italianClassificationECCNLegislationList: [...italianClassificationECCNLegislationList].sort(),
      italianClassificationECCNList: [...italianClassificationECCNList].sort(),
      italianClassificationHTSNList: [...italianClassificationHTSNList].sort(),
      foreignClassificationManufacturerCountryList:
        countryOptions?.filter(({ value }) => foreignClassificationManufacturerCountryList.has(value)) ?? [],
      foreignClassificationECCNLegislationList: [...foreignClassificationECCNLegislationList].sort(),
      foreignClassificationECNNList: [...foreignClassificationECNNList].sort(),
      foreignClassificationHTSNList: [...foreignClassificationHTSNList].sort(),
      techDataClassificationECCNCountryList:
        countryOptions?.filter(({ value }) => techDataClassificationECCNCountryList.has(value)) ?? [],
      techDataClassificationECCNLegislationList: [...techDataClassificationECCNLegislationList].sort(),
      techDataClassificationECCNList: [...techDataClassificationECCNList].sort(),
      serniCodeList: [...serniCodeList].sort(),
    };
  }, [countryColumn.valueOptions, searchResult]);

  const columns = useMemo(() => {
    const columns: GridColDef<BottomUpSearchResult>[] = [
      {
        field: 'item',
        headerName: 'P/N',
        width: 150,
        type: 'singleSelect',
        valueOptions: gridDataModel.pnList,
      },
      {
        ...makeBuyColumn,
      },
    ];

    columns.push(
      {
        field: 'vendorCode',
        headerName: 'Vendor code',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorCodeList,
        width: 120,
      },
      {
        field: 'vendorName',
        headerName: 'Vendor',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorNameList,
        width: 240,
      },
      {
        field: 'vendorSource',
        headerName: 'Vendor source',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorSourceList,
        width: 140,
      },
      {
        ...countryColumn,
        field: 'vendorCountry',
        headerName: 'Vendor country',
        valueOptions: gridDataModel.vendorCountryList,
        width: 150,
      },
    );
    columns.push(
      {
        field: 'pgrCode',
        headerName: 'Purchasing group',
        type: 'singleSelect',
        valueOptions: gridDataModel.pgrCodeList,
        width: 150,
      },
      {
        field: 'pgrBuyer',
        headerName: 'Buyer',
        type: 'singleSelect',
        valueOptions: gridDataModel.pgrBuyerList,
        width: 150,
      },
    );
    columns.push(
      {
        field: 'vendorDa',
        headerName: 'DA',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorDAList,
        width: 150,
      },
      {
        field: 'vendorLead',
        headerName: 'Lead',
        type: 'singleSelect',
        valueOptions: gridDataModel.vendorLeadList,
        width: 150,
      },
    );
    if (searchParams.fields.foreignClassification) {
      columns.push(
        {
          ...countryColumn,
          field: 'foreignClassificationManufacturerCountry',
          headerName: 'CoO (Country of Origin)',
          valueOptions: gridDataModel.foreignClassificationManufacturerCountryList,
          width: 200,
        },
        { field: 'foreignClassificationManufacturer', headerName: 'Manufacturer', width: 150 },
        {
          ...isMilitaryColumn,
          field: 'foreignClassificationECCNIsMilitary',
          headerName: 'Foreign classification',
          width: 200,
        },
        {
          field: 'foreignClassificationECCNLegislation',
          headerName: 'Legislation (Foreign classification)',
          renderCell: ({ value, row }) => (
            <>
              {row.foreignClassificationECCNCountry && (
                <CountryFlag countryCode={row.foreignClassificationECCNCountry} format="png" />
              )}{' '}
              {value}
            </>
          ),
          type: 'singleSelect',
          valueOptions: gridDataModel.foreignClassificationECCNLegislationList,
          width: 200,
        },
        {
          field: 'foreignClassificationECCN',
          headerName: 'ECCN (Foreign classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.foreignClassificationECNNList,
          width: 200,
        },
        {
          field: 'foreignClassificationHTSN',
          headerName: 'HTSN (Foreign classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.foreignClassificationHTSNList,
          width: 200,
        },
      );
    }
    if (searchParams.fields.enditem) {
      columns.push({
        field: 'enditem',
        headerName: 'End Item',
        width: 150,
        type: 'singleSelect',
        valueOptions: gridDataModel.enditemList,
      });
      columns.push({
        field: 'enditemDescription',
        headerName: 'Descrizione End Item',
        width: 200,
      });
    }
    if (searchParams.fields.program) {
      columns.push({
        ...programColumn,
        headerName: 'Programma',
      });
    }
    if (searchParams.fields.customer) {
      columns.push(
        {
          field: 'customerName',
          headerName: 'Customer',
          type: 'singleSelect',
          valueOptions: gridDataModel.customerNameList,
          width: 250,
          renderCell: ({
            value,
            row,
          }: GridRenderCellParams<BottomUpSearchResult, BottomUpSearchResult['customerName']>) => {
            if (!value && row.fromPir === 1 && row.enditem && row.programId) {
              return <NotifyMissingRecipientButton pn={row.item} enditem={row.enditem} programId={row.programId} />;
            }
            if (row.fromPir === 0 && row.fromMro === 0 && row.fromSos === 0) {
              return (
                <>
                  {value}
                  <IconButton size="small" onClick={() => setRowForRecipientCheck(row)}>
                    <EyeIcon />
                  </IconButton>
                </>
              );
            }
            return value;
          },
        },
        {
          field: 'customerId',
          headerName: 'Customer code',
          type: 'singleSelect',
          valueOptions: gridDataModel.customerIdList,
          width: 120,
        },
        {
          ...countryColumn,
          field: 'customerCountry',
          headerName: 'Customer country',
          valueOptions: gridDataModel.customerCountryList,
          width: 150,
        },
        {
          field: 'customerAddress',
          headerName: 'Customer address',
          width: 350,
        },
        {
          field: 'shipToName',
          headerName: 'Ship to',
          type: 'singleSelect',
          valueOptions: gridDataModel.shipToNameList,
          width: 250,
        },
        {
          field: 'shipToId',
          headerName: 'Ship to code',
          type: 'singleSelect',
          valueOptions: gridDataModel.shipToIdList,
          width: 120,
        },
        {
          ...countryColumn,
          field: 'shipToCountry',
          headerName: 'Ship to country',
          valueOptions: gridDataModel.shipToCountryList,
          width: 150,
        },
        {
          field: 'shipToAddress',
          headerName: 'Ship to address',
          width: 350,
        },
      );
    }
    if (searchParams.fields.enduser) {
      columns.push(
        {
          field: 'enduserName',
          headerName: 'End user',
          type: 'singleSelect',
          valueOptions: gridDataModel.enduserNameList,
          width: 250,
        },
        {
          ...countryColumn,
          field: 'enduserCountry',
          headerName: 'End user country',
          valueOptions: gridDataModel.enduserCountryList,
          width: 150,
        },
        {
          field: 'enduserAddress',
          headerName: 'End user address',
          width: 350,
        },
      );
    }
    columns.push({
      field: 'thirdPartyWarning',
      headerName: 'Terze parti',
      type: 'boolean',
      valueGetter: (value, row) =>
        (row.vendorCountry === 'US' || row.foreignClassificationManufacturerCountry === 'US') &&
        (row.foreignClassificationECCNIsMilitary || row.italianClassificationECCNIsMilitary),
      renderCell: ({ value }) => (value ? <ThirdPartyWarningButton size="small" /> : ''),
    });
    if (searchParams.fields.italianClassification) {
      columns.push(
        {
          ...isMilitaryColumn,
          field: 'italianClassificationECCNIsMilitary',
          headerName: 'Italian classification',
          width: 200,
        },
        {
          ...countryColumn,
          field: 'italianClassificationECCNCountry',
          headerName: 'Country (Italian classification)',
          valueOptions: gridDataModel.italianClassificationECCNCountryList,
          width: 250,
        },
        {
          field: 'italianClassificationECCNLegislation',
          headerName: 'Legislation (Italian classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.italianClassificationECCNLegislationList,
          width: 250,
        },
        {
          field: 'italianClassificationECCN',
          headerName: 'ECCN (Italian classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.italianClassificationECCNList,
          width: 250,
        },
        {
          field: 'italianClassificationHTSN',
          headerName: 'HTSN (Italian classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.italianClassificationHTSNList,
          width: 250,
        },
      );
    }
    if (searchParams.fields.serni) {
      columns.push({
        field: 'serniCode',
        headerName: 'SERNI',
        type: 'singleSelect',
        valueOptions: gridDataModel.serniCodeList,
        width: 150,
      });
    }
    if (searchParams.fields.techDataClassification) {
      columns.push(
        {
          field: 'techDataFilename',
          headerName: 'Tech Data file',
          width: 350,
        },
        {
          field: 'techDataHas3dModel',
          headerName: '3D Model',
          type: 'boolean',
          width: 80,
        },
        {
          field: 'techDataSpec',
          headerName: 'Tech Data specification',
          width: 180,
        },
        {
          ...isMilitaryColumn,
          field: 'techDataClassificationECCNIsMilitary',
          headerName: 'Tech Data classification',
          width: 200,
        },
        {
          ...countryColumn,
          field: 'techDataClassificationECCNCountry',
          headerName: 'Country (Tech Data classification)',
          valueOptions: gridDataModel.techDataClassificationECCNCountryList,
          width: 250,
        },
        {
          field: 'techDataClassificationECCNLegislation',
          headerName: 'Legislation (Tech Data classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.techDataClassificationECCNLegislationList,
          width: 250,
        },
        {
          field: 'techDataClassificationECCN',
          headerName: 'ECCN (Tech Data classification)',
          type: 'singleSelect',
          valueOptions: gridDataModel.techDataClassificationECCNList,
          width: 250,
        },
      );
    }
    return columns;
  }, [gridDataModel, countryColumn, searchParams, programColumn]);

  return (
    <DataGridWrapper>
      <DataGrid
        rows={gridDataModel.rows}
        columns={columns}
        initialState={initialState}
        sessionStorageId="BottomUpSearchDataGrid"
        slots={{
          toolbar: SearchToolbar,
        }}
        slotProps={{
          toolbar: { onBack },
        }}
        pagination
      />
      {rowForRecipientCheck?.programId &&
        rowForRecipientCheck.enditem &&
        rowForRecipientCheck.customerName &&
        rowForRecipientCheck.customerId &&
        rowForRecipientCheck.shipToName &&
        rowForRecipientCheck.shipToId && (
          <RecipientManualInfoDialog
            pn={rowForRecipientCheck.enditem}
            programId={rowForRecipientCheck.programId}
            customerName={rowForRecipientCheck.customerName}
            customerId={rowForRecipientCheck.customerId}
            shipToName={rowForRecipientCheck.shipToName}
            shipToId={rowForRecipientCheck.shipToId}
            open={Boolean(rowForRecipientCheck)}
            onClose={() => setRowForRecipientCheck(null)}
          />
        )}
    </DataGridWrapper>
  );
}
