import { Controller, UseFormReturn } from 'react-hook-form';
import { CountryAutocomplete, ProgramAutocomplete } from '@top-solution/microtecnica-mui';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { EndUserForm, EndUserType, endUserTypeLabel } from '../../entities/EndUser';

export interface EndUserEditFormProps {
  form: UseFormReturn<EndUserForm>;
}

export function EndUserEditForm(props: EndUserEditFormProps): JSX.Element {
  const { form } = props;

  const { control } = form;

  return (
    <>
      <Controller
        control={control}
        name="programId"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <ProgramAutocomplete
            label="Programma"
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <TextField label="Nome end-user" error={Boolean(error)} helperText={error?.message ?? ' '} {...field} />
        )}
      />
      <Controller
        control={control}
        name="type"
        render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
          <TextField
            label="Tipo"
            value={value ?? ''}
            onChange={(event) => onChange((event.target.value as EndUserType) || null)}
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            select
          >
            {Object.entries(endUserTypeLabel).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
            <MenuItem value="">&nbsp;</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name="country"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <CountryAutocomplete
            label="Nazione"
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="address"
        render={({ field, fieldState: { error } }) => (
          <TextField label="Indirizzo" error={Boolean(error)} helperText={error?.message ?? ' '} {...field} />
        )}
      />
      <Controller
        control={control}
        name="emailOe"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <Autocomplete
            multiple
            freeSolo
            autoSelect
            options={[]}
            renderInput={(params) => (
              <TextField
                label="Email PM OE"
                error={Boolean(error)}
                helperText={
                  (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
                  'Premere Invio/Enter per aggiungere un nuovo indirizzo email'
                }
                {...params}
              />
            )}
            onChange={(_, value) => onChange(value)}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="emailMro"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <Autocomplete
            multiple
            freeSolo
            autoSelect
            options={[]}
            renderInput={(params) => (
              <TextField
                label="Email PM MRO"
                error={Boolean(error)}
                helperText={
                  (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
                  'Premere Invio/Enter per aggiungere un nuovo indirizzo email'
                }
                {...params}
              />
            )}
            onChange={(_, value) => onChange(value)}
            {...field}
          />
        )}
      />
    </>
  );
}
