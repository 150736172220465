import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import IconButton from '@mui/material/IconButton';
import { GridActionsColDef, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { EUSAddButton, EUSDeleteConfirm } from '../../components/EUS';
import { EUSListDataGrid } from '../../components/EUS/EUSListDataGrid';
import { useEUSListDataGridColumns } from '../../components/EUS/useEUSListDataGridColumns';
import { ChevronRightIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { EUS } from '../../entities/EUS';
import { UserRole } from '../../entities/User';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { eusSection } from '../sections';

const breadcrumbs = [{ title: eusSection.title }];
const pinnedColumns = { right: ['actions'] };

export function EUSListPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();
  const [eusToRemove, setEusToRemove] = useState<EUS | null>(null);

  const canEditEUS = useMemo(() => isAdmin || hasRole(UserRole.FOREIGN_CLASSIFICATION_MANAGER), [hasRole, isAdmin]);

  const defaultColumns = useEUSListDataGridColumns();
  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      ...defaultColumns,
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        renderHeader: canEditEUS ? () => <EUSAddButton /> : undefined,
        renderCell: ({ row }: GridRenderCellParams<EUS>) => (
          <IconButton size="small" component={Link} to={`./${row.id}`}>
            <ChevronRightIcon />
          </IconButton>
        ),
      },
    ],
    [defaultColumns, canEditEUS],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper sx={{ '.ExpirationWarning': { bgcolor: 'error.light', color: 'error.contrastText' } }}>
          <EUSListDataGrid columns={columns} pinnedColumns={pinnedColumns} />
        </DataGridWrapper>
        {eusToRemove && (
          <EUSDeleteConfirm open={Boolean(eusToRemove)} data={eusToRemove} onClose={() => setEusToRemove(null)} />
        )}
      </Layout>
    </AuthGuard>
  );
}
